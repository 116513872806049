import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';


import { MediaPlayer, MediaProvider, Track } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import CONSTANTS from '../../constants';

export default function VidStackPlayer(){
    return(
        <div className='video__player__live'  >
        <MediaPlayer title="..." src={CONSTANTS.BaseURL + '/recording/hls/excel-for-analytics/master.m3u8'} >
            <MediaProvider>
            </MediaProvider>
            <DefaultVideoLayout
            icons={defaultLayoutIcons}
            thumbnails="/thumbnails.vtt"
            style={{
                '--video-brand': '#f5f5f5',
            }}
            />
            </MediaPlayer>
        </div>
    )
}

// https://stream.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU.m3u8
//