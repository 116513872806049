import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CONSTANTS from "../../constants";
import { toggleNavigation, useOnBoardMutation, useCheckOnBoardMutation } from "../../store";
import toastService from '../../toast';
import { globalError } from "../../utils/__errorHandling";

let assets = CONSTANTS.assets;

function CollegeOnBoard(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checkOnBoard] = useCheckOnBoardMutation();

    const [ onBoard ] = useOnBoardMutation();
    const [questions, changeQuestion] = useState(
        [
            {
                question: '"Are you a working professional or a fresher?"',
                options: [ 'Fresher', 'Working Professional'],
                selected: '',
                _type: 'select',
                img: ''
            },
            {
                question: `What company are you working for?`,
                options: [ 'Data Analyst', 'Data Scientist' ],
                selected: '',
                placeholder: 'Company Name',
                _type: 'input'
            },
            {
                question: 'What is your Highest Education Qualification',
                options: ['BE / B.Tech', 'ME / M.Tech', 'MBA / PGDM', 'Bachelor of Commerce / Management'],
                selected: '',
                _type: 'select'
            }
        ]
    );

    const setInputAnswer = (_id, _answer) => {
        changeQuestion((state)=>{
            let _data = state;
            _data[_id].selected = _answer
            return _data;
        })
    }

    const [ index, changeIndex ] = useState(0);

    const checkRecord = async() => {
        try{
            let response = await checkOnBoard();
            const { status, exists } = response.data;
            if(status === 'success'){
                if(exists){
                    navigate('/');
                }else{
                    return;
                }
            }
        }catch(err){
            console.log({ err })
            toastService.error();
        }
    }

    useEffect(()=>{
        dispatch(toggleNavigation(false));
        checkRecord();
    }, [dispatch]);

    const optionClick = (_answer) => {
        let question = questions;
        questions[index].selected = _answer;
        if(index === 0){
            question[index + 1].question = (_answer === 'Fresher') 
            ? 'What College are you studying in?' : 'What company are you working for?';
            question[index + 1].placeholder = (_answer === 'Fresher') 
            ? 'College Name' : 'Company Name';
        }
        changeQuestion([...question]);
    };

    const nextClick = async() => {
        try{
            window.scrollTo(0,0);
            if(index === questions.length - 1){
                let response = await onBoard({
                    experienceLevel: questions[0].selected,
                    organization: questions[1].selected,
                    educationBackground: questions[2].selected,
                });
                if(response['error']){
                    globalError(response['error']);
                    return;
                };
                if(response['data']){
                    if(response['data'].status === 'success'){
                        await dispatch(toggleNavigation(true));
                        navigate('/');
                    }else{
                        toastService.error();
                    }
                    return;
                };
                return;
            };
            if(questions[index].selected === ''){
                toastService.error('Select valid answer!');
                return;
            }
            changeIndex(index + 1);
        }catch(err){
            toastService.error();
        }
    };

    const renderQuestion = () => {
        let data = questions[index];
        return (
            <React.Fragment>
                <h3>{data.question}</h3>
                {   data._type === 'input' && 
                    <input type="text" name="i-name" placeholder={data.placeholder}
                        onChange={(e)=>setInputAnswer(index, e.target.value)} />
                }
                <div className="onboard__container__txt__options" >
                    { 
                        data._type !== 'input' &&
                        data.options.map((e)=>(
                            <div 
                                key={e}
                                onClick={()=>optionClick(e)}
                                className={ (e === data.selected) ? "onboard--active": null} >
                                {e}
                            </div>
                        )) 
                    }
                </div>
                {
                    (index === questions.length - 1)  && (
                        <span className="onboard__container__txt__options--check" >
                            I have read and agree to the <a href="https://businesstoys.in/privacy-policy/" target="__blank" >Terms and Conditions</a>&nbsp;
                            and the &nbsp;
                            <a href="https://businesstoys.in/privacy-policy/#:~:text=on%20this%20page.-,Refund%20%26%20Cancellation%20Policy,-Thank%20you%20for" target="__blank" >Refund Policy</a>.&nbsp;
                            I accept these terms and understand how refunds are handled on &nbsp; 
                            <a href="https://businesstoys.in/" target="__blank" >businesstoys</a> and any transactions made on this platform.
                        </span>)
                }
            </React.Fragment>
        )
    };

    return (
        <section className="onboard" >
            <div className="onboard__container" >
                <div className="onboard__container__txt" >
                    { (index === 0) && <h1>Welcome to Business Toys</h1>}
                    {renderQuestion()}
                    <button className="btn" onClick={nextClick} >
                        { (index === (questions.length - 1)) ? 'Submit' : 'Next' }
                    </button>
                </div>
                <div className="onboard__container__img" >
                    <img src={assets + '/onboard/' + (index + 1) +'.png' } alt="onboard-img" />
                </div>
            </div>
            <div className="onboard__sub" >
                <div className={ (index === 0) ? 
                        "onboard__sub--img onboard__sub--active": 
                        "onboard__sub--img"} >
                    <img src={assets + '/icons/note.png'} alt="note" />
                </div>
                <div className="onboard__sub--line" >
                    <img src={assets + '/icons/line.png'} alt="line" />
                </div>
                <div className={ (index === 1) ? 
                    "onboard__sub--img onboard__sub--active":
                    "onboard__sub--img"} >
                    <img src={assets + '/icons/breifcase.png'} alt="breifcase" />
                </div>
                <div className="onboard__sub--line" >
                    <img src={assets + '/icons/line.png'} alt="line" />
                </div>
                <div className={ (index === 2) ? 
                    "onboard__sub--img onboard__sub--active":
                    "onboard__sub--img"} >
                    <img src={assets + '/icons/wallet.png'} alt="wallet" />
                </div>
            </div>
        </section>
    )
};

export default CollegeOnBoard;