import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toggleNavigation, disablePopup } from '../../store';

function Terms(){
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(toggleNavigation(false));
        dispatch(disablePopup());
    }, [dispatch]);

    return(
        <section className='policy' >
            <h1>Terms & Conditions</h1>
            <div className="policy__container" >
                <div className="policy__container__card" >
                    <h2>Effective Date: February 21, 2022</h2>
                </div>
                <div className="policy__container__card" >
                    <h3>Introduction</h3>
                    <p>
                        These Terms and Conditions (“Terms”) govern your use of the learn.businesstoys.in 
                        Web App (“learn.businesstoys.in”) provided by SKILLPHUL BUSINESS TOYS PRIVATE LIMITED. 
                        By accessing or using the learn.businesstoys.in, you agree to be bound by these Terms. 
                        If you do not agree to these Terms, you may not access or use the learn.businesstoys.in.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Use of the learn.businesstoys.in</h3>
                    <p>
                        You must be at least 18 years old to use the learn.businesstoys.in. 
                        By using the learn.businesstoys.in, you represent and warrant that you are 18 years old or older.
                    </p>
                    <p>
                        You are responsible for maintaining the confidentiality of your account and password, and for 
                        restricting access to your computer or mobile device. 
                        You agree to accept responsibility for all activities that occur under your account or password.
                    </p>
                    <p>
                        You agree not to use the learn.businesstoys.in for any illegal or unauthorized purpose. 
                        You must comply with all applicable laws and regulations in your use of the learn.businesstoys.in
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Intellectual Property:</h3>
                    <p>
                        The learn.businesstoys.in and its content, including without limitation the text, graphics, logos, 
                        icons, and images (“Content”), are the property of SKILLPHUL BUSINESS TOYS PRIVATE LIMITED or its licensors and are 
                        protected by copyright, trademark, and other intellectual property laws. You may not reproduce, modify, 
                        distribute, or otherwise use any Content without the prior written consent of SKILLPHUL BUSINESS TOYS PRIVATE LIMITED.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>User Content:</h3>
                    <p>
                        You may submit content to the learn.businesstoys.in, including without limitation text, images, 
                        and audiovisual content (“User Content”). You retain all ownership rights in your User Content, 
                        but you grant SKILLPHUL BUSINESS TOYS PRIVATE LIMITED a non-exclusive, worldwide, perpetual, irrevocable, royalty-free 
                        license to use, copy, modify, distribute, 
                        and display your User Content for the purpose of providing the learn.businesstoys.in services.
                    </p>
                    <p>
                        You are solely responsible for your User Content and for ensuring 
                        that your User Content does not violate any third-party rights or 
                        applicable laws and regulations. You agree to indemnify and hold 
                        SKILLPHUL BUSINESS TOYS PRIVATE LIMITED harmless from any claims 
                        arising from your User Content.
                    </p>
                    
                </div>
                <div className="policy__container__card" >
                    <h3>Limitation of Liability:</h3>
                    <p>
                        The learn.businesstoys.in is provided “as is” and without warranty of any kind, 
                        either express or implied. SKILLPHUL BUSINESS TOYS PRIVATE LIMITED does not guarantee that the 
                        learn.businesstoys.in will be error-free or uninterrupted.
                    </p>
                    <p>
                        To the fullest extent permitted by law, SKILLPHUL BUSINESS TOYS PRIVATE LIMITED
                        shall not be liable for any indirect, incidental, special, or 
                        consequential damages arising out of or in connection with your use of 
                        the learn.businesstoys.in, including without limitation damages for loss 
                        of profits, goodwill, use, data, or other intangible losses.
                    </p>

                </div>
                <div className="policy__container__card" >
                    <h3>Indemnification:</h3>
                    <p>
                        You agree to indemnify and hold SKILLPHUL BUSINESS TOYS PRIVATE LIMITED harmless from 
                        any claims, damages, or other expenses arising out of or in connection with your 
                        use of the learn.businesstoys.in, your User Content, or your breach of these Terms.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Governing Law:</h3>
                    <p>
                        These Terms shall be governed by and construed in accordance with the laws of India, 
                        without giving effect to any principles of conflicts of law.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Dispute Resolution:</h3>
                    <p>
                        Any dispute arising out of or in connection with these Terms shall be resolved through binding 
                        arbitration in accordance with the Arbitration and Conciliation Act, 1996. 
                        The arbitration shall be conducted in Bengaluru, and the award shall be final and binding.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Contact Us</h3>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our practices, 
                        please contact us at <a href='mailto:contact@businesstoys.in' >contact@businesstoys.in</a>.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Changes to these Terms:</h3>
                    <p>
                        We may update these Terms from time to time. We will notify you of any changes by 
                        posting the new Terms on the learn.businesstoys.in.  <br />
                        We encourage you to review these Terms periodically for any updates or changes.
                    </p>
                </div>
                
            </div>
        </section>
    )
};

export default Terms;