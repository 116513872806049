import React, { useEffect, useState } from "react";
import FileDownload from 'js-file-download';
import { Link, useParams } from 'react-router-dom';

import CONSTANTS from "../../constants";
import { useSelector } from 'react-redux';
import { globalQueryError } from "../../utils/__errorHandling";
import toastService from "../../toast";
import { downloadAttachment } from '../../utils/__axios';
import { useCourseAssetsMutation } from "../../store/apis/api";

const assets = CONSTANTS.assets;

function Resources(){
    const courseVideo = useSelector((state => state.courseVideo));
    const [attachments, setAttachments] = useState([]);
    const params = useParams();
    const [fetchCourseAssets] = useCourseAssetsMutation();

    const getAttachments = async () => {
        try {
            const response = await fetchCourseAssets(params.id)
            if (response && response.data) {
                const data = await response.data.data.filter(e => e.module === courseVideo.module)
                setAttachments(data)
            }
        } catch (error) {
            toastService.error()
        }
    }

    useEffect(() => {
        getAttachments()
    }, [courseVideo])

    const downloadClick = async(_key, _name) => {
        try{
            window.scrollTo(0,0);
            toastService.info('Downloading...');
            let response = await downloadAttachment(_key);
            FileDownload(response.data, _name);
        }catch(err){
            toastService.error();
        }
    }

    return(
        <div className="courseVideo__player__features--resource" >
            <div className="courseVideo__player__features--resource__container" >
                {
                    <span>
                        {
                            attachments?.map((e)=>(
                                (e.module === courseVideo?.module) 
                                ? (<div key={e.fileKey} onClick={()=>downloadClick(e.fileKey, e.filename)} className="courseVideo__player__features--resource__container__upload" >
                                    <img src={assets + '/video/folder-placeholder.png'} alt="folder" />
                                    <h6>{e.filename}</h6>
                                    <p>
                                        {
                                            new Date(e.uploadedAt).toLocaleString('en-US', {
                                                year: 'numeric', 
                                                month: 'short', 
                                                day: 'numeric', 
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            })
                                        }
                                    </p>
                                    </div>)
                                : null
                            ))
                        }
                        { (attachments?.length === 0) && <p>No attachments</p> }
                    </span>
                }
            </div>
        </div>
    )
};

export default Resources;