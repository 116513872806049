import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import toastService from '../../toast';
import { useSaveNotesMutation, useGetNotesMutation, setNotes } from '../../store'

function Notes({ setplyrTime }){
    const timestamp = useSelector(state => state.timestamp) || 0
    const videoDetails = useSelector(state => state.videoDetails)
    const notes = useSelector(state => state.notes)

    const dispatch = useDispatch();
    const [rteNote, setrteNote] = useState('');
    const [rteDisplay, setRteDisplay] = useState(false);
    const [saveNotes] = useSaveNotesMutation();
    const [getNotes] = useGetNotesMutation();
    // const [notes, setNotes] = useState([]);
    const params = useParams();
    const navigate = useNavigate();

    const loadData = async () => {
        try{
            setrteNote('');
            let response = await getNotes({ 
                _course: params['id'], 
                _recording: videoDetails['_id']
            });
            
            if(response.data['status'] === 'success'){
                dispatch(setNotes(response.data['data']));
            }else{
                toastService.error();
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    }

    const notesSaveClick = async() => {
        try{
            if(rteNote.length < 3) return toastService.error('Enter valid content');

            const data = {
                timestamp,
                content: rteNote,
                recordingId: videoDetails['_id'],
                course: params['id']
            }
            let response = await saveNotes(data);

            if(response['error']){
                let error = response.error;
                if(error.status === 401){
                    navigate('/login');
                    toastService.error('Unauthorized');
                }else{
                    toastService.error();
                }
            }

            if(response['data']){
                if(response['data'].status === 'created'){
                    loadData();
                    toastService.success('Notes saved');
                }
            }

        }catch(err){
            console.log({ err });
            toastService.error();
        }
    }

    useEffect(()=>{
        loadData();
    }, [videoDetails]);


    const secondsToMinutes = (_seconds) => {
        const hours = Math.floor(_seconds / 3600);
        const minutes = Math.floor((_seconds % 3600) / 60);
        const remainingSeconds = (_seconds % 60).toFixed(0);
        
        let timeString = '';
        
        if (hours > 0) {
            const formattedHours = String(hours).padStart(2, '0');
            timeString += `${formattedHours}:`;
        }
        
        const formattedMinutes = String(minutes).padStart(2, '0');
        timeString += `${formattedMinutes}:${remainingSeconds}`;
        
        return timeString;
    }

    const renderNotes = () => {
        return notes.map((e)=>{
            return(
                <div key={e._id} className='video__player__features--notes__container__card' >
                    <h3>{e.recordingId.label}</h3>
                    <h5> <span >{secondsToMinutes(e.timestamp)}</span> {e.recordingId.title.split('##')[0]}</h5>
                    <p dangerouslySetInnerHTML={{__html: e.content}}></p>
                </div>
            )
        })
    };

    return(
        <div className='video__player__features--notes'  >
            <div className='video__player__features--notes__rte'  style={{ display: !rteDisplay ? 'block' : 'none' }}
                onClick={()=>setRteDisplay(true)}
            >
                Create your notes  
            </div>
            <div style={{ display: rteDisplay ? 'block' : 'none' }} >
                <ReactQuill theme="snow" value={rteNote} onChange={setrteNote} />
                <div className='video__player__features--notes__rte--btns' >
                    <button className='btn' onClick={notesSaveClick} >Save</button>
                    <button onClick={()=>{
                        setrteNote('');
                        setRteDisplay(false);
                    }} >Cancel</button>
                </div>

            </div>
            <div className='video__player__features--notes__container' >
                {renderNotes()}
            </div>
        </div>
    )
};

export default Notes;