import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toggleNavbar, useQuizTopicListMutation } from "../../store";
import toastService from "../../toast";
import { globalError } from "../../utils/__errorHandling";
import { analyticsEvent } from "../../firebase";

function Quiz(){
    let params = useParams();
    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [ fetchQuizTopicData ] = useQuizTopicListMutation();
    const [content, setContent] = useState([]);

    useEffect(()=>{
        window.scrollTo(0,0);
        dispatch(toggleNavbar(false));
        analyticsEvent({ 
            _event: 'quiz_view', _type: 'view', 
            _custom: { quizName: params['slug'] } 
        });
    }, [dispatch]);

    const loadData = async() => {
        try{
            let response = await fetchQuizTopicData(params['slug']);
            if(response['error']){
                globalError(response['error']);
                return;
            };
            if(response['data'] && response['data']['status'] === 'success'){
                if(response['data']['data'].length === 0){
                    navigate('/');
                    toastService.error('No Quiz found');
                    return;
                }
                setContent(response['data']['data']);
            }

        }catch(err){
            toastService.error();
        }
    }

    useEffect(()=>{
        loadData();
    }, []);

    const renderContent = () => content.map((e)=>(
        <div key={e._id} className="quiz__container__card" style={{
            backgroundImage: 'url(/assets/quiz/card-active.png)'
        }} >
            <h4>{e.topic} ({e.diffcultyLevel})</h4>
            <div className="quiz__container__card__status" >
                <p><span style={{ width: `${ (e.correct)/(e.content.length) * 100 }%` }} ></span></p>
                <h5>
                    {(e.correct > e.content.length) ? e.content.length : e.correct }/{e.content.length}
                </h5>
            </div>
            <p>Test your {params['slug']} knowledge now</p>
            <Link to={`/quiz/play/${e.slug}`} >Start Quiz</Link>
        </div>
    ))

    return(
        <section className="quiz" >
            <Link to="/" >
                <img className="quiz__logo" src="/assets/logos/logo.png" alt="MOAT Logo" />
            </Link>
            <h1>"Ready to put your {params['slug'].toLocaleUpperCase()} skills to the test? Welcome to our quiz!"</h1>
            <h4>This quiz will be having <b>multiple questions</b> on each level</h4>
            <h4>More levels <b>coming soon....</b></h4>
            <div className="quiz__container"  >
                {renderContent()}
            </div>
        </section>
    )
};

export default Quiz;