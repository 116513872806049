import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { toggleSidebar, useLogOutMutation } from '../../store';

// import CONSTANTS from "../../constants";
import toastService from "../../toast";

// let assets = CONSTANTS.assets;

const routes = [
    {
        url: '/courses',
        imgPath: '/sidebar/courses.png',
        activeImgPath: '/sidebar/courses-blue.png',
        title: 'My Courses',
        external: false,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M26.0156 1.87499C26.0156 1.48686 25.7006 1.17186 25.3125 1.17186H20.6597C20.6409 1.16999 20.625 1.16061 20.6053 1.16061H13.1456C13.1259 1.16061 13.11 1.16999 13.0912 1.17186H6.75092C6.72467 1.17093 6.69373 1.16999 6.66279 1.16999C5.2181 1.16999 4.0406 2.31374 3.98529 3.74436V3.74905V26.6137C4.09123 27.8615 5.12998 28.8328 6.39654 28.8328C6.4556 28.8328 6.51373 28.8309 6.57185 28.8262H6.56435H25.3144C25.7025 28.8262 26.0175 28.5112 26.0175 28.1231C26.0175 27.735 25.7025 27.42 25.3144 27.42H6.75185C6.73029 27.4209 6.70498 27.4219 6.67967 27.4219C6.00748 27.4219 5.45529 26.909 5.39248 26.2528V26.2472V25.1006C5.39248 24.6703 5.93998 24.2934 6.56248 24.2934L25.3144 24.3469C25.5084 24.3469 25.6847 24.2681 25.8112 24.1406C25.8272 24.1247 25.8347 24.1022 25.8487 24.0844C25.8928 24.0366 25.9303 23.9812 25.9594 23.9222L25.9612 23.9184C25.9762 23.8725 25.9884 23.82 25.9931 23.7647V23.7619C26.0034 23.729 26.0119 23.6897 26.0165 23.6484V23.6456V23.6437L26.0156 1.87499ZM24.6094 22.9415L10.0781 22.9003V2.57811H12.4415V12.3731C12.4415 12.3731 12.4415 12.3731 12.4415 12.3741C12.4415 12.7622 12.7565 13.0762 13.1437 13.0762C13.3134 13.0762 13.469 13.0162 13.59 12.9159L13.589 12.9169L16.8797 10.2272L20.1647 12.8559C20.2837 12.9516 20.4365 13.0097 20.6034 13.0097C20.9915 13.0097 21.3065 12.6947 21.3075 12.3066V2.57811H24.6084L24.6094 22.9415ZM19.9022 2.57811V10.8431L17.3147 8.77311C17.1956 8.67655 17.0428 8.61843 16.8759 8.61843C16.7062 8.61843 16.5506 8.67843 16.4287 8.77968L16.4297 8.77874L13.8478 10.89V2.57811H19.9022ZM5.3906 23.1431V3.74999C5.45342 3.08905 6.00654 2.57624 6.67873 2.57624C6.70404 2.57624 6.72935 2.57718 6.75373 2.57811H6.74998H8.67185V22.8956L6.56248 22.89C6.13779 22.8919 5.73467 22.9847 5.37279 23.1506L5.3906 23.1431Z" fill="white"/>
            </svg>)
    },
    {
        url: '/placement',
        imgPath: '/sidebar/placement.png',
        activeImgPath: '/sidebar/placement-blue.png',
        title: 'Placement',
        external: false,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M23.8961 5.15884C25.7252 8.32689 25.7252 12.1085 23.8961 15.2765L15.6129 29.6235C15.5141 29.7946 15.3315 29.9 15.1339 29.9C14.9364 29.9 14.7538 29.7946 14.655 29.6235L6.37182 15.2765C4.54273 12.1085 4.54273 8.32689 6.37182 5.15884C8.20091 1.9908 11.4758 0.100006 15.1339 0.100006C18.7921 0.100006 22.0671 1.9908 23.8961 5.15884ZM7.32965 14.7235L15.1339 28.2409L22.9382 14.7235C24.5669 11.9024 24.567 8.53286 22.9382 5.71187C21.3095 2.8908 18.3913 1.20605 15.1339 1.20605C11.8766 1.20605 8.95842 2.8908 7.32965 5.71187C5.70095 8.53286 5.70095 11.9024 7.32965 14.7235Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.8335 10.232C7.8335 6.20664 11.1086 2.93158 15.1339 2.93158C19.1593 2.93158 22.4344 6.20657 22.4344 10.232C22.4344 14.2574 19.1593 17.5324 15.1339 17.5324C11.1086 17.5324 7.8335 14.2574 7.8335 10.232ZM12.1849 15.1813V15.6781L12.2379 15.7063C13.1027 16.1656 14.0885 16.4263 15.134 16.4264C16.1795 16.4264 17.1654 16.1656 18.0303 15.7061L18.0833 15.6779V15.1813C18.0833 13.555 16.7604 12.2321 15.1341 12.2321C13.5078 12.2321 12.1849 13.5551 12.1849 15.1813ZM19.1626 14.7223L19.1848 14.9182L19.3298 14.7845C20.5575 13.6521 21.3283 12.0305 21.3283 10.232C21.3283 6.81629 18.5496 4.03763 15.1339 4.03763C11.7182 4.03763 8.93954 6.81629 8.93954 10.232C8.93954 12.0306 9.71035 13.6523 10.9383 14.7848L11.0833 14.9185L11.1055 14.7225C11.2544 13.4064 12.036 12.2793 13.1386 11.6532L13.2615 11.5834L13.1548 11.4907C12.5162 10.9359 12.1117 10.1193 12.1117 9.20962C12.1117 7.54327 13.4675 6.18741 15.1339 6.18741C16.8003 6.18741 18.1561 7.54327 18.1561 9.20962C18.1561 10.1192 17.7516 10.9358 17.1131 11.4906L17.0064 11.5833L17.1293 11.653C18.232 12.2791 19.0136 13.4061 19.1626 14.7223ZM17.0501 9.20962C17.0501 8.153 16.1905 7.29346 15.1339 7.29346C14.0773 7.29346 13.2177 8.153 13.2177 9.20962C13.2177 10.2662 14.0772 11.1258 15.1339 11.1258C16.1906 11.1258 17.0501 10.2662 17.0501 9.20962Z" fill="white"/>
            </svg>)
    },
    {
        url: 'https://jobs.businesstoys.in/',
        imgPath: '/sidebar/job.png',
        activeImgPath: '/sidebar/job-blue.png',
        title: 'Job portal',
        external: true,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.88235 7.00294C8.88235 5.34446 10.2268 4 11.8853 4H18.2382C19.8968 4 21.2412 5.34447 21.2412 7.00294V7.52941H24.1206C25.7791 7.52941 27.1235 8.87388 27.1235 10.5324V22.2971C27.1235 23.9556 25.7791 25.3 24.1206 25.3H6.00294C4.34447 25.3 3 23.9556 3 22.2971V10.5324C3 8.87387 4.34446 7.52941 6.00294 7.52941H8.88235V7.00294ZM6.00294 8.82941C5.06243 8.82941 4.3 9.59184 4.3 10.5324V15.2382C4.3 16.1788 5.06243 16.9412 6.00294 16.9412H14.4118V15.2382C14.4118 14.8793 14.7028 14.5882 15.0618 14.5882C15.4207 14.5882 15.7118 14.8793 15.7118 15.2382V16.9412H24.1206C25.0611 16.9412 25.8235 16.1788 25.8235 15.2382V10.5324C25.8235 9.59184 25.0611 8.82941 24.1206 8.82941H6.00294ZM19.9412 7.52941H10.1824V7.00294C10.1824 6.06243 10.9448 5.3 11.8853 5.3H18.2382C19.1788 5.3 19.9412 6.06243 19.9412 7.00294V7.52941ZM25.8235 17.712C25.3396 18.0457 24.7529 18.2412 24.1206 18.2412H15.7118V19.9441C15.7118 20.3031 15.4207 20.5941 15.0618 20.5941C14.7028 20.5941 14.4118 20.3031 14.4118 19.9441V18.2412H6.00294C5.37061 18.2412 4.78392 18.0457 4.3 17.712V22.2971C4.3 23.2376 5.06243 24 6.00294 24H24.1206C25.0611 24 25.8235 23.2376 25.8235 22.2971V17.712Z" fill="white"/>
            </svg>)
    },
    {
        url: '/payment',
        imgPath: '/sidebar/payment.png',
        activeImgPath: '/sidebar/payment-blue.png',
        title: 'Payment',
        external: false,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.2327 3.19359C13.9511 2.99998 13.5683 3.04778 13.343 3.30519L9.61974 7.58773H5.15474C3.8294 7.58773 2.755 8.66218 2.755 9.98751V24.5252C2.755 25.8506 3.82941 26.925 5.15474 26.925H24.8452C26.1705 26.925 27.2449 25.8506 27.2449 24.5252V9.98751C27.2449 8.66218 26.1705 7.58773 24.8452 7.58773H23.4042L21.4514 3.68216C21.3718 3.52356 21.2325 3.40184 21.0631 3.34545C20.8917 3.28754 20.7076 3.30212 20.5487 3.38157L17.0367 5.13758L14.2336 3.19425L14.2327 3.19359ZM11.4035 7.58773L13.9626 4.64411L15.6642 5.82392L12.1366 7.58773H11.4035ZM21.8993 7.58773H15.1465L20.5487 4.88654L21.8993 7.58773ZM4.10103 9.98751C4.10103 9.40671 4.574 8.93376 5.15474 8.93376H24.8452C25.426 8.93376 25.8989 9.40671 25.8989 9.98751V12.3839H20.9626C18.7503 12.3839 16.9518 14.1864 16.9518 16.4016V17.062C16.9518 19.2769 18.7504 21.0789 20.9626 21.0789H25.8989V24.5252C25.8989 25.106 25.426 25.579 24.8452 25.579H5.15474C4.574 25.579 4.10103 25.106 4.10103 24.5252V9.98751ZM18.2979 16.4016C18.2979 14.9281 19.4941 13.7299 20.9626 13.7299H25.8989V19.7329H20.9626C19.494 19.7329 18.2979 18.535 18.2979 17.062V16.4016ZM20.9681 14.8292C19.9796 14.8292 19.1761 15.6327 19.1761 16.6212C19.1761 17.6098 19.9796 18.4132 20.9681 18.4132C21.9567 18.4132 22.7601 17.6098 22.7601 16.6212C22.7601 15.6327 21.9567 14.8292 20.9681 14.8292ZM20.5221 16.6212C20.5221 16.3757 20.7225 16.1752 20.9681 16.1752C21.2137 16.1752 21.4141 16.3757 21.4141 16.6212C21.4141 16.8668 21.2136 17.0673 20.9681 17.0673C20.7225 17.0673 20.5221 16.8668 20.5221 16.6212Z" fill="white"/>
            </svg>)
    },
    {
        url: 'https://businesstoys.in/blog/',
        imgPath: '/sidebar/blog.png',
        activeImgPath: '/sidebar/blog-blue.png',
        title: 'Blogs',
        external: true,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.40002 7.61125C1.40002 5.63066 3.00561 4.02507 4.9862 4.02507H21.4102C22.1251 4.02507 22.7929 4.23492 23.3531 4.59658C23.6315 4.77631 23.7115 5.1477 23.5317 5.42609C23.352 5.70449 22.9806 5.78447 22.7022 5.60473C22.3302 5.36453 21.8874 5.22507 21.4102 5.22507H4.9862C3.66835 5.22507 2.60002 6.2934 2.60002 7.61125V18.9338C2.60002 20.2517 3.66835 21.32 4.9862 21.32H5.54611C5.87748 21.32 6.14611 21.5886 6.14611 21.92V24.2493L8.85107 21.4993C8.96386 21.3846 9.11798 21.32 9.27883 21.32H21.4102C22.728 21.32 23.7963 20.2517 23.7963 18.9338V15.8232C23.7963 15.4919 24.065 15.2232 24.3963 15.2232C24.7277 15.2232 24.9963 15.4919 24.9963 15.8232V18.9338C24.9963 20.9144 23.3908 22.52 21.4102 22.52H9.53026L5.97386 26.1357C5.80305 26.3093 5.54421 26.3625 5.31879 26.2702C5.09337 26.1779 4.94611 25.9585 4.94611 25.7149V22.5198C2.98399 22.4983 1.40002 20.901 1.40002 18.9338V7.61125Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.2572 8.79327C5.2572 8.4619 5.52583 8.19327 5.8572 8.19327H17.4908C17.8222 8.19327 18.0908 8.4619 18.0908 8.79327C18.0908 9.12464 17.8222 9.39327 17.4908 9.39327H5.8572C5.52583 9.39327 5.2572 9.12464 5.2572 8.79327Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.2572 13.5836C5.2572 13.2522 5.52583 12.9836 5.8572 12.9836H13.5715C13.9029 12.9836 14.1715 13.2522 14.1715 13.5836C14.1715 13.915 13.9029 14.1836 13.5715 14.1836H5.8572C5.52583 14.1836 5.2572 13.915 5.2572 13.5836Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.2572 18.3739C5.2572 18.0426 5.52583 17.7739 5.8572 17.7739H12.265C12.5964 17.7739 12.865 18.0426 12.865 18.3739C12.865 18.7053 12.5964 18.9739 12.265 18.9739H5.8572C5.52583 18.9739 5.2572 18.7053 5.2572 18.3739Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M23.5437 6.19847C24.7314 5.1326 26.5475 5.18998 27.6655 6.32871C28.8643 7.54972 28.7928 9.52671 27.5088 10.6578L24.1633 13.605L19.1191 17.7554L13.7513 18.7026L15.478 13.3656L20.0777 9.34087L22.8136 6.85363L23.5437 6.19847ZM26.8092 7.16942C26.1409 6.48868 25.0552 6.45437 24.3452 7.09156L24.061 7.34659L26.4373 10.0025L26.7155 9.7574C27.4831 9.08121 27.5259 7.89934 26.8092 7.16942ZM25.5369 10.7957L23.1705 8.15096L20.8722 10.2403L16.8306 13.7766L18.942 16.347L23.3853 12.6912L25.5369 10.7957ZM17.7443 16.7794L16.2308 14.937L15.507 17.1742L17.7443 16.7794Z" fill="white"/>
            </svg>)
    },
];

const routes_mobile =  [
    {
        url: '/payment',
        imgPath: '/sidebar/payment.png',
        activeImgPath: '/sidebar/payment-blue.png',
        title: 'Payment',
        external: false,
        svg: 
            (<svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.2327 3.19359C13.9511 2.99998 13.5683 3.04778 13.343 3.30519L9.61974 7.58773H5.15474C3.8294 7.58773 2.755 8.66218 2.755 9.98751V24.5252C2.755 25.8506 3.82941 26.925 5.15474 26.925H24.8452C26.1705 26.925 27.2449 25.8506 27.2449 24.5252V9.98751C27.2449 8.66218 26.1705 7.58773 24.8452 7.58773H23.4042L21.4514 3.68216C21.3718 3.52356 21.2325 3.40184 21.0631 3.34545C20.8917 3.28754 20.7076 3.30212 20.5487 3.38157L17.0367 5.13758L14.2336 3.19425L14.2327 3.19359ZM11.4035 7.58773L13.9626 4.64411L15.6642 5.82392L12.1366 7.58773H11.4035ZM21.8993 7.58773H15.1465L20.5487 4.88654L21.8993 7.58773ZM4.10103 9.98751C4.10103 9.40671 4.574 8.93376 5.15474 8.93376H24.8452C25.426 8.93376 25.8989 9.40671 25.8989 9.98751V12.3839H20.9626C18.7503 12.3839 16.9518 14.1864 16.9518 16.4016V17.062C16.9518 19.2769 18.7504 21.0789 20.9626 21.0789H25.8989V24.5252C25.8989 25.106 25.426 25.579 24.8452 25.579H5.15474C4.574 25.579 4.10103 25.106 4.10103 24.5252V9.98751ZM18.2979 16.4016C18.2979 14.9281 19.4941 13.7299 20.9626 13.7299H25.8989V19.7329H20.9626C19.494 19.7329 18.2979 18.535 18.2979 17.062V16.4016ZM20.9681 14.8292C19.9796 14.8292 19.1761 15.6327 19.1761 16.6212C19.1761 17.6098 19.9796 18.4132 20.9681 18.4132C21.9567 18.4132 22.7601 17.6098 22.7601 16.6212C22.7601 15.6327 21.9567 14.8292 20.9681 14.8292ZM20.5221 16.6212C20.5221 16.3757 20.7225 16.1752 20.9681 16.1752C21.2137 16.1752 21.4141 16.3757 21.4141 16.6212C21.4141 16.8668 21.2136 17.0673 20.9681 17.0673C20.7225 17.0673 20.5221 16.8668 20.5221 16.6212Z" fill="white"/>
            </svg>)
    },
    {
        url: '/placement',
        imgPath: '/sidebar/placement.png',
        activeImgPath: '/sidebar/placement-blue.png',
        title: 'Placement',
        external: false,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M23.8961 5.15884C25.7252 8.32689 25.7252 12.1085 23.8961 15.2765L15.6129 29.6235C15.5141 29.7946 15.3315 29.9 15.1339 29.9C14.9364 29.9 14.7538 29.7946 14.655 29.6235L6.37182 15.2765C4.54273 12.1085 4.54273 8.32689 6.37182 5.15884C8.20091 1.9908 11.4758 0.100006 15.1339 0.100006C18.7921 0.100006 22.0671 1.9908 23.8961 5.15884ZM7.32965 14.7235L15.1339 28.2409L22.9382 14.7235C24.5669 11.9024 24.567 8.53286 22.9382 5.71187C21.3095 2.8908 18.3913 1.20605 15.1339 1.20605C11.8766 1.20605 8.95842 2.8908 7.32965 5.71187C5.70095 8.53286 5.70095 11.9024 7.32965 14.7235Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M7.8335 10.232C7.8335 6.20664 11.1086 2.93158 15.1339 2.93158C19.1593 2.93158 22.4344 6.20657 22.4344 10.232C22.4344 14.2574 19.1593 17.5324 15.1339 17.5324C11.1086 17.5324 7.8335 14.2574 7.8335 10.232ZM12.1849 15.1813V15.6781L12.2379 15.7063C13.1027 16.1656 14.0885 16.4263 15.134 16.4264C16.1795 16.4264 17.1654 16.1656 18.0303 15.7061L18.0833 15.6779V15.1813C18.0833 13.555 16.7604 12.2321 15.1341 12.2321C13.5078 12.2321 12.1849 13.5551 12.1849 15.1813ZM19.1626 14.7223L19.1848 14.9182L19.3298 14.7845C20.5575 13.6521 21.3283 12.0305 21.3283 10.232C21.3283 6.81629 18.5496 4.03763 15.1339 4.03763C11.7182 4.03763 8.93954 6.81629 8.93954 10.232C8.93954 12.0306 9.71035 13.6523 10.9383 14.7848L11.0833 14.9185L11.1055 14.7225C11.2544 13.4064 12.036 12.2793 13.1386 11.6532L13.2615 11.5834L13.1548 11.4907C12.5162 10.9359 12.1117 10.1193 12.1117 9.20962C12.1117 7.54327 13.4675 6.18741 15.1339 6.18741C16.8003 6.18741 18.1561 7.54327 18.1561 9.20962C18.1561 10.1192 17.7516 10.9358 17.1131 11.4906L17.0064 11.5833L17.1293 11.653C18.232 12.2791 19.0136 13.4061 19.1626 14.7223ZM17.0501 9.20962C17.0501 8.153 16.1905 7.29346 15.1339 7.29346C14.0773 7.29346 13.2177 8.153 13.2177 9.20962C13.2177 10.2662 14.0772 11.1258 15.1339 11.1258C16.1906 11.1258 17.0501 10.2662 17.0501 9.20962Z" fill="white"/>
            </svg>)
    },
    {
        url: '/courses',
        imgPath: '/sidebar/courses.png',
        activeImgPath: '/sidebar/courses-blue.png',
        title: 'My Courses',
        external: false,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M26.0156 1.87499C26.0156 1.48686 25.7006 1.17186 25.3125 1.17186H20.6597C20.6409 1.16999 20.625 1.16061 20.6053 1.16061H13.1456C13.1259 1.16061 13.11 1.16999 13.0912 1.17186H6.75092C6.72467 1.17093 6.69373 1.16999 6.66279 1.16999C5.2181 1.16999 4.0406 2.31374 3.98529 3.74436V3.74905V26.6137C4.09123 27.8615 5.12998 28.8328 6.39654 28.8328C6.4556 28.8328 6.51373 28.8309 6.57185 28.8262H6.56435H25.3144C25.7025 28.8262 26.0175 28.5112 26.0175 28.1231C26.0175 27.735 25.7025 27.42 25.3144 27.42H6.75185C6.73029 27.4209 6.70498 27.4219 6.67967 27.4219C6.00748 27.4219 5.45529 26.909 5.39248 26.2528V26.2472V25.1006C5.39248 24.6703 5.93998 24.2934 6.56248 24.2934L25.3144 24.3469C25.5084 24.3469 25.6847 24.2681 25.8112 24.1406C25.8272 24.1247 25.8347 24.1022 25.8487 24.0844C25.8928 24.0366 25.9303 23.9812 25.9594 23.9222L25.9612 23.9184C25.9762 23.8725 25.9884 23.82 25.9931 23.7647V23.7619C26.0034 23.729 26.0119 23.6897 26.0165 23.6484V23.6456V23.6437L26.0156 1.87499ZM24.6094 22.9415L10.0781 22.9003V2.57811H12.4415V12.3731C12.4415 12.3731 12.4415 12.3731 12.4415 12.3741C12.4415 12.7622 12.7565 13.0762 13.1437 13.0762C13.3134 13.0762 13.469 13.0162 13.59 12.9159L13.589 12.9169L16.8797 10.2272L20.1647 12.8559C20.2837 12.9516 20.4365 13.0097 20.6034 13.0097C20.9915 13.0097 21.3065 12.6947 21.3075 12.3066V2.57811H24.6084L24.6094 22.9415ZM19.9022 2.57811V10.8431L17.3147 8.77311C17.1956 8.67655 17.0428 8.61843 16.8759 8.61843C16.7062 8.61843 16.5506 8.67843 16.4287 8.77968L16.4297 8.77874L13.8478 10.89V2.57811H19.9022ZM5.3906 23.1431V3.74999C5.45342 3.08905 6.00654 2.57624 6.67873 2.57624C6.70404 2.57624 6.72935 2.57718 6.75373 2.57811H6.74998H8.67185V22.8956L6.56248 22.89C6.13779 22.8919 5.73467 22.9847 5.37279 23.1506L5.3906 23.1431Z" fill="white"/>
            </svg>)
    },
    {
        url: 'https://jobs.businesstoys.in/',
        imgPath: '/sidebar/job.png',
        activeImgPath: '/sidebar/job-blue.png',
        title: 'Job portal',
        external: true,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M8.88235 7.00294C8.88235 5.34446 10.2268 4 11.8853 4H18.2382C19.8968 4 21.2412 5.34447 21.2412 7.00294V7.52941H24.1206C25.7791 7.52941 27.1235 8.87388 27.1235 10.5324V22.2971C27.1235 23.9556 25.7791 25.3 24.1206 25.3H6.00294C4.34447 25.3 3 23.9556 3 22.2971V10.5324C3 8.87387 4.34446 7.52941 6.00294 7.52941H8.88235V7.00294ZM6.00294 8.82941C5.06243 8.82941 4.3 9.59184 4.3 10.5324V15.2382C4.3 16.1788 5.06243 16.9412 6.00294 16.9412H14.4118V15.2382C14.4118 14.8793 14.7028 14.5882 15.0618 14.5882C15.4207 14.5882 15.7118 14.8793 15.7118 15.2382V16.9412H24.1206C25.0611 16.9412 25.8235 16.1788 25.8235 15.2382V10.5324C25.8235 9.59184 25.0611 8.82941 24.1206 8.82941H6.00294ZM19.9412 7.52941H10.1824V7.00294C10.1824 6.06243 10.9448 5.3 11.8853 5.3H18.2382C19.1788 5.3 19.9412 6.06243 19.9412 7.00294V7.52941ZM25.8235 17.712C25.3396 18.0457 24.7529 18.2412 24.1206 18.2412H15.7118V19.9441C15.7118 20.3031 15.4207 20.5941 15.0618 20.5941C14.7028 20.5941 14.4118 20.3031 14.4118 19.9441V18.2412H6.00294C5.37061 18.2412 4.78392 18.0457 4.3 17.712V22.2971C4.3 23.2376 5.06243 24 6.00294 24H24.1206C25.0611 24 25.8235 23.2376 25.8235 22.2971V17.712Z" fill="white"/>
            </svg>)
    },
    {
        url: 'https://businesstoys.in/blog/',
        imgPath: '/sidebar/blog.png',
        activeImgPath: '/sidebar/blog-blue.png',
        title: 'Blogs',
        external: true,
        svg: 
            (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.40002 7.61125C1.40002 5.63066 3.00561 4.02507 4.9862 4.02507H21.4102C22.1251 4.02507 22.7929 4.23492 23.3531 4.59658C23.6315 4.77631 23.7115 5.1477 23.5317 5.42609C23.352 5.70449 22.9806 5.78447 22.7022 5.60473C22.3302 5.36453 21.8874 5.22507 21.4102 5.22507H4.9862C3.66835 5.22507 2.60002 6.2934 2.60002 7.61125V18.9338C2.60002 20.2517 3.66835 21.32 4.9862 21.32H5.54611C5.87748 21.32 6.14611 21.5886 6.14611 21.92V24.2493L8.85107 21.4993C8.96386 21.3846 9.11798 21.32 9.27883 21.32H21.4102C22.728 21.32 23.7963 20.2517 23.7963 18.9338V15.8232C23.7963 15.4919 24.065 15.2232 24.3963 15.2232C24.7277 15.2232 24.9963 15.4919 24.9963 15.8232V18.9338C24.9963 20.9144 23.3908 22.52 21.4102 22.52H9.53026L5.97386 26.1357C5.80305 26.3093 5.54421 26.3625 5.31879 26.2702C5.09337 26.1779 4.94611 25.9585 4.94611 25.7149V22.5198C2.98399 22.4983 1.40002 20.901 1.40002 18.9338V7.61125Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.2572 8.79327C5.2572 8.4619 5.52583 8.19327 5.8572 8.19327H17.4908C17.8222 8.19327 18.0908 8.4619 18.0908 8.79327C18.0908 9.12464 17.8222 9.39327 17.4908 9.39327H5.8572C5.52583 9.39327 5.2572 9.12464 5.2572 8.79327Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.2572 13.5836C5.2572 13.2522 5.52583 12.9836 5.8572 12.9836H13.5715C13.9029 12.9836 14.1715 13.2522 14.1715 13.5836C14.1715 13.915 13.9029 14.1836 13.5715 14.1836H5.8572C5.52583 14.1836 5.2572 13.915 5.2572 13.5836Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M5.2572 18.3739C5.2572 18.0426 5.52583 17.7739 5.8572 17.7739H12.265C12.5964 17.7739 12.865 18.0426 12.865 18.3739C12.865 18.7053 12.5964 18.9739 12.265 18.9739H5.8572C5.52583 18.9739 5.2572 18.7053 5.2572 18.3739Z" fill="white"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M23.5437 6.19847C24.7314 5.1326 26.5475 5.18998 27.6655 6.32871C28.8643 7.54972 28.7928 9.52671 27.5088 10.6578L24.1633 13.605L19.1191 17.7554L13.7513 18.7026L15.478 13.3656L20.0777 9.34087L22.8136 6.85363L23.5437 6.19847ZM26.8092 7.16942C26.1409 6.48868 25.0552 6.45437 24.3452 7.09156L24.061 7.34659L26.4373 10.0025L26.7155 9.7574C27.4831 9.08121 27.5259 7.89934 26.8092 7.16942ZM25.5369 10.7957L23.1705 8.15096L20.8722 10.2403L16.8306 13.7766L18.942 16.347L23.3853 12.6912L25.5369 10.7957ZM17.7443 16.7794L16.2308 14.937L15.507 17.1742L17.7443 16.7794Z" fill="white"/>
            </svg>)
    },
];

function SideBar(){
    const navigate = useNavigate();
    const { sidebar } = useSelector( state => state.navigation);
    const [logOut] = useLogOutMutation();
    let location = useLocation();
    const [active, setActive] = useState('');
    const [stickyFooter, setStickyFooter] = useState(false);

    const logoutClick = async() => {
        try{
            let response = await logOut();
            if(response['data'].status === 'success'){
                toastService.success('Logged Out');
                navigate('/login');
            }else{
                toastService.error();
            }
        }catch(err){
            toastService.error();
        }
    };

    useEffect(()=>{
        setActive(location.pathname);
        if(window.innerWidth < 770){
            setStickyFooter(true);
        }
    }, [location.pathname]);


    const renderRoutes = () => routes.map((e, i)=>{
        const linkProps = {
            className: (active === `${e.url}`) ? 'sidebar__card sidebar__card--active' : 'sidebar__card',
            id: 'courses'
        };
        return e.external 
        ? (
        <a href={e.url} key={i} target="__blank" {...linkProps} >
            {e.svg}
            <p>{e.title}</p>
        </a>) 
        : (
        <Link to={e.url} key={i} {...linkProps} >
            { e.svg }
            <p>{e.title}</p>
        </Link>)
    });

    return(
        <>
            <div className="sidebar" style={{ display: (sidebar) ? 'flex' : 'none' }}  >
                <div className="sidebar__menu" >
                    <img src="/assets/icons/menu.png" alt="menu" />
                </div>
                {renderRoutes()}
                {/*
                <div className="sidebar__card" >
                    <img src={assets + '/sidebar/support.png'} alt="navbar icon" />
                    <p>
                        Support
                    </p>
                </div>*/}
            </div>
            <div className="sidebar__footer"  style={{ display: (sidebar && stickyFooter) ? 'flex' : 'none' }}  >
                {
                    routes_mobile.map((e,i)=>{
                        return (e.external) 
                        ? (
                        <a href={e.url} key={i} target="__blank" >
                            {e.svg}
                            <p>{e.title}</p>
                        </a>) 
                        : 
                        (
                        <Link to={e.url} key={i}  >
                            { e.svg }
                            <p>{e.title}</p>
                        </Link>)
                    })
                }
            </div>
        </>
    )
};

export default SideBar;