import React, { useState } from 'react';
import ReactStars from 'react-stars';

import { useDispatch, useSelector } from 'react-redux';
import toastService from '../../toast';
import { useSubmitFeedbackMutation, popupFeedback } from '../../store';

function FeedbackPopup(){
    const dispatch = useDispatch();
    const popup = useSelector(state => state.popup)
    const videoDetails = useSelector(state => state.videoDetails)
    const [feedbackSubmit] = useSubmitFeedbackMutation();
    const [comments, setComments] = useState('');
    const [star, setStar] = useState(0);

    const formSubmit = async(e) => {
        try{
            e.preventDefault();
            if(star === 0){
                toastService.error('Select valid rating!');
                return;
            }
            if(comments.length < 3){
                toastService.error('Enter valid comment');
                return;
            }

            let response = await feedbackSubmit({
                _id: videoDetails._id,
                body: {
                    rating: star,
                    comments
                }
            });
            await dispatch(popupFeedback(false));

            if(response.data.status === 'created'){
                toastService.success('Feedback submitted');
                // await dispatch(popupFeedback(false));
            }
        }catch(error){
            toastService.error(error.message);
        }
    }

    return(
        <section className='popup__feedback' style={{ display: (popup.feedback) ? 'block' : 'none' }}  >
            <form onSubmit={formSubmit} className="popup__feedback__container">
                <label>Feedback for - {videoDetails.title} *</label>
                <ReactStars
                    count={5}
                    value={star}
                    onChange={(_e)=>{setStar(_e)}}
                    size={37}
                    half={false}
                    color2={'#145EEE'} />
                <textarea value={comments} placeholder="Comments....." onChange={(e)=>setComments(e.target.value)} ></textarea>
                <button className='btn' >Submit</button>
            </form>
        </section>
    )
};

export default FeedbackPopup;