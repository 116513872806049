import React, { useEffect, useState, useRef } from 'react';
import FileDownload from 'js-file-download';

import { useAssignmentListMutation } from '../../store';
import toastService from '../../toast';
import { dateFormatter, daysLeft } from '../../utils/__utility';
import { downloadAssignment, downloadSubmission, uploadAssignment } from '../../utils/__axios';
import CONSTANTS from '../../constants';
import { useParams } from 'react-router-dom';

function Assignment(props){
    const { id } = useParams();
    const { assets } = CONSTANTS;
    const [fetchAssignment] = useAssignmentListMutation();
    const [assignment, setAssignment] = useState([]);
    const [active, setActive] = useState(null);
    const [file, setFile] = useState();
    const inputRef = useRef();

    const loadData = async() => {
        try{
            let response = await fetchAssignment(id);
            let { data, status } = response.data;
            if(status === 'success'){
                setAssignment(data);
            }else{
                toastService.error();
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    };

    const downloadClick = async(_key, _name, _type) => {
        try{
            window.scrollTo(0,0);
            toastService.info('Downloading...');
            let response;
            if(_type === 'assets') response =  await downloadAssignment(_key);
            if(_type === 'submission') response = await downloadSubmission(_key);
            FileDownload(response.data, _name);
        }catch(err){
            toastService.error();
        }
    };

    const uploadFile = async(_id) => {
        try{
            toastService.info('Uploading...')
            const formData = new FormData();
            formData.append(
                "submission",
                file,
                file.name
            );
            let response = await uploadAssignment(_id, formData);
            const { status } = response.data;
            if(status === 'uploaded'){
                toastService.success('Uploaded');
                loadData();
            }else{
                toastService.error();
            }
        }catch(err){
            toastService.error();
        }
    }

    useEffect(()=>{
        window.scrollTo(0, 0);
        loadData();
    }, [props]);

    const renderCards = () => {
        return assignment.map((e)=>{
            const leftDays = daysLeft(new Date(e.deadLine));
            const percentage = (leftDays < 0) ? 100 : (100 - (leftDays * 10));
            return(
                <div className='video__assignment__card' key={e._id} onClick={()=>{
                    setActive(e._id);
                    setFile({});    
                }} >
                <div className='video__assignment__card__container' >
                    <div className='video__assignment__card__trainer' >
                        <img src={e.trainerId.profilePic} alt={e.trainerId.name} />
                        <h6>{e.trainerId.name}</h6>
                    </div>
                    <div className='video__assignment__card__content' >
                        <h4>{e.assessmentId.module}</h4>
                        <h5>{e.assessmentId.title}</h5>
                    </div>
                    <div className='video__assignment__card__deadline' >
                        <h5>{dateFormatter(e.deadLine)}</h5>
                    </div>
                    <div className='video__assignment__card__status'>
                        {e.students[0].submissions.length === 0 && <h5> Assigned </h5>}
                        {(e.students[0].submissions.length > 0 && !e.students[0].completed ) && <h6>In- Progress</h6>}
                        {(e.students[0].submissions.length > 0 && e.students[0].completed ) && <h4>Approved</h4>}
                    </div>
                    <div className='video__assignment__card__percentage' >
                        <div><span style={{ 
                            width: `${percentage}%`,
                            backgroundColor: (leftDays <= 0) ? '#E40000' : '#2A70FE' 
                        }} ></span></div>
                        <h5>{(leftDays < 0) ? 0 : leftDays} days left</h5>
                    </div>
                    <div className='video__assignment__card__arr' >
                        <img style={{
                            transform: `rotate(${(active === e._id) ? 180 : 0}deg)`
                        }}  src={assets + '/icons/arrow-down.png'} alt="arrow-down" />
                    </div>
                </div>
                {
                    (active === e._id) && 
                    (
                        <div className='video__assignment__card__active' >
                            <div className='video__assignment__card__active__assets' >
                                <h4>Assets</h4>
                                { e.assessmentId.attachments.map((_att)=>(
                                    <p key={_att._id} onClick={()=>downloadClick(_att.awsKey, _att.filename, 'assets')} >
                                        {_att.filename}
                                    </p>
                                )) }
                            </div>
                            <div className='video__assignment__card__active__submission' >
                                <h4>Your Submissions</h4>
                                { e.students[0].submissions.map((_sub)=>(
                                    <p key={_sub._id} onClick={()=>downloadClick(_sub.awsKey, _sub.filename, 'submission')}  >
                                        {_sub.filename}
                                    </p>
                                )) }
                            </div>
                            <div className='video__assignment__card__active__upload' >
                                <input type='file' name='submission' ref={inputRef} onChange={(_event)=>{setFile(_event.target.files[0])}}/>
                                <button className='btn video__assignment__card__active__upload__file' 
                                    onClick={()=>{inputRef.current.click()}} >Choose File</button>
                                <button className='btn' onClick={()=>uploadFile(e._id)} >Upload</button>
                                <p>{file.name}</p>
                            </div>
                            <div className='video__assignment__card__active__comment' >
                                <h4>Comments</h4>
                                    <p>
                                        <ul>
                                        {
                                            e.students[0].trainerComments.map((_comm, i)=>(
                                                <li key={i} >
                                                    {_comm.comment}&nbsp;&nbsp;&nbsp; <span>- {dateFormatter(_comm.commentDate)}</span> 
                                                </li>
                                            ))
                                        }
                                        </ul>
                                    </p>
                                    
                            </div>
                        </div>
                    )
                }
            </div>
        )}
        )
    }

    return(
        <section className="video__assignment" >
            {(assignment.length > 0) ? renderCards() : <h5 className='video__assignment__no' >No assignments assigned </h5>}
        </section>
    )
};

export default Assignment;