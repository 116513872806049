import { createSlice } from '@reduxjs/toolkit';

const notificationSlice = createSlice({
    name: 'notification',
    initialState: { 
        token: null
    },
    reducers: {
        setNotificationToken(state, action){
            state['token'] = action.payload;
            return state;
        }
    }

});

export const { setNotificationToken } = notificationSlice.actions;
export { notificationSlice };