import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TimeAgo from 'react-timeago';
import { useNavigate } from 'react-router-dom';

import CONSTANTS from '../../constants';
import { useGetNotificationsQuery, useReadNotificationsMutation } from '../../store';
import toastService from '../../toast';
import { globalQueryError } from '../../utils/__errorHandling';
let assets = CONSTANTS.assets;

function Notification(){
    const { token } = useSelector((state)=>state.notification);
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    let _data = [];
    let { data, error} = [];
    // const { data, error, refetch } = useGetNotificationsQuery(Date.now());
    const [readNotification] = useReadNotificationsMutation();

    useEffect(()=>{
        // refetch();
    }, []);
    
    if(error){
        if(error['data']){
            const { statusCode } = error['data'].error;
            globalQueryError(statusCode, error['data']);
        }else{
            toastService.error(error['error']);
        }
    };

    if(data){
        if(data['status'] === 'success'){
            _data = data['data'];
        }
    };

    const notificationClick = async(_ele) => {
        try{
            let response = await readNotification(_ele['_id']);
            if(response.data.status === 'success'){
                // refetch();
                setShow(false);
                navigate(_ele['click_action'] || '#');
            }
        }catch(err){
            toastService.error();
        }
    };

    return(
        <div className='nav__home__noti' id='notifications' >
            {_data.length > 0 ? <span>{_data.length}</span> : null}
            { token 
                ? (<img onClick={()=>setShow(!show)} src={assets + '/icons/notification.png'} alt="notification" />) 
                : (<img src={assets + '/icons/notification-disabled.png'} alt="notification" />)
            }
            {show && (
                <div className='nav__home__noti--pop' >
                    <h2>Notifications <img src={assets + '/icons/settings.png'} alt="settings" /></h2>
                    {   /*
                        <h5>
                            <div>All</div>
                            <div>Classes</div>
                            <div>Other</div>
                        </h5>
                        */
                    }
                    <div className='nav__home__noti--pop__container' >
                        { 
                            _data.map((e)=>(
                                <div key={e._id} onClick={()=>notificationClick(e)}   className='nav__home__noti--pop__container__card' >
                                    <div className='nav__home__noti--pop__container__card--trainer' >
                                        <img src={e.trainerId.profilePic} alt={e.trainerId.name} />
                                        <div>
                                            <h4>{e.trainerId.name}</h4>
                                            <p><TimeAgo date={e.createdTime} /></p>
                                        </div>
                                    </div>
                                    <p className='nav__home__noti--pop__container__card--main' >
                                        {e.body}
                                    </p>
                                </div>
                            )) 
                        }
                        { _data.length === 0 && <p className='nav__home__noti--pop__container--empty' >No new notifications</p> }
                    </div>
                </div>
            )}
        </div>
    );
};

export default Notification;