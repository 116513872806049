import React, { useEffect, useState } from 'react';

import Resources from './Resources';
import Notes from './Notes';
import Content from './Content';
import toastService from '../../toast';


function Nav({ data, course }){
    let _data = data;
    const [width] = useState(window.innerWidth);
    const [fullwidth, setFullwidth] = useState(false);
    const [navigation, setNavigation] = useState('resources');

    useEffect(()=>{
        window.scrollTo(0,0);
        if(width < 770){
            setFullwidth(true);
        }else{
            setFullwidth(false);
        };
        // loadData();
    }, [width]);

    // console.log({ _data });

    return(
        <div className='courseVideo__player__features' >
            <div className='courseVideo__player__features--title' >
                { fullwidth 
                    ? <h1 id='course-content'
                    className={(navigation === 'course_content') ? 'courseVideo--active' : null} 
                    onClick={()=>setNavigation('course_content')}
                    >Course Content</h1> : null 
                }
                {/* <h1 
                    className={(navigation === 'notes') ? 'courseVideo--active' : null} 
                    onClick={()=>setNavigation('notes')}
                >Your Notes</h1> */}
                <h1 id='resources'
                    className={(navigation === 'resources') ? 'courseVideo--active' : null} 
                    onClick={()=>setNavigation('resources')}
                >Resources</h1>
            </div>
            {/* COURSE CONTENT */}
            <div style={{ marginTop: '30px' }} >
                { fullwidth 
                    ? ( (navigation === 'course_content') && <Content {...course} /> )
                    : null 
                }
            </div>
            {/* NOTES */}
            {/* { (navigation === 'notes') && <Notes />} */}
            {/* RESOURCES */}
            {(navigation === 'resources') && <Resources />}
        </div>
    )
};

export default Nav;