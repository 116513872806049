import {toast} from 'react-toastify';
// import { toast } from 'react-hot-toast';
/*
let error = (message = 'Something went wrong, try again') => {
    toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}

let success = (message = '')=>{
    toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

let info = (message = '') => {
    toast.info(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
};

let waiting = (message = '') => {
    toast(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
}

*/

const error = (_message = 'Something went wrong') => {
    toast.error(_message);
}

let success = (_message) => {
    toast.success(_message);
}

let info = (_message) => {
    toast.info(_message);
}

let waiting = (_promise, { _success = 'success', _error = 'Something went wrong' }) =>{
    toast.promise(_promise, {
        loading: 'loading...',
        success: _success,
        error: _error,
    });
}

let toastService = {
    error,
    success,
    info,
    waiting
}


export default toastService;