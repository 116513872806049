const React = require('react');

const map = new Map();

const setRef = function setRef(key) {
  if (!key) return console.warn("useDynamicRefs: Cannot set ref without key ");
  const ref = React.createRef();
  map.set(key, ref);
  return ref;
};

const getRef = function getRef(key) {
  if (!key) return console.warn("useDynamicRefs: Cannot get ref without key");
  return map.get(key);
};

const useDynamicRefs = function useDynamicRefs() {
  return [getRef, setRef];
};

module.exports = useDynamicRefs;