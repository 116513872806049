import { createApi, fetchBaseQuery  } from '@reduxjs/toolkit/query/react';

import CONSTANTS from '../../constants';

const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ 
        baseUrl: CONSTANTS.BaseURL, 
        credentials: 'include',
    }),
    keepUnusedDataFor: 0.01,
    refetchOnMountOrArgChange: 0.01,
    prepareHeaders: (headers, { getState }) => {
        headers.set('Accept', 'application/json');
        headers.set('Cache-Control', 'no-cache');
        headers.set('Pragma', 'no-cache');
        headers.set('Expires', '0');
        return headers;
      },
    endpoints(builder){
        return{
            googleLogin: builder.mutation({
                query: (token) => {
                    return{
                        url: '/v2/google-login',
                        method: 'POST',
                        body: {
                            token: token
                        }
                    }
                }
            }),
            emailLogin: builder.mutation({
                query: (_data) => {
                    return {
                        url: '/auth/login',
                        method: 'POST',
                        body: _data 
                    }
                } 
            }),
            onBoard: builder.mutation({
                query: (_data) => {
                    return {
                        url: '/onboard',
                        method: 'POST',
                        body: _data
                    }
                }
            }),
            homeData: builder.mutation({
                query: () => {
                    return {
                        url: '/home',
                        method: 'GET',
                    }
                }
            }),
            courses: builder.query({
                query: () => {
                    return {
                        url: '/courses',
                        method: 'GET'
                    }
                }
            }),
            payment: builder.query({
                query: () => {
                    return {
                        url: '/payment-details',
                        method: 'GET'
                    }
                }
            }),
            razorpayOrder: builder.mutation({
                query: (_amount) => {
                    return {
                        url: '/payment',
                        method: 'GET',
                        params: {
                            amount: _amount
                        }
                    }
                }
            }),
            razorpayVerify: builder.mutation({
                query: (_data) => {
                    return {
                        url: '/payment',
                        method: 'POST',
                        body: _data
                    }
                }
            }),
            recordings: builder.mutation({
                query: (_courseId) => {
                    return {
                        url: '/playlist',
                        method: 'GET',
                        params: {
                            courseId: _courseId
                        }
                    }
                }
            }),
            studyMaterial: builder.query({
                query: () => {
                    return {
                        url: '/study-material',
                        method: 'GET'
                    }
                }
            }),
            customClickEvent: builder.mutation({
                query: (_course) => {
                    return {
                        url: '/mix-track',
                        method: 'POST',
                        body: {
                            courseName: _course
                        }
                    }
                }
            }),
            markAttendance: builder.mutation({
                query: (_id) => {
                    return {
                        url: `/v2/attendance/${_id}`,
                        method: 'GET'
                    }
                }
            }),
            notificationToken: builder.mutation({
                query: (_token) => {
                    return {
                        url: '/notification-token',
                        method: 'POST',
                        body: {
                            token: _token
                        }
                    }
                }
            }),
            getNotifications: builder.query({
                query: () => {
                    return {
                        url: '/notifications',
                        method: 'GET'
                    }
                }
            }),
            readNotifications: builder.mutation({
                query: (_id) => {
                    return {
                        url: `/notifications/read/${_id}`,
                        method: 'GET'
                    }
                }
            }),
            courseSignUpDetail: builder.mutation({
                query: (_slug) => {
                    return {
                        url: `/offline/course/${_slug}`,
                        method: 'GET'
                    }
                }
            }),
            courseSignUp: builder.mutation({
                query: ({_id, token}) => {
                    return {
                        url: `/offline/signup/${_id}`,
                        method: 'POST',
                        body: {
                            token: token
                        }
                    }
                }
            }),
            examSignUp: builder.mutation({
                query: ({_id, data}) => {
                    return {
                        url: `/exam/signup/${_id}`,
                        method: 'POST',
                        body: data
                    }
                }
            }),
            updateNumber: builder.mutation({
                query: (_data) => {
                    return {
                        url: '/update-number',
                        method: 'POST',
                        body: { number: _data }
                    }
                }
            }),
            getFeedback: builder.mutation({
                query: (_id) => {
                    return {
                        url: `/feedback/session/${_id}`,
                        method: 'GET'
                    }
                }
            }),
            submitFeedback: builder.mutation({
                query: (_data) => {
                    return {
                        url: `/feedback/${_data._id}`,
                        method: 'POST',
                        body: _data.body
                    }
                }
            }),
            kycStatus: builder.mutation({
                query: () =>{
                    return {
                        url: `/kyc-status`,
                        method: 'GET'
                    }
                }
            }),
            assignmentList: builder.mutation({
                query: (_id) => {
                    return {
                        url: `/assignment?course=${_id}`,
                        method: 'GET'
                    }
                }
            }),
            checkOnBoard: builder.mutation({
                query: () => {
                    return {
                        url: '/onboard/check',
                        method: 'GET'
                    }
                }
            }),
            logOut: builder.mutation({
                query: () => {
                    return{
                        url: '/auth/logout',
                        method: 'GET'
                    }
                }
            }),
            magicLink: builder.mutation({
                query: (_data) => {
                    return{
                        url: '/auth/magic-link',
                        method: 'POST',
                        body: _data
                    }
                }
            }),
            magicLinkValidate: builder.mutation({
                query: (_id) => {
                    return {
                        url: `/auth/magic-link/${_id}`,
                        method: 'GET'
                    }
                }
            }),
            saveNotes: builder.mutation({
                query: (_data) => {
                    return {
                        url: `/notes/save`,
                        method: 'POST',
                        body: _data
                    }
                }
            }),
            getNotes: builder.mutation({
                query: ({
                    _course,
                    _recording
                }) => {
                    return {
                        url: `/notes/list/${_course}/${_recording}`,
                        method: 'GET'
                    }
                }
            }),
            quizDetails: builder.mutation({
                query: (_params) => {
                    return {
                        url: `/quiz/details/${_params}`,
                        method: 'GET'
                    }
                }
            }),
            quizTopicList: builder.mutation({
                query: (_params) => {
                    return {
                        url: `/quiz/topic/list/${_params}`,
                        method: 'GET'
                    }
                }
            }),
            quizAnswerCheck: builder.mutation({
                query: ({ _id, _data }) => {
                    return {
                        url: `/quiz/answer/${_id}`,
                        method: 'POST',
                        body: _data
                    }
                }
            }),
            quizResultCheck: builder.mutation({
                query: (_slug) => {
                    return {
                        url: `/quiz/result/${_slug}`,
                        method: 'GET'
                    }
                }
            }),
            resumeList: builder.query({
                query: () => {
                    return {
                        url: '/resume/list',
                        method: 'GET'
                    }
                }
            }),
            resumeUpload: builder.mutation({
                query: (_file) => {
                    return {
                        url: '/resume/upload',
                        method: 'POST'
                    }
                }
            }),
            mockInterviewSchedule: builder.mutation({
                query: (_data) => {
                    return {
                        url: '/mock-interview/schedule',
                        method: 'POST',
                        body: _data
                    }
                }
            }),
            mockInterviewList: builder.mutation({
                query: () => {
                    return {
                        url: '/mock-interview/list',
                        method: 'GET'
                    }
                }
            }),
            personalizedJobs: builder.mutation({
                query: (_page = 1) => {
                    return {
                        url: `/personalized-jobs?pageNumber=${_page}`,
                        method: 'GET'
                    }
                }
            }),
            jobportalJobs: builder.mutation({
                query: (_page = 1) => {
                    return {
                        url: `/job-portal?pageNumber=${_page}`,
                        method: 'GET'
                    }
                }
            }),
            placementProcessAccess: builder.mutation({
                query: () => {
                    return{
                        url: '/placement-process-access',
                        method: 'GET'
                    }
                }
            }),
            interviewPrepTopics: builder.mutation({
                query: () => {
                    return{
                        url: '/interview-assessment',
                        method: 'GET'
                    }
                }
            }),
            interviewPrepTopicsCases: builder.mutation({
                query: (_topic) => {
                    return{
                        url: `/interview-assessment/${_topic}`,
                        method: 'GET'
                    }
                }
            }),
            interviewPrepDetail: builder.mutation({
                query: (_slug) => {
                    return{
                        url: `/interview-assessment-detail/${_slug}`,
                        method: 'GET'
                    }
                }
            }),
            coursePlaylist: builder.mutation({
                query: (_id) => {
                    return {
                        url: `/playlist/course/${_id}`,
                        method: 'GET'
                    }
                }
            }),
            courseProgress: builder.mutation({
                query: (_id) => {
                    return {
                        url: `/course-progress/${_id}`,
                        method: 'GET'
                    }
                }
            }),
            courseAssets: builder.mutation({
                query: (_id) => {
                    return {
                        url: `/course-assets/${_id}`,
                        method: 'GET'
                    }
                }
            })
        }
    },
});


export const { 
        useGoogleLoginMutation, 
        useEmailLoginMutation ,
        useHomeDataMutation,
        useOnBoardMutation,
        useCoursesQuery,
        usePaymentQuery,
        useRazorpayOrderMutation,
        useRazorpayVerifyMutation,
        useRecordingsMutation,
        useStudyMaterialQuery,
        useCustomClickEventMutation,
        useMarkAttendanceMutation,
        useNotificationTokenMutation,
        useGetNotificationsQuery,
        useReadNotificationsMutation,
        useCourseSignUpDetailMutation,
        useCourseSignUpMutation,
        useExamSignUpMutation,
        useUpdateNumberMutation,
        useGetFeedbackMutation,
        useSubmitFeedbackMutation,
        useKycStatusMutation,
        useAssignmentListMutation,
        useCheckOnBoardMutation,
        useLogOutMutation,
        useMagicLinkMutation,
        useMagicLinkValidateMutation,
        useSaveNotesMutation,
        useGetNotesMutation,
        useQuizDetailsMutation,
        useQuizTopicListMutation,
        useQuizAnswerCheckMutation,
        useQuizResultCheckMutation,
        useResumeListQuery,
        useResumeUploadMutation,
        useMockInterviewScheduleMutation,
        useMockInterviewListMutation,
        usePersonalizedJobsMutation,
        useJobportalJobsMutation,
        usePlacementProcessAccessMutation,
        useInterviewPrepTopicsMutation,
        useInterviewPrepTopicsCasesMutation,
        useInterviewPrepDetailMutation,
        useCoursePlaylistMutation,
        useCourseProgressMutation,
        useCourseAssetsMutation
    } =  api;
export { api };