import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setCookie } from '../../storage';
import toastService from '../../toast';
import { 
    toggleNavigation, 
    useGoogleLoginMutation, 
    useEmailLoginMutation, 
    disablePopup,
    useMagicLinkMutation,
    setUser,
    setGuideModal,
    toggleRun
 } from '../../store';
import CONSTANTS from '../../constants';
import { globalError } from '../../utils/__errorHandling';
import { analyticsUserProperties, analyticsEvent } from '../../firebase';

function Login(){
    const [email, setEmail] = useState('');
    // const [password, setPassword] = useState('');
    // const [ emailLogin ] = useEmailLoginMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ googleLogin ] = useGoogleLoginMutation();
    const redirect = useSelector((state => state.redirect));
    const [magicLink] = useMagicLinkMutation();
    const [btn, setBtn] = useState(false);

    useEffect(()=>{
        dispatch(toggleNavigation(false));
        dispatch(disablePopup());
        dispatch(setGuideModal(false));
        dispatch(toggleRun(false));
        analyticsEvent({
            _event: 'login_page',
            _type: 'view'
        });
    }, [dispatch]);
    
    const successNavigateHandel = async(_data) => {
        let { onboard, user } = _data;
        
        dispatch(setUser(user));
        await setCookie(CONSTANTS.authCookie, true, {
            path: '/',
            expires: CONSTANTS.cookieExpire
        })
        if(onboard){
            navigate('/onboard');
        }
        else{
            navigate(redirect);
        }

        analyticsUserProperties({ _prop: {...user, email} });
        analyticsEvent({ _event: 'login', _type: 'click', _custom: user });
    }

    const responseGoogle = async(googleObj) => {
        try{
            let token = googleObj['credential'];
            if(!token){
                toastService.error();
                return;
            };
            let response = await googleLogin(token);
            if(response['error']){
                globalError(response['error']);
                return;
            };
            if(response['data']){
                successNavigateHandel(response['data']);
                return;
            };
        }catch(err){
            toastService.error();
        }
    };

    const googleLoginErr = () => {
        toastService.error();
    };

    let formSubmit = async(e) => {
        try{
            e.preventDefault();
            await setBtn(true);

            if(!email || !`${email}`.includes('@')){
                toastService.error('Enter valid email');
                return;
            };

            let response = await magicLink({ email });
            setBtn(false);
            

            if(response['error']){
                let error = response['error'];
                if(error['status'] === 404) toastService.error('Enter registered EmailId');
                return;
            }

            if(response['data']){
                let data = response['data'];
                if(data['status'] === 'success') toastService.success('Check your email for Login Link');
            }

            // if(!password){
            //     toastService.error('Enter valid password');
            //     return;
            // };

            // const response = await emailLogin({ email, password });
            // if(response['error']){
            //     globalError(response['error']);
            //     return;
            // };

            // if(response['data']){
            //     successNavigateHandel(response['data']);
            //     return;
            // };

            // navigate(redirect);

        }catch(err){
            toastService.error();
        }
    };
   
    return(
        <section className='login' >
            <div className='login__container' >
                <h1> Login to Your Business Toys Account! </h1>
                <div>
                    <GoogleLogin
                        onSuccess={responseGoogle}
                        onError={googleLoginErr}
                        />
                </div>
                <h3>Or</h3>
                <form onSubmit={formSubmit}>
                    <h1>Login with Email Link</h1>
                    <div className='login__container__input'>
                        <label>Registered Email</label>
                        <input type='email' name="email" value={email} 
                           onChange={e=>setEmail(e.target.value)} placeholder='Enter email address' />
                    </div>
                    {/*<div className='login__container__input'>
                        <label>Password</label>
                        <input type='password' name="password" value={password}
                            onChange={e=>setPassword(e.target.value)} placeholder='Enter password' />
                    </div>*/}
                    <button disabled={btn} >Login</button>
                </form>
            </div>
        </section>
    )
};

export default Login;