import React, { useEffect, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setCookie } from '../../storage';

import toastService from '../../toast';
import { toggleNavigation, useCourseSignUpMutation, useCourseSignUpDetailMutation, disablePopup, setGuideModal, toggleRun } from '../../store';
import CONSTANTS from '../../constants';
import { globalError } from '../../utils/__errorHandling';



function CollegeSignup(){
    const [ courseSignUp ] = useCourseSignUpMutation();
    const [courseDetails] = useCourseSignUpDetailMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const redirect = useSelector((state => state.redirect));
    let params = useParams();
    const [course, setCourse] = useState({ name: null, _id: null })

    const fetchDetails = async(_slug) => {
        try{
            let response = await courseDetails(_slug);
            let data = response.data;
            if(!data.data){
                toastService.error('Invalid URL');
                return;
            }
            setCourse(data.data);
        }catch(err){
            toastService.error(err.message);

        }
    }

    useEffect(()=>{
        dispatch(toggleNavigation(false));
        dispatch(disablePopup());
        dispatch(setGuideModal(false));
        dispatch(toggleRun(false));
        const { id } = params;
        fetchDetails(id);
    }, [dispatch]);

    const successNavigateHandel = async(_data) => {
        await setCookie(CONSTANTS.authCookie, true, {
            path: '/',
            expires: CONSTANTS.cookieExpire
        })
        navigate('/onboard/ds');
    }

    const responseGoogle = async(googleObj) => {
        try{
            let token = googleObj['credential'];

            if(!token){
                toastService.error();
                return;
            };
            
            let response = await courseSignUp({
                _id: course._id, 
                token
            });

            if(response['error']){
                globalError(response['error']);
                return;
            };
            if(response['data']){
                successNavigateHandel();
                return;
            };
        }catch(err){
            toastService.error();
        }
    };

    const googleLoginErr = () => {
        toastService.error();
    };

    return(
        <section className='login' >
            <div className='login__container' >
                <h1>{course.name}</h1>
                <div>
                    <GoogleLogin
                        onSuccess={responseGoogle}
                        onError={googleLoginErr}
                        />
                </div>
            </div>
        </section>
    )
};

export default CollegeSignup;