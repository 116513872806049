import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {combineReducers} from "redux"; 
import {  
    persistStore,
    persistReducer,
  } from 'redux-persist';
import { setupListeners } from '@reduxjs/toolkit/query';

import { navigationReducer, navigationSlice } from './slices/navigationSlice';
import { api } from './apis/api';
import { loadingReducer } from './slices/loadingSlice';
import { redirectSlice } from './slices/redirectSlice';
import { VideoPlayerSlice } from './slices/videoPlayerSlice';
import { notificationSlice } from './slices/notificationSlice';
import { popupSlice } from './slices/popupSlice';
import { userSlice } from './slices/userSlice';
import { homeSlice } from './slices/homeSlice';
import { guideSlice } from './slices/guideSlice';
import { timeStampSlice } from './slices/timeStamp';
import { notesSlice } from './slices/notesSlice';
import { courseSlice } from './slices/courseSlice';
import { courseVideoSlice } from './slices/courseVideoSlice';

const reducers = combineReducers({
    [navigationSlice.name]: navigationReducer,
    [redirectSlice.name]: redirectSlice.reducer,
    [api.reducerPath]: api.reducer,
    [VideoPlayerSlice.name]: VideoPlayerSlice.reducer,
    loading: loadingReducer,
    [notificationSlice.name]: notificationSlice.reducer,
    [popupSlice.name]: popupSlice.reducer,
    [userSlice.name]: userSlice.reducer,
    [homeSlice.name]: homeSlice.reducer,
    [guideSlice.name]: guideSlice.reducer,
    [timeStampSlice.name]: timeStampSlice.reducer,
    [notesSlice.name]: notesSlice.reducer,
    [courseSlice.name]: courseSlice.reducer,
    [courseVideoSlice.name]: courseVideoSlice.reducer
});
   
const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['loading', 'guide', 'timestamp', 'api', 'steps.0.content' ]
};
   
const persistedReducer = persistReducer(persistConfig, reducers);
   
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //   warnAfter: 128
      // },
      serializableCheck: false
    }).concat(api.middleware),
});

const persistor = persistStore(store);
setupListeners(store.dispatch);

export { store, persistor };
export { toggleSidebar, toggleNavigation, setActivebar, toggleNavbar } from './slices/navigationSlice';
export { toggleLoading } from './slices/loadingSlice';
export { setRedirect, resetRedirect } from './slices/redirectSlice';
export { setVideoDetials, setStatus } from './slices/videoPlayerSlice';
export { setNotificationToken } from './slices/notificationSlice';
export { togglePopup, popupFeedback, disablePopup, popupFreeProject, popupfreeCourseFeedback } from './slices/popupSlice';
export { setUser } from './slices/userSlice';
export { setHomeData } from './slices/homeSlice';
export { setGuideModal, setSteps, toggleRun } from './slices/guideSlice';
export { setTimestamp } from './slices/timeStamp';
export { setNotes } from './slices/notesSlice';
export { setCourseData } from './slices/courseSlice';
export { setCourseVideo } from './slices/courseVideoSlice'
export { 
  useGoogleLoginMutation, 
  useEmailLoginMutation, 
  useHomeDataMutation,
  useOnBoardMutation,
  useCoursesQuery,
  usePaymentQuery,
  useRazorpayOrderMutation,
  useRazorpayVerifyMutation,
  useRecordingsMutation,
  useStudyMaterialQuery,
  useCustomClickEventMutation,
  useMarkAttendanceMutation,
  useNotificationTokenMutation,
  useGetNotificationsQuery,
  useReadNotificationsMutation,
  useCourseSignUpDetailMutation,
  useCourseSignUpMutation,
  useExamSignUpMutation,
  useUpdateNumberMutation,
  useGetFeedbackMutation,
  useSubmitFeedbackMutation,
  useKycStatusMutation,
  useAssignmentListMutation,
  useCheckOnBoardMutation,
  useLogOutMutation,
  useMagicLinkMutation,
  useMagicLinkValidateMutation,
  useSaveNotesMutation,
  useGetNotesMutation,
  useQuizDetailsMutation,
  useQuizTopicListMutation,
  useQuizAnswerCheckMutation,
  useQuizResultCheckMutation,
  useResumeListQuery,
  useResumeUploadMutation,
  useMockInterviewScheduleMutation,
  useMockInterviewListMutation,
  usePersonalizedJobsMutation,
  useJobportalJobsMutation,
  usePlacementProcessAccessMutation,
  useInterviewPrepTopicsMutation,
  useInterviewPrepTopicsCasesMutation,
  useInterviewPrepDetailMutation,
  useCoursePlaylistMutation
} from './apis/api';


/*
   FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
 */