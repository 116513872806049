import React, { useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { 
    useMagicLinkValidateMutation, 
    toggleNavigation, 
    disablePopup,
    setUser
} from '../../store';
import toastService from "../../toast";
import CONSTANTS from "../../constants";
import { setCookie } from '../../storage';

function MagicLink(){
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [magicLinkValidate] = useMagicLinkValidateMutation();

    const successNavigateHandel = async(_data) => {
        let { onboard, user } = _data;
        dispatch(setUser(user));
        await setCookie(CONSTANTS.authCookie, true, {
            path: '/',
            expires: CONSTANTS.cookieExpire
        })
        if(onboard){
            navigate('/onboard');
        }
        else{
            navigate('/');
        }
    }

    const validateUser = async (_id) => {
        try{    
            let response = await magicLinkValidate(_id);
            if(response['error']){
                const error = response['error'];
                console.log({ error })
                if(error.status === 498){
                    toastService.error('Login link expired');
                    navigate('/login');
                    return;
                }

                if(error.status === 404){
                    toastService.error('Invalid link');
                    navigate('/login');
                    return;
                }
            }

            if(response['data']){
                const data = response['data'];
                if(data.status === 'success'){
                    successNavigateHandel(data);
                }
            }
        }catch(err){
            toastService.error();
            navigate('/login');
        }
    }

    useEffect(()=>{
        validateUser(params['id']);
    }, [params]);

    useEffect(()=>{
        dispatch(toggleNavigation(false));
        dispatch(disablePopup());
    }, [dispatch]);

    return(
        <div></div>
    )
};

export default MagicLink;