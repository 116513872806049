import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: { name: '', profilePic: 'https://learn.businesstoys.in/assets/icons/user.png' },
    reducers: {
        setUser(state, action){
            return { name: action.payload['name'], profilePic: action.payload['profilePic'] }
        }
    }
});

export const userReducer = userSlice.reducer;
export const { setUser } = userSlice.actions;
export { userSlice };