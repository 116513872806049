import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import toastService from '../../toast';
import { useUpdateNumberMutation, togglePopup } from '../../store';

function Popup(){
    const dispatch = useDispatch();
    const { show } = useSelector(state =>state.popup);
    const [phone, setPhone] = useState('');

    const [ updateNumber ] = useUpdateNumberMutation();

    const formSubmit = async(e) => {
        try{
            e.preventDefault();

            if(phone.length !== 10){
                toastService.error('Enter valid number');
                return;
            }

            let response = await updateNumber(phone);
            if(response.data.status === 'success'){
                dispatch(togglePopup(false));
            }else{  
                toastService.error();
            }

        }catch(error){
            toastService.error();
        }
    }

    return(
        <section className='popup' style={{ display: (show) ? 'block' : 'none' }}  >
            <form onSubmit={formSubmit} className="popup__container">
                <label>Please enter your 10 digit Phone number*</label>
                <input type="tel" name="number" placeholder='8088426734' value={phone} 
                    onChange={(e)=>setPhone(e.target.value)}  />
                <button className='btn' >Submit</button>
            </form>
        </section>
    )
};

export default Popup;