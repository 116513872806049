/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import CourseQuiz from './Quiz'
import CONSTANTS from '../../constants'
import { useDispatch, useSelector } from 'react-redux';
import toastService from '../../toast';
import { setTimestamp } from '../../store';
import { courseVideoWatchtime } from '../../utils/__axios';

const Player = ({
    courseId,
    setPlayNext
}) => {
    const video = useRef(null);
    const [supported] = useState(Hls.isSupported());
    const hls = useRef(null);
    const player = useRef(null);
    const dispatch = useDispatch()

    const plyrOptions = {
      ratio: '16:9',
      autopause: true,
      controls: [
        'rewind',
        'play',
        'fast-forward',
        'play-large',
        'current-time',
        'progress',
        'duration',
        'mute',
        'volume',
        'settings',
        'fullscreen'
      ],
      speed: { selected: 1, options: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] } 
    }
    const courseVideo = useSelector(state => state.courseVideo)
    const hlsSource = CONSTANTS.BaseURL + `/recording/hls/${courseId}/${courseVideo._id}/master.m3u8`
    const poster = courseVideo?.banner

    const width = window.innerWidth
    if (width < 770) {
      plyrOptions.controls = [
        'play',
        'play-large',
        'progress',
        'duration',
        'volume',
        'settings',
        'fullscreen'
      ]
    }

    useEffect(() => {
        dispatch(setTimestamp(0))
        
        if (courseVideo.type !== 'VIDEO') return
        video.current = document.getElementById(courseId);
        if (poster && video.current) {
           video.current.poster = poster;
        } else if(video.current){
          video.current.poster = null
        }

        if (!supported || !hlsSource) {
            video.current.src = hlsSource;
            player.current = new Plyr(video.current, plyrOptions);
            return () => {
                player.current?.destroy(() => toastService.error('Video player not supported in your browser'));
            };
        }

        hls.current = new Hls({ xhrSetup: (xhr) =>  xhr.withCredentials = true, autoStartLoad: false });
        hls.current.loadSource(hlsSource);
        hls.current.attachMedia(video.current);
        hls.current.on(Hls.Events.MANIFEST_PARSED, () => {
            console.log('Manifest parsed');

            const levels = hls.current.levels;
            const quality = {
                default: levels[levels.length - 1].height,
                options: levels.map(level => level.height),
                forced: true,
                onChange: (newQuality) => {
                    console.log('changes', newQuality);
                    levels.forEach((level, levelIndex) => {
                        if (level.height === newQuality) {
                            hls.current.currentLevel = levelIndex;
                        }
                    });
                }
            };

            player.current = new Plyr(video.current, {
                ...plyrOptions,
                quality
            });

            player?.current?.on('play', () => {
              console.log('playing')
              hls?.current?.startLoad()
            })

            player.current.on('timeupdate', (event) => {
              const currentTime = event.detail.plyr.currentTime
              dispatch(setTimestamp(currentTime))
              const roundTime = Math.round(currentTime)
              if (roundTime > 1 && roundTime % 10 === 0) {
                courseVideoWatchtime(courseId, courseVideo._id, currentTime)
              }
            });

            player.current.on('pause', () => {
                console.log('paused');
                hls.current.stopLoad();
            });

            player.current.on('ended', () => {
              console.log('end')
              setPlayNext(true)
            })

        });

        return () => {
            console.log('destroyed');
            hls.current?.destroy();
            player.current?.destroy();
        };
    }, [courseId, courseVideo]);

    if (!courseVideo?._id) return null

    if (courseVideo.type === 'VIDEO') {
      return (
        <>
          <h1>{courseVideo?.title}</h1>
          <div>
              <video style={{ width: '100%' }} id={courseId} />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>Quiz {courseVideo?.title}</h1>
          <CourseQuiz data={courseVideo} setPlayNext={setPlayNext} />
        </>
      )
    }
};

export default Player;