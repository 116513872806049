import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics, logEvent, setUserProperties } from "firebase/analytics";

import toastService from '../toast';

import CONSTANTS from '../constants';

const firebaseConfig = {
    apiKey: "AIzaSyAY0Uk3M48y9vim6UgGLl20pMlEiAeN_w0",
    authDomain: "learn-businesstoys-in-798b7.firebaseapp.com",
    projectId: "learn-businesstoys-in-798b7",
    storageBucket: "learn-businesstoys-in-798b7.appspot.com",
    messagingSenderId: "1087004260461",
    appId: "1:1087004260461:web:d8fcea9b4cf7d8a531e764",
    measurementId: "G-DCJGDC36YQ"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const messaging = getMessaging();

const requestForToken = async() => 
  new Promise((resolve,reject) => {
    getToken(messaging, { vapidKey: CONSTANTS.VAPID_KEY })
      .then((currentToken) => {
        if (currentToken) {
            // Perform any other neccessary action with the token
            resolve(currentToken);
        } else {
            // Show permission request UI
            reject('No Browser Support');
        }
      })
      .catch((err) => {
        // console.log('An error occurred while retrieving token. ', err);
        reject(err);
      });
});

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
      toastService.info(payload);
    });
});

const analyticsEvent = ({ _event, _type, _custom }) => {
  if(!`${window.location.href}`.includes('businesstoys.in')) return;
  logEvent(analytics, _event, {
      type: _type,
      ..._custom
  });
};

const analyticsUserProperties = ({ _prop }) => {
  if(!`${window.location.href}`.includes('businesstoys.in')) return;
  setUserProperties(analytics, { ..._prop } );
}

export { 
  requestForToken, 
  onMessageListener, 
  analyticsEvent, 
  analyticsUserProperties 
};