import React,{ useState,useReducer, useEffect } from 'react';
import useDynamicRefs from '../../utils/__dynamicRefs';
import { useNavigate } from 'react-router-dom';

import { useKycStatusMutation } from '../../store';
import { kycFileUpload } from '../../utils/__axios';
import toastService from '../../toast';

const initialArgs = [
    
    {
        title:'Basic Details',
        inputs:[
            {
                label: 'First Name',
                type: 'text',
                placeholder: 'Enter Your First Name',
                name: 'firstName',
                tag: "input",
                value: ""
            },
            {
                label: 'Middle Name',
                type: 'text',
                placeholder: 'Enter Your Middle Name',
                name: 'middleName',
                tag: "input",
                value: ""
            },
            {
                label:'Last Name',
                type:'text',
                placeholder:'Enter Your Last Name',
                name:'lastName',
                tag:"input",
                value:""
            },
            {
                label:'Gender',
                placeholder:'Select Gender',
                name:'gender',
                tag:"select",
                value:'',
                options:[
                    {
                        optionValue: "",
                        optionName: 'Select Gender'
                    },
                    {
                        optionValue: "male",
                        optionName: 'Male'
                    },
                    {
                        optionValue: "female",
                        optionName: 'Female'
                    }
                ]
            },
            {
                label:'Marital Status',
                placeholder:'Select Marital Status',
                name:'maritalStatus',
                tag:"select",
                value:'',
                options:[
                    {
                        optionValue: "",
                        optionName: 'Select Marital Status',
                    },
                    {
                        optionValue: "single",
                        optionName: 'single',

                    },
                    {
                        optionValue: "married",
                        optionName: 'married',
                    },
                    {
                        optionValue: "widowed",
                        optionName: 'widowed',
                    },
                    {
                        optionValue: "divorced",
                        optionName: 'divorced',
                    }
                ]
            },
            {
                label:'Date of Birth',
                type:'date',
                placeholder:'Enter Your Date of Birth',
                name:'dob',
                tag:"input",
                value:""
            }
        ]
    },

    {
        title:'Basic Details',
        inputs:[
            {
                label:'Adhar Number',
                type:'text',
                placeholder: '4991 1866 5246', 
                name: 'aadharNumber',
                tag: "input",
                value: "",
                min: 0,
            },
            {
                label:'Pan Number',
                type:'text',
                placeholder:'ABCTY1234D',
                name:'panNumber',
                tag: "input",
                value: "",
            },
            {
                label: 'Mobile Number',
                type: 'text',
                placeholder: '9876543210',
                name:'mobileNumber',
                tag: "input",
                value: "",
                min:0,
            },
            {
                label:'Email',
                type:'email',
                placeholder:'Email',
                name:'email',
                tag: "input",
                value: "",
            },
            {
                label:'I am a',
                placeholder:'Select',
                name:'experience',
                tag:"select",
                value:'',
                options:[
                    {
                        optionValue: "",
                        optionName: 'Select'
                    },
                    {
                        optionValue: "Working Profesional",
                        optionName: 'Working Profesional'
                    },
                    {
                        optionValue: "Student",
                        optionName: 'Student'
                    }
                ]
            },
            {
                label:'Looking For A Loan For',
                placeholder:'Select',
                name:'loanFor',
                tag:"select",
                value:'',
                options:[
                    {       
                        optionValue: "",
                        optionName: 'Select'
                    },
                    {
                        optionValue: "Myself",
                        optionName: 'Myself'
                    },
                    {
                        optionValue: "Child",
                        optionName: 'Child'
                    },
                    {
                        optionValue: "Sposue",
                        optionName: 'Sposue'
                    }
                ]
            },
            {
                label: 'Profession of Applicant',
                placeholder: 'Select',
                name: "professionOfApplicant",
                tag: "select",
                value: '',
                options: [
                    {
                        optionValue: "",
                        optionName: 'Select'
                    },
                    {
                        optionValue: "Self Employed",
                        optionName: 'Self Employed'
                    },
                    {
                        optionValue: "Salaried",
                        optionName: "Salaried"
                    }
                ]
            },
        ]
    },
    
    {
        title:'Residential Details',
        inputs:[
            {
                label:'Current Address',
                type:'text',
                placeholder:'Enter Current Address',
                name:'currentAddress',
                tag: "input",
                value: ""
            },
            {
                label:'Current Address PinCode',
                type:'text',
                placeholder:'560 090',
                name:'currentAddressPinCode',
                tag: "input",
                value: ""
            },
            {
                label:'Current Address State',
                type:'text',
                placeholder:'Karnataka',
                name:'currentAddressState',
                tag: "input",
                value: ""
            },
            {
                label:'Current Address City',
                type:'text',
                placeholder:'Bengaluru',
                name:'currentAddressCity',
                tag: "input",
                value: ""
            },
            {
                label:'Current Residence Type',
                placeholder:'Select Residence Type',
                name:'currentResidenceType',
                tag:"select",
                value: '',
                options:[
                    {
                        optionValue: "",
                        optionName: 'Select Residence Type',
                    },
                    {
                        optionValue: "rented",
                        optionName: 'rented',

                    },
                    {
                        optionValue: "owned",
                        optionName: 'owned',
                    }
                ]
            },
            {
                label: 'Current Residence Duration',
                placeholder: 'Current Residence Duration',
                name: 'residenceDuration',
                tag: "select",
                value: '',
                condition: "state[2].inputs[4].value === 'owned'",
                options: [
                    {
                        optionValue: "",
                        optionName: 'Select Residence Duration',
                    },
                    {
                        optionValue: "0 - 1 Year",
                        optionName: '0 - 1 Year',

                    },
                    {
                        optionValue: "1 - 2 Years",
                        optionName: '1 - 2 Years',
                    },
                    {
                        optionValue: "More than 2 Years",
                        optionName: 'More than 2 Years',
                    }
                ]
            },
            {
                label: 'Current Residence Owned By',
                placeholder: 'Current Residence Owned By',
                name: 'residenceOwnedBy',
                tag: "select",
                value: '',
                condition: "state[2].inputs[4].value === 'owned'",
                options: [
                    {
                        optionValue: "",
                        optionName: 'Select Owned By',
                    },
                    {
                        optionValue: "Parents/Sibling",
                        optionName: 'Parents/Sibling',

                    },
                    {
                        optionValue: "Self",
                        optionName: 'Self',
                    }
                ]
            },
            {
                label: 'Is Your Current Address & Permanent Address Same?',
                placeholder: '',
                name: 'currentResidenceSameAsOld',
                tag: "select",
                value: '',
                options: [
                    {
                        optionValue: "",
                        optionName: 'Select',
                    },
                    {
                        optionValue: "Yes",
                        optionName: 'Yes',

                    },
                    {
                        optionValue: "No",
                        optionName: 'No',
                    }
                ]
            },
            {
                label: 'Permanent Address',
                placeholder: '#123, 4th A main',
                name: 'permanentAddress',
                condition: "state[2].inputs[7].value === 'No'",
                tag: "input",
                type: 'text',
                value: ''
            },
            {
                label: 'Permanent Address Pin Code',
                placeholder: '560 091',
                name: 'permanentAddressPinCode',
                condition: "state[2].inputs[7].value === 'No'",
                tag: "input",
                type: 'text',
                value: ''
            },
            {
                label: 'Permanent Address State',
                placeholder: 'Karnataka',
                name: 'permanentAddressState',
                condition: "state[2].inputs[7].value === 'No'",
                tag: "input",
                type: 'text',
                value: ''
            },
            {
                label: 'Permanent Address City',
                placeholder: 'Bengaluru',
                name: 'permanentAddressCity',
                condition: "state[2].inputs[7].value === 'No'",
                tag: "input",
                type: 'text',
                value: ''
            },
        ]
    },
    
    {
        title:'Work Details',
        inputs:[
            {
                label:'Company Name',
                type:'text',
                placeholder:'Company Name',
                name:'companyName',
                tag: "input",
                value: ""
            },
            {
                label:'Employer Type',
                placeholder:'Select Employer Type',
                name:'employerType',
                tag:"select",
                value:'',
                options:[
                    {
                        optionValue: "",
                        optionName: 'Select Employer Type',
                    },
                    {
                        optionValue: "Private Sector",
                        optionName: 'Private Sector',

                    },
                    {
                        optionValue: "Government Sector",
                        optionName: 'Government Sector',
                    }
                ]
            },
            {
                label:'Annual Income in Rs'  ,
                type:'number',
                placeholder: 0,
                name:'annualIncome',
                tag: "input",
                value: "",
                min:0
            },
            {
                label:'Employer Duration',
                placeholder:'Select Employer Duration',
                name:'employmentDuration',
                tag:"select",
                value:'',
                options:[
                    {
                        optionValue: "",
                        optionName: 'Select Employer Type',
                    },
                    {
                        optionValue: "0 to 1 Years",
                        optionName: '0 to 1 Years',

                    },
                    {
                        optionValue: "1 to 2 Years",
                        optionName: '1 to 2 Years',
                    },
                    {
                        optionValue: "More than 2 Years",
                        optionName: 'More than 2 Years',
                    }
                ]
            },
            {
                label:'Work Email Address',
                type:'email',
                placeholder:'Work Email',
                name:'workEmail',
                tag: "input",
                value: ""
            },
            
        ]
    },

    {
        title:'Work Address',
        inputs:[
            {
                label:'Work Address',
                type:'text',
                placeholder:'Work Address',
                name:'workAddress',
                tag:"input",
                value:""
            },
            {
                label:'Pin Code',
                type:'number',
                placeholder:'City Pincode',
                name:'workAddressPinCode',
                tag: "input",
                value: ""
            },
            {
                label:'State',
                type:'text',
                placeholder:'State',
                name:'workAddressState',
                tag: "input",
                value: ""
            },
            {
                label:'City',
                type:'text',
                placeholder:'City',
                name:'workAddressCity',
                tag: 'input',
                value: ""
            },  
        ]
    },
    
    {
        title: 'Upload Documents',
        note: '** Please Upload jpg, png, or pdf formats only **',
        inputs:[
            {
                label: 'Aadhar Card Front',
                type: 'file',
                name: 'aadharFront',
                tag: 'input',
                placeholder: 'Choose File',
                value: null,
                ref: null
            },
            {
                label: 'Aadhar Card Back',
                type: 'file',
                name: 'aadharBack',
                tag: 'input',
                placeholder: 'Choose File',
                value: null
            },
            {
                label: 'Pan Card',
                type: 'file',
                name: 'pan',
                tag: 'input',
                placeholder: 'Choose File',
                value: null
            },
            {
                label: 'Bank Statement (3 Months )',
                type: 'file',
                name: 'bankStatement',
                tag: 'input',
                placeholder: 'Choose File',
                value: null
            },
            {
                label: 'Salary Slip (3 Months)',
                type: 'file',
                name: 'salarySlip',
                tag: 'input',
                placeholder: 'Choose File',
                value: null
            },
            {
                label: 'Photo',
                type: 'file',
                name: 'photo',
                tag: 'input',
                placeholder: 'Choose File',
                value: null
            },
            {
                label: 'Address Proof (DL/Voter Id) Front',
                type: 'file',
                name: 'addressProofFront',
                tag: 'input',
                placeholder: 'Choose File',
                value: null
            },
            {
                label: 'Address Proof (DL/Voter Id)Back',
                type: 'file',
                name: 'addressProofBack',
                tag: 'input',
                placeholder: 'Choose File',
                value: null
            }
        ]
    }
];

function LoanPayments (){

    const [index, setIndex] = useState(0);
    const [fetchKyc] = useKycStatusMutation();
    const navigate = useNavigate();

    const handleFile = (_index) => {
        let ele = getRef(_index);
        ele.current.click();
    }

    const submitForm = async(e) => {
        try{
            window.scrollTo(0,0);
            e.preventDefault();            
            let data = {
                firstName: state[0].inputs[0].value,
                middleName:state[0].inputs[1].value,
                lastName:  state[0].inputs[2].value,
                gender:    state[0].inputs[3].value,
                maritalStatus: state[0].inputs[4].value,
                dob:       state[0].inputs[5].value,

                aadharNumber:          state[1].inputs[0].value || null,
                panNumber:             state[1].inputs[1].value || null,
                mobileNumber:          state[1].inputs[2].value || null,
                email:                 state[1].inputs[3].value || null,
                experience:            state[1].inputs[4].value || null,
                loanFor:               state[1].inputs[5].value || null,
                professionOfApplicant: state[1].inputs[6].value || null,

                currentAddress:            state[2].inputs[0].value || null,
                currentAddressPinCode:     state[2].inputs[1].value || null,
                currentAddressState:       state[2].inputs[2].value || null,
                currentAddressCity:       state[2].inputs[3].value || null,
                currentResidenceType:      state[2].inputs[4].value || null,
                residenceDuration:         state[2].inputs[5].value || null,
                residenceOwnedBy:          state[2].inputs[6].value || null,
                currentResidenceSameAsOld: state[2].inputs[7].value || null,
                permanentAddress:          state[2].inputs[8].value || null,
                permanentAddressPinCode:   state[2].inputs[9].value || null,
                permanentAddressState:     state[2].inputs[10].value || null,
                permanentAddressCity:      state[2].inputs[11].value || null,

                companyName:        state[3].inputs[0].value || null,
                employerType:       state[3].inputs[1].value || null,
                annualIncome:       state[3].inputs[2].value || null,
                employmentDuration: state[3].inputs[3].value || null,
                workEmail:          state[3].inputs[4].value || null,

                workAddress:        state[4].inputs[0].value || null,
                workAddressPinCode: state[4].inputs[1].value || null,
                workAddressState:   state[4].inputs[2].value || null,
                workAddressCity:    state[4].inputs[3].value || null
            }
            let response = await kycFileUpload(data);
            if(response.data.status === 'success'){
                toastService.success('Saved successfully!');
            };
            if(initialArgs.length - 1 <= index){
                toastService.success('Application Submitted!');
                navigate('/payment');
                return null;
            };
            setIndex(_index => _index + 1);
        }catch(err){
            console.log({err});
            toastService.error();
        }
    }

    const handlePrevious = (e)=> {
        e.preventDefault();
        if (index !== 0 ){
            setIndex(index-1);
        }else{
            setIndex(index);
        }
    }

    const reducer = (state, action) => {
        let data = [...state];
        switch (action.type) {
            case 'INPUT':
                data[index].inputs[action.index].value = action.data;
                return data;
            case 'INIT':
                data[action.mainIndex].inputs[action.index].value = action.data;
                return data;
            default:
                return data;
        }
    }
    
    const [state, dispatch] = useReducer(reducer, initialArgs);
    const [_data, _setData] = useState({});

    const loadData = async() => {
        try{
            let response = await fetchKyc();
            let data = response.data;
            if(data.status === 'success'){
                let doc = { ...data.documents };
                delete doc.aadharFront;
                delete doc.aadharFrontStatus;
                delete doc.aadharBack;
                delete doc.aadharBackStatus;
                delete doc.pan;
                delete doc.panStatus;
                delete doc.bankStatement;
                delete doc.bankStatementStatus;
                delete doc.salarySlip;
                delete doc.salarySlipStatus;
                delete doc.photo;
                delete doc.photoStatus;
                delete doc.addressProofFront;
                delete doc.addressProofFrontStatus;
                delete doc.addressProofBack;
                delete doc.addressProofBackStatus;
                await _setData(doc);

                for(let i=0; i<state.length; i++){
                    let _main = state[i];
                    for(let j=0; j<_main.inputs.length; j++){
                        let item =  _main.inputs[j];
                        if(doc[item.name]){
                            if(item.name === 'dob') {
                                let formatDate = new Date(doc[item.name]).toISOString().substring(0, 10);
                                dispatch({ type: 'INIT', mainIndex: i, index: j, data: formatDate });
                            }
                            else dispatch({ type: 'INIT', mainIndex: i, index: j, data: doc[item.name] })
                        }
                    };
                }

            }
        }catch(err){
            console.log({ err });
            // toastService.error();
        }
    };

    useEffect(()=>{
        window.scrollTo(0,0);
        loadData();
    }, [])

    const [getRef, setRef] =  useDynamicRefs();

    const handleChangeFile = async(_event, _index, _key) => {
        try{
            await dispatch({
                type:'INPUT',
                data: _event.target.files[0],
                index: _index
            });
            
            const formData = new FormData();
            formData.append(
                `${_key}`,
                state[index].inputs[_index].value,
                state[index].inputs[_index].name,
            );
    
            let response = await kycFileUpload(formData);
            if(response.data.status === 'success'){
                   toastService.success('Uploaded Successfully');
            }else{
                toastService.error();
            }
        }catch(err){
            toastService.error();
        }
    };

    const renderOptions = (_arr) => {
        return _arr.map((e,i)=>(
            (i===0) ? <option key={i} disabled value={e.optionValue}>{e.optionName}</option> :
                <option key={i}  value={e.optionValue}>{e.optionName}</option>
        ))
    };

    const renderInputs = (_arr) => {
        return _arr.map((e,i)=>{
            if (e.tag === 'input') {
                let show = true;
                if(e.condition) show = eval(e.condition);
                if(!show) return null;

                if(e.type === 'number'){
                    return (
                        <div key={i} className='loan_container_inputs'>
                            <label htmlFor={e.name}>{e.label}</label>
                            <input type={e.type} name={e.name} placeholder={e.placeholder} 
                                value={e.value} required min={e.min}
                                onChange={(e)=>dispatch({ type: 'INPUT', index: i, data: e.target.value })}  
                            />
                        </div>
                    );
                }

                else if(e.type === 'file'){
                    return(
                        <div key={i} className='loan_container_inputs'>
                            <div className='loan_container_inputs_file'>
                                <div><label htmlFor={e.name}>{e.label}</label></div>
                                <button type='button' className='btn loan_container_inputs_btn' 
                                    onClick={()=>handleFile('ref' + i)}>
                                        {e.placeholder}
                                </button>
                                <input type={e.type} name={e.name} ref={setRef('ref' + i)} 
                                onChange={(event)=>handleChangeFile(event, i, e.name)}/>
                            </div>
                            <p>{e.value ? e.value.name : null}</p>

                        </div>
                    );
                }

                return(
                    <div key={i} className='loan_container_inputs'>
                        <label htmlFor={e.name}>{e.label}</label>
                        <input type={e.type} name={e.name} placeholder={e.placeholder} 
                            value={e.value} required
                            onChange={(e)=>dispatch({ type: 'INPUT', index: i, data: e.target.value })}  
                        />
                    </div>
                );            
            }
             
            else {
                let show = true;
                if(e.condition) show = eval(e.condition);
                if(show) return(
                        <div key={i} className='loan_container_inputs'>
                            <label htmlFor={e.name}>{e.label}</label>
                            <select name={e.name} required value={e.value} onChange={(e)=>dispatch({ type:'INPUT', index: i, data: e.target.value })}>
                                {renderOptions(e.options)} 
                            </select>
                        </div>
                );
                else return null;
                
            }
        })
    };

    return (
        <section className='loan'>
            <div className='loan_page'>
                <div>
                    <div className={ (index >= 0) ? 'loan_page-bullet loan_page-bullet-active' : 'loan_page-bullet'}></div>
                    <div className={ (index >= 0) ? 'loan_page-line loan_page-line-active' : 'loan_page-line'}></div>
                </div>
                <div>
                    <div className={ (index >= 1) ? 'loan_page-bullet loan_page-bullet-active' : 'loan_page-bullet'}></div>
                    <div className={ (index >= 1) ? 'loan_page-line loan_page-line-active' : 'loan_page-line'}></div>
                </div>
                <div>
                    <div className={ (index >= 2) ? 'loan_page-bullet loan_page-bullet-active' : 'loan_page-bullet'}></div>
                    <div className={ (index >= 2) ? 'loan_page-line loan_page-line-active' : 'loan_page-line'}></div>
                </div>
                <div>
                    <div className={ (index >= 3) ? 'loan_page-bullet loan_page-bullet-active' : 'loan_page-bullet'}></div>
                    <div className={ (index >= 3) ? 'loan_page-line loan_page-line-active' : 'loan_page-line'}></div>
                </div>
                <div>
                    <div className={ (index >= 4) ? 'loan_page-bullet loan_page-bullet-active' : 'loan_page-bullet'}></div>
                    <div className={ (index >= 4) ? 'loan_page-line loan_page-line-active' : 'loan_page-line'}></div>
                </div>
                <div>
                    <div className={ (index >= 5) ? 'loan_page-bullet loan_page-bullet-active' : 'loan_page-bullet'}></div>
                </div> 
            </div>
            <form  onSubmit={submitForm}>
                <h3>{ state[index].title }</h3>
                { (index === 5) &&  <h6>{state[index].note}</h6> }
                <div className='loan_container'>
                    {renderInputs(state[index].inputs)}

                    {(index === initialArgs.length - 1) && (<p><input type="checkbox" name="checkbox" required /> &nbsp;
                        I Hereby Apply For 0% Interest Easy Emi Assistance For The Programme Offered By Business Toys Pvt Ltd. 
                        I Acknowledge And Understand That Business Toys Is Solely Into Offering Educational, 
                        Skill Development Courses And Career Scaling Offerings And Is Not An NBFC. 
                        Business Toys Is Offering The Financial Assistance / Loan Product To Facilitate Easy 
                        Emi Through A Financial Institution /NBFC. I Understand And Acknowledge That The Said 
                        NBFC Follows The RBI Guidelines To Process The Loan Application And The Details Are Separately 
                        Shared By The NBFC. Business Toys Has No Role Nor Obligations In Deciding The Acceptance Or Rejecting 
                        My Loan Application Or Loan Structure. I Understand That Business Toys Has 
                        A Quality Policy To Deliver High Impact Skills Training Programmes Along With One On One Career 
                        Success Mentoring By Experts And So Admits Only Few Students In Each Cohort And 
                        My Decision To Join The Program May Lead Business Toys To Forgo Admission Of 
                        Other Candidate(S) To Ensure Compact Size Of The Batch. I Fully Understand And Acknowledge 
                        That The Emi Is Paid Directly To NBFC Till The Tenure And Business Toys Is Not Under Any 
                        Obligation To Refund The Course Fee To Me / NBFC, If I Choose To Withdraw From The Program 
                        In Between For Any Reasons. I Authorise Business Toys To Refer My Application To 
                        The NBFC For Easy Emi. I Have Understood The Terms And Conditions Of Business Toys 
                        And That Of The NBFC.
                    </p>)}
                </div>
                <div className='loan_btns'>
                    { (index > 0) && <button className='btn btn-transp' type='button'  onClick={handlePrevious}>Previous</button>}
                    { (index === state.length-1) ? <button className='btn' type='submit'>Submit</button> : <button className='btn' type='submit'>Save & Continue</button> }
                </div>
            </form>
        </section>
    );
}

export default LoanPayments;