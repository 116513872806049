import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
    name: 'popup',
    initialState: { 
        show: false, 
        feedback: false ,
        freeCourse: false,
        freeCourseFeedback: false
    },
    reducers: {
        togglePopup(state, action){
            if(action.payload !== undefined) {
                state.show = action.payload;
            }else{
                state.show = !state.show;
            };
            return state;
        },
        popupFeedback(state, action){
            if(action.payload !== undefined) {
                state.feedback = action.payload;
            }else{
                state.feedback = !state.feedback;
            };
            return state;
        },
        disablePopup(state){
            return { show: false, feedback: false, freeCourse: false }
        },
        popupFreeProject(state, action){
            if(action.payload !== undefined) {
                state.freeCourse = action.payload;
            }else{
                state.freeCourse = !state.freeCourse;
            };
            return state;
        },
        popupfreeCourseFeedback(state, action){
            if(action.payload !== undefined) {
                state.freeCourseFeedback = action.payload;
            }else{
                state.freeCourseFeedback = !state.freeCourse;
            };
            return state;
        }
    }
});

export const { 
    togglePopup, 
    popupFeedback, 
    disablePopup, 
    popupFreeProject,
    popupfreeCourseFeedback
} = popupSlice.actions;
export { popupSlice }; 