import Cookies from 'universal-cookie';

const cookies = new Cookies();

const setCookie = (_key, _value, _options = { path: '/'}) => {
    return cookies.set(_key, _value, _options);
};

const getCookie = (_key) => {
    return cookies.get(_key);
};

const removeCookie = (_key) => {
    return cookies.remove(_key);
}

export { setCookie, getCookie, removeCookie };