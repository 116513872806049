import React, { useEffect, useState } from "react";

import { usePersonalizedJobsMutation, useJobportalJobsMutation } from '../../../store';
import toastService from '../../../toast';

function JobPortal(){
    const [personalizedJobs] = usePersonalizedJobsMutation();
    const [jobportalJobs] = useJobportalJobsMutation();

    const [personalJobs, setPersonalJobs] = useState([]);
    const [portalJobs, setPortalJobs] = useState([]);
    const [personalPaginate, setPersonalPaginate] = useState({ page: 1, max: 1 });
    const [portalPaginate, setPortalPaginate] = useState({ page: 1, max: 1 });
    const [selectIndex, setSelectIndex] = useState(0);
    const [viewJobs, setViewJobs] = useState('personalized');
    const width = window.innerWidth;

    const loadData = async(_page) => {
        try{
            window.scrollTo(0,0);
            let response = await personalizedJobs(_page);
            if(response && response.data['status'] === 'success'){
                setPersonalJobs(response.data.data);
                setPersonalPaginate({ page: _page, max: response.data.pages });
            }else{
                toastService.error();
            }
        }catch(err){
            toastService.error();
        }
    };

    const loadJobportal = async(_page) => {
        try{
            let response = await jobportalJobs(_page);
            if(response && response.data['status'] === 'success'){
                setPortalJobs(response.data.data);
                setPortalPaginate({ page: _page, max: response.data.pages });
            }else{
                toastService.error();
            }
        }catch(err){
            toastService.error();
        }
    };

    useEffect(()=>{
        window.scrollTo(0,0);
        loadData(1);
    }, [width]);

    useEffect(()=>{
        if(viewJobs === 'personalized') loadData(1);
        if(viewJobs === 'jobportal') loadJobportal(1);
    }, [viewJobs]);

    const onPrevPageClick = () => {
        if(personalPaginate.page <= 1) return;
        setPersonalPaginate((state)=>{
            loadData(state.page - 1);
            return { ...state, page: state.page - 1 }
        });
    };

    const onNextPageClick = () => {
        if(personalPaginate.page >= personalPaginate.max) return;
        setPersonalPaginate((state)=>{
            loadData(state.page + 1);
            return { ...state, page: state.page + 1 }
        })
    };

    const renderPersonalJobs = () => {
        return(
            <>
                <div className="placement__jobportal__container__list" >
                    <div className="placement__jobportal__container__list__container"  >
                        {
                            personalJobs.map((e,i)=>(
                                <div onClick={()=>{
                                    if(width > 770) window.scrollTo(0,0);
                                    setSelectIndex(i)
                                    }} key={e._id} className="placement__jobportal__container__list__card" >
                                    <div className="placement__jobportal__container__list__card__title" >
                                        <img src={ e.companyLogo || "/assets/placement/company.png"} alt="company" />
                                        <div>
                                            <h4>{e.title}</h4>
                                            <p>{e.company}</p>
                                        </div>
                                    </div>
                                    <div className="placement__jobportal__container__list__card__stats" >
                                        <p>
                                            <img src="/assets/placement/experience.png" alt="experience" />
                                            {e.experience}
                                        </p>
                                        <p>
                                            <img src="/assets/placement/salary.png" alt="salary" />
                                            {e.salary}
                                        </p>
                                        <p>
                                            <img src="/assets/placement/location.png" alt="location" />
                                            {e.location}
                                        </p>
                                    </div>
                                    <ul >
                                        <li>{e.description[1]}</li>
                                    </ul>
                                    <div className="placement__jobportal__container__list__card__skills" >
                                        {e.skills.map((_skill, _index)=>{
                                            if(_index>3) return null;
                                            return(
                                                <p key={_index} >{_skill}</p>
                                            )
                                        })}
                                    </div>
                                    <a href={e.applyUrl} target="__blank" className="placement__jobportal__container__list__card__btn" >
                                        Apply Now
                                    </a>
                                    <p className="placement__jobportal__container__list__card__date" >
                                        {new Date(e.postedDate).toLocaleDateString(undefined, {day: 'numeric', month: 'long', year: 'numeric'})}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                    <p className="placement__jobportal__container__list__pagination" >
                        <img
                            src="/assets/placement/left-arr.png" 
                            onClick={onPrevPageClick} 
                            alt="left-arr" />
                        {personalPaginate.page} - {personalPaginate.max}
                        <img 
                            src="/assets/placement/right-arr.png" 
                            onClick={onNextPageClick}
                            alt="right-arr" />
                    </p>
                </div>
                {
                    personalJobs[selectIndex] &&
                    (<div className="placement__jobportal__container__detail" >
                        <div className="placement__jobportal__container__detail__card" >
                            <div className="placement__jobportal__container__detail__card__title" >
                                <img src={personalJobs[selectIndex].companyLogo || "/assets/placement/company.png"} alt="company" />
                                <div>
                                    <h4>{personalJobs[selectIndex].title}</h4>
                                    <p>{personalJobs[selectIndex].company}</p>
                                </div>
                            </div>
                            <div className="placement__jobportal__container__detail__card__stats" >
                                <p>
                                    <img src="/assets/placement/experience.png" alt="experience" />
                                    {personalJobs[selectIndex].experience}
                                </p>
                                <p>
                                    <img src="/assets/placement/salary.png" alt="salary" />
                                    {personalJobs[selectIndex].salary}
                                </p>
                                <p>
                                    <img src="/assets/placement/location.png" alt="location" />
                                    {personalJobs[selectIndex].location}
                                </p>
                            </div>
                            <div className="placement__jobportal__container__detail__card__skills" >
                                {
                                    personalJobs[selectIndex].skills.map((_skill, _index)=>(
                                        <p key={_index} >{_skill}</p>
                                    ))
                                }
                            </div>
                            <div className="placement__jobportal__container__detail__card__date" >
                                <p>
                                    {new Date(personalJobs[selectIndex].postedDate).toLocaleDateString(undefined, {day: 'numeric', month: 'long', year: 'numeric'})}
                                </p>
                                <a href={personalJobs[selectIndex].applyUrl} target="__blank" >Apply Now</a>
                            </div>
                            <div className="placement__jobportal__container__detail__card__border" ></div>
                            <div className="placement__jobportal__container__detail__card__details" >
                                <h4>
                                    General responsibilities include:
                                </h4>
                               <ul>
                                    {
                                        personalJobs[selectIndex].description.map((e, i)=>(
                                            <li key={i} >{e}</li>
                                        ))
                                    }
                               </ul>
                            </div>
                            {/* <div className="placement__jobportal__container__detail__card__quali" >
                                <p>
                                    <b>Role:</b> Data Science & Analytics - Other
                                </p>
                                <p>
                                    <b>Industry Type:</b> Management Consulting
                                </p>
                                <p>
                                    <b>Department:</b> Data Science & Analytics
                                </p>
                                <p>
                                    <b>Employment Type:</b> Full Time, Permanent
                                </p>
                                <p>
                                    <b>Role Category:</b> Data Science & Analytics - Other Education
                                </p>
                            </div> */}
                        </div>
                    </div>)
                }
            </>
        )
    };

    const onPrevPortalPageClick = () => {
        if(portalPaginate.page <= 1) return;
        setPortalPaginate((state)=>{
            loadData(state.page - 1);
            return { ...state, page: state.page - 1 }
        });
    };

    const onNextPortalPageClick = () => {
        if(portalPaginate.page >= portalPaginate.max) return;
        setPortalPaginate((state)=>{
            loadData(state.page + 1);
            return { ...state, page: state.page + 1 }
        })
    }

    const renderJobportalJobs = () => {
        return(
            <>
                <div className="placement__jobportal__container__portal" >
                    <div className="placement__jobportal__container__portal__container" >
                    {
                        portalJobs.map((e,i)=>(
                            <div onClick={()=>{
                                if(width > 770) window.scrollTo(0,0);
                                setSelectIndex(i)
                                }} key={e._id} className="placement__jobportal__container__portal__card" >
                                <div className="placement__jobportal__container__portal__card__title" >
                                    <img src={ e.companyLogo || "/assets/placement/company.png"} alt="company" />
                                    <div>
                                        <h4>{e.title}</h4>
                                        <p>{e.company}</p>
                                    </div>
                                </div>
                                <div className="placement__jobportal__container__portal__card__stats" >
                                    <p>
                                        <img src="/assets/placement/experience.png" alt="experience" />
                                        {e.experience}
                                    </p>
                                    <p>
                                        <img src="/assets/placement/salary.png" alt="salary" />
                                        {e.salary}
                                    </p>
                                    <p>
                                        <img src="/assets/placement/location.png" alt="location" />
                                        {e.location}
                                    </p>
                                </div>
                                <ul >
                                    <li>{e.description}</li>
                                </ul>
                                <div className="placement__jobportal__container__portal__card__skills" >
                                    
                                </div>
                                <a href={e.jobUrl} target="__blank" 
                                    className="placement__jobportal__container__portal__card__btn" >
                                    Apply Now
                                </a>
                            </div>
                        ))
                    }
                    </div>
                    <p className="placement__jobportal__container__portal__pagination" >
                            <img
                                src="/assets/placement/left-arr.png" 
                                onClick={onPrevPortalPageClick} 
                                alt="left-arr" />
                            {portalPaginate.page} - {portalPaginate.max}
                            <img 
                                src="/assets/placement/right-arr.png" 
                                onClick={onNextPortalPageClick}
                                alt="right-arr" />
                    </p>
                </div>
            </>
        )
    }

    return(
        <section className="placement__jobportal" >
            {/* <h1>Welcome Back, Lets Find You Job! 🔥</h1> */}
            <div className="placement__jobportal__filter" >
                <div className="placement__jobportal__filter__card" onClick={()=>setViewJobs('personalized')}  >
                    <p>
                        Personalized Job Recommendation
                    </p>
                    <img src="/assets/placement/personal.png" alt="personal" />
                </div>
                <div className="placement__jobportal__filter__card" onClick={()=>setViewJobs('jobportal')} >
                    <p>
                        BT Portal
                    </p>
                    <img src="/assets/placement/businesstoys.png" alt="businesstoys" />
                </div>
                <div className="placement__jobportal__filter__card" onClick={()=>window.open('https://www.iimjobs.com/')} >
                    <p>
                        IIMjobs & Hirist
                    </p>
                    <img src="/assets/placement/iim.png" alt="iim" />
                </div>
            </div>
            <div className="placement__jobportal__container" >
                {(viewJobs === 'personalized') && renderPersonalJobs()}
                {(viewJobs === 'jobportal') && renderJobportalJobs()}
            </div>
        </section>
    )
};

export default JobPortal;