import React, { useEffect, useState } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ScrollIntoView from 'react-scroll-into-view';

import { useMockInterviewScheduleMutation, useMockInterviewListMutation } from "../../../store";
import toastService from '../../../toast'

function MockInterview(){
    const [ scheduleMockInterview ] = useMockInterviewScheduleMutation();
    const [ listMockInterview ] = useMockInterviewListMutation();
    const [date, setDate] = useState(null);
    const [timeIndex, setTimeIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [interviews, setInterviews] = useState([]);

    const loadData = async () => {
        try{
            let response = await listMockInterview();
            if(response.data && response.data.status === 'success'){
                setInterviews(response.data.data);
            }else{
                toastService.error();
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        loadData();
    },[])

    const disabledWeekdays = [1, 2, 3, 4, 5]; 
    const time = ['09-10 am', '10-11 am', '11-12 am', '12-01 pm', '01-02 pm'];
    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
          const dayOfWeek = date.getDay();
          return disabledWeekdays.includes(dayOfWeek);
        }
        return false; // Enable all other views (e.g., year, decade)
    };

    const onSubmit = async() => {
        try{
            let data = {
                date: date,
                time: time[timeIndex]
            };

            if(!data.time || !data.date){
                toastService.error('Select valid Date & Time');
                return;
            };

            if(!showModal){
                setShowModal(true);
                return;
            }

            let response = await scheduleMockInterview(data);
            if(response.data && response.data.status === 'success'){
                setTimeIndex(null);
                setShowModal(false);
                setDate(null);
                toastService.success('Mock Interview scheduled');
                loadData();
                window.scrollTo(0,0);
            }else{
                toastService.error();
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    };


    const onTimeClick = (_index) => {
        if(_index === 1 || _index === 2 ){
            setTimeIndex(_index)
        };
    };

    const onCancel = () => {
        setTimeIndex(null);
        setShowModal(false);
        setDate(null);
    };

    const renderBook = () => {
        if(interviews[0] && interviews[0]) return null;
        if(interviews[0]){
            if(new Date(interviews[0].createdOn) > new Date()) return null;
        };
        return(
            <>
                <div className="placement__mock__book__title" >
                    <h2>Book your slot</h2>
                    {/* <p>
                        <img src="/assets/placement/green-dot.png" alt="green-dot" />
                        Available
                    </p>
                    <p>
                        <img src="/assets/placement/beige-dot.png" alt="green-dot" />
                        Booked
                    </p> */}
                </div>
                <div className="placement__mock__book__calendar" >
                    <Calendar onChange={setDate} tileDisabled={tileDisabled} value={date} minDate={new Date()} />
                </div>
                <div className="placement__mock__book__time" >
                    <h4>Select Time</h4>
                    <div>
                        {time.map((e, i)=>(
                            <p 
                                className={(i === timeIndex) ? 'placement__mock__book__time__active' : '' }
                                key={i} 
                                onClick={()=>onTimeClick(i)} >{e}</p>
                        ))}
                    </div>
                </div>
                <button onClick={onSubmit} className="placement__mock__book__btn" >Submit</button>
            </>
        )
    }

    return(
        <>
            <section className="placement__mock" >
                <div className="placement__mock__title" >
                    <h1>Mock Interview</h1>
                    <p>
                        Enhance your interview preparation with our Mock Interviews. Practice virtual interviews with 
                        industry professionals, receive valuable 
                        feedback, and improve your communication skills. Prepare for success 
                        in Business Analytics and Data Science job interviews today!
                    </p>
                    <div className="placement__mock__title__btn" >
                        {
                            (interviews.length === 0) &&
                            (
                                <ScrollIntoView selector="#placement__mock__book">
                                    <button>Apply for Mock Interview <b>One</b></button>
                                </ScrollIntoView>
                            )
                        }
                        {
                            (interviews[0]) &&
                            (<h4>
                                Mock Interview Scheduled on <br />
                                <b>
                                    {new Date(interviews[0].scheduledOn).toLocaleDateString(undefined, {day: 'numeric', month: 'long', year: 'numeric'})} 
                                    &nbsp;
                                    at {interviews[0].timeRange}
                                </b>
                            </h4>)
                        }
                        {
                            (interviews.length <= 1) &&
                            (
                                <ScrollIntoView selector="#placement__mock__book">
                                    <button onClick={()=>{
                                        if(!interviews[0]) return;
                                        if(new Date(interviews[0].createdOn) < new Date()){
                                            toastService.info('Complete your 1st Mock Interview to schedule 2nd')
                                        }
                                    }}>
                                        {
                                            (interviews.length === 0) &&
                                            <img src="/assets/placement/mock-interview-lock.png" alt="mockinterview" />
                                        }
                                        Apply for Mock Interview <b>Second</b>
                                    </button>
                                </ScrollIntoView>
                            )
                        }
                        {
                            (interviews[1]) &&
                            (<h4>
                                Mock Interview Scheduled on <br />
                                <b>
                                    {new Date(interviews[1].scheduledOn).toLocaleDateString(undefined, {day: 'numeric', month: 'long', year: 'numeric'})} 
                                    &nbsp;
                                    at {interviews[1].timeRange}
                                </b>
                            </h4>)
                        }
                    </div>
                </div>
                <div id="placement__mock__book" className="placement__mock__book" >
                    {renderBook()}
                </div>
            </section>
            {
                (showModal) &&
                (<div className="placement__mock__modal" >
                    <div className="placement__mock__modal__container" >
                        <h2>Confirm your Slot</h2>
                        <p>Your Mock interview is Scheduled with  industry professionals on </p>
                        <p>
                            {date && date.toLocaleDateString(undefined, {day: 'numeric', month: 'long', year: 'numeric'})} 
                            &nbsp;&nbsp;
                            {time[timeIndex]}
                        </p>
                        <div className="placement__mock__modal__container__btn" >
                            <button onClick={onSubmit} >Confirm </button>
                            <button onClick={onCancel} >Cancel</button>
                        </div>
                    </div>
                </div>)
            }
        </>
    )
};

export default MockInterview;