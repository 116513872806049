import React, { useEffect, useState } from 'react';
import { MobilePDFReader } from 'react-read-pdf';
import { useParams } from 'react-router-dom';

import CONSTANTS from '../../constants';

function PDFViewer(){
    let params = useParams();

    return(
        <section className='pdf__open' >
            <MobilePDFReader 
                url={`${CONSTANTS.BaseURL}/study-material/${params.id}`}
            />
        </section>
    );
};

export default PDFViewer;