import { createSlice } from '@reduxjs/toolkit';

const homeSlice = createSlice({
    name: 'home',
    initialState: {
        academic: null, attendance: 0, courses: [], recording: []
    },
    reducers: {
        setHomeData(state, action){
            return action.payload;
        }
    }
});

export const { setHomeData } = homeSlice.actions;
export { homeSlice };


