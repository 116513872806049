import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useCoursesQuery } from '../../store/index';
import toastService from '../../toast';
import { globalQueryError } from '../../utils/__errorHandling';

function Courses(){
    const { data, error, refetch } = useCoursesQuery();
    let _data = { courses: [] };

    useEffect(()=>{
        refetch();
    }, [data]);

    if(error){
        if(error['data']){
            const { statusCode } = error['data'].error;
            globalQueryError(statusCode, error['data']);
        }else{
            toastService.error(error['error']);
        }
    }

    if(data){
        if(data['status'] === 'success'){
            _data = { ...data['data']};
        }
    }

    const renderCourse = () => {
        return _data.courses.map((e)=>{
            return(
                <Link to={e.deliveryType !== 'PRERECORDED' 
                    ? `/course/${e._id}`
                    : `/video/${e._id}`} key={e._id} className="course__card">
                    <img src={e.banner} alt="data-scientist-certification-program" />
                    <div className='course__card__textBox'>
                        <h3>{e.name}</h3>
                        <p>
                            &nbsp;
                            <span>{e.rating} <img src="/assets/icons/star.png" alt="star"/></span>
                        </p>
                        <div className='course__card__textBox_range'>
                            <span style={{ width: `${0}%` }} ></span>
                        </div>
                    </div>    
                </Link>
            )
        })
    }

    return(
        <section className='courses' >
            <h2>My Courses</h2>
            <div className='courses__container'>
                {renderCourse()}
            </div>
        </section>
    )
};

export default Courses;