import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { toggleNavbar, useQuizAnswerCheckMutation, useQuizDetailsMutation } from '../../store';
import { answerCheckQuiz } from '../../utils/__axios';
import toastService from "../../toast";
import { analyticsEvent } from "../../firebase";

function QuizPlay(){
    let params = useParams();
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const [fetchQuizData] = useQuizDetailsMutation();
    // const [fetchCheckAnswer] = useQuizAnswerCheckMutation();
    const audioTimerRef = useRef(null);
    const nextRef = useRef(null);

    const [content, setContent] = useState([]);
    const [index, setIndex] = useState(0);
    const [answerIndex, setAnswerIndex] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState({});
    const [optionDisable, setOptionDisable]  = useState(false);
    const [showNext, setShowNext] = useState(false);

    const loadData = async () => {
        try{
            let response = await fetchQuizData(params['slug']);
            let data = response.data;
            if(data && data['status'] === 'success' && data.data ){
                setContent(data['data']['content']);
                setData(data['data']);
            }else{
                toastService.error('Quiz not found');
                navigate('/');
            }

        }catch(err){
            console.log({ err });
            toastService.error();
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        loadData();
        dispatch(toggleNavbar(false));
        countDownTimerMP3(true);
        analyticsEvent({
            _event: 'quiz_play_view',
            _type: 'view',
            _custom: { quizName: params['slug'] }
        });
    }, [dispatch]);

    useEffect(()=>{
        if(showNext) window.scrollTo(0, document.documentElement.scrollHeight);
    }, [showNext]);

    const countDownTimerMP3 = (_play = false) => {
      if (_play) {
        audioTimerRef.current.currentTime = 0;
        audioTimerRef.current.play();
      } else {
        audioTimerRef.current.pause();
      }
    };

    const handleClick = () => {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 1200);
    };

    const answerCheck = async (_index) => {
        try{
            await setOptionDisable(true);
            let response = await answerCheckQuiz({
                _id: data['_id'],
                _data: {
                    questionId: content[index]._id,
                    slug: data['slug'],
                    question: content[index].question,
                    answered: content[index].options[_index],
                    time: Date.now(),
                    index: index
                }
            });

            if(response.data && response.data.status === 'success'){
                await setOptionDisable(false);
                if(index >= (content.length - 1)){
                    navigate(`/quiz/end/${params['slug']}`);
                    //end quiz
                    return;
                }else{
                    setShowNext(true);
                }
            }else{
                navigate(-1);
                await setOptionDisable(false);
                toastService.error();
            };

        }catch(err){
            console.log({ err });
            toastService.error();
        }
    }

    const onOptionClick = async(_index) => {
        await setAnswerIndex(_index);
        // setShowNext(true);
        if(optionDisable) return;
        answerCheck(_index);
    };

    const nextClick = () => {
        window.scrollTo(0,0);
        setAnswerIndex(null);
        handleClick();
        countDownTimerMP3(true);
        setShowNext(false);
        setIndex((state) => state + 1);
    };

    const renderContent = () => {
        if(content.length > 0){
            return (
                <>
                <div className="quiz__play__content" >
                    <h3 dangerouslySetInnerHTML={{__html: content[index].question}} ></h3>
                    <div className="quiz__play__content__options" >
                        {
                            content[index].options.map((e,i)=>(
                                <p 
                                    key={i}
                                    onClick={()=>onOptionClick(i)}
                                    style={{
                                        backgroundImage: (i === answerIndex) 
                                        ? 'url("/assets/quiz/option-active.png")' 
                                        : 'url("/assets/quiz/option-bc.png")',
                                        color: (i === answerIndex) ? '#000' : '#fff'
                                    }} 
                                >{e}</p>
                            ))
                        }

                    </div>
                </div>
                <div className="quiz__play__giphy" onClick={(e)=>e.preventDefault()} >
                    <iframe src={content[index].giphy} style={{
                        width: '100%',
                        objectFit: 'contain',
                        height: '100%',
                        position: 'absolute'
                    }} frameBorder={0}
                            className="giphy-embed">
                    </iframe>
                </div>
                </>
            )
        }else{
            return null;
        }
    };

    return(
        <section className="quiz" >
            <audio style={{ display: 'none' }} src="/assets/audio/quiz/count-down.mp3" ref={audioTimerRef} controls loop />
            <Link to='/' >
                <img className="quiz__logo" src="/assets/logos/logo.png" alt="MOAT Logo" />
            </Link>
            <div className={`quiz__play ${isVisible ? 'quiz__play__content__animate' : ''}`} >
                {renderContent()}
            </div>
            <div className="quiz__play__next" style={{ display: (showNext) ? 'block' : 'none' }} >
                <img ref={nextRef} id="next" src="/assets/quiz/quiz-next.png" onClick={nextClick} alt="quiz next" />
            </div>
        </section>
    )
};

export default QuizPlay;