import { createSlice } from "@reduxjs/toolkit"

const courseVideoSlice = createSlice({
    name: 'courseVideo',
    initialState: {
        _id: null,
        title: '',
        module: '',
        type: 'VIDEO', //'VIDEO' , 'QUIZ', 'ASSESSMENT'
        quiz: [],
        banner: null,
        uiType: '',
        subtitle: '',
        assessmentQuestions: []
    },
    reducers: {
        setCourseVideo(state, action){
            return { ...action.payload }
        }
    }
})

export const { setCourseVideo } = courseVideoSlice.actions
export { courseVideoSlice }