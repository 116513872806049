import React from "react";
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CONSTANTS from '../constants';
import { getCookie } from '../storage';
import { toggleNavigation, setRedirect, resetRedirect } from '../store';


let useAuth = () => {
    let login = getCookie(CONSTANTS.authCookie);
    return (login === 'true') ? true : false;
};

function ProtectedRoutes({ children }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigation = useSelector(state => state.navigation)
    const redirect = useSelector(state => state.redirect)
    const auth = useAuth();

    if(auth){
        if(!navigation['topbar']) dispatch(toggleNavigation(true));
        if(redirect !== '/') dispatch(resetRedirect());
        return children;
    }else{
        dispatch(setRedirect(`${location.pathname}${location.search}`));
        return <Navigate to='/login' />
    }
  }

export default ProtectedRoutes;