import React, { useEffect, useState } from 'react';

import Resources from './Resources';
import Feedback from './Feedback';
import Notes from './Notes';
import Content from './Content';
import toastService from '../../toast';
import Assignment from './Assignment';


function Nav({ data, loadData, setplyrTime}){
    let _data = data;
    const [width] = useState(window.innerWidth);
    const [fullwidth, setFullwidth] = useState(false);
    const [navigation, setNavigation] = useState('resources');

    useEffect(()=>{
        window.scrollTo(0,0);
        if(width < 770){
            setFullwidth(true);
        }else{
            setFullwidth(false);
        };
        loadData();
    }, [width]);

    // console.log({ _data });

    return(
        <div className='video__player__features' >
            <div className='video__player__features--title' >
                { fullwidth 
                    ? <h1 id='course-content'
                    className={(navigation === 'course_content') ? 'video--active' : null} 
                    onClick={()=>setNavigation('course_content')}
                    >Course Content</h1> : null 
                }
                <h1 
                    className={(navigation === 'notes') ? 'video--active' : null} 
                    onClick={()=>setNavigation('notes')}
                >Your Notes</h1>
                <h1 id='nav-assignment'
                    className={(navigation === 'assignment') ? 'video--active' : null} 
                    onClick={()=>setNavigation('assignment')}
                >Assignment</h1>
                <h1 id='resources'
                    className={(navigation === 'resources') ? 'video--active' : null} 
                    onClick={()=>setNavigation('resources')}
                >Resources</h1>
                <h1 id='nav-feedback'
                    className={(navigation === 'feedback') ? 'video--active' : null} 
                    onClick={()=>setNavigation('feedback')}
                >Feedback</h1>
            </div>
            {/* COURSE CONTENT */}
            <div style={{ marginTop: '30px' }} >
                { fullwidth ? 
                    ( (navigation === 'course_content') && <Content 
                        data={_data['paylist']} 
                        certificate={ { _id: _data['_id'], course: _data['course'] } }
                        /> )
                    : null }
            </div>
            {/* NOTES */}
            { (navigation === 'notes') && <Notes setplyrTime={setplyrTime} />}
            {/* ASSIGNMENT */}
            {/* RESOURCES */}
            {(navigation === 'resources') && <Resources />}
            {/* FEEDBACK */}
            { (navigation === 'feedback') && <Feedback id={_data['_id']} />}
            {/* ASSIGNMENT */}
            { (navigation === 'assignment') && <Assignment  />}
        </div>
    )
};

export default Nav;