import { createSlice } from '@reduxjs/toolkit';

const guideSlice = createSlice({
    name: 'guide',
    initialState: {
        run: false,
        modal: false,
        steps: [],
    },
    reducers: {
        setGuideModal(state, action){
            state.modal = (action.payload !== undefined) ? action.payload : !state.modal;
            return state;
        },
        setSteps(state, action){
            state.steps = action.payload;
            state.run = true;
            return state;
        },
        toggleRun(state, action){
            state.run = (action.payload !== undefined) ? action.payload : !state.run;
            state.modal = false;
        }
    }
});

export const{ setGuideModal, setSteps, toggleRun } = guideSlice.actions;
export { guideSlice };
