import React, { useRef, useState } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useNavigate } from "react-router-dom";

import { useResumeListQuery } from '../../../store';
import { globalQueryError } from "../../../utils/__errorHandling";
import toastService from "../../../toast";
import { uploadResume } from "../../../utils/__axios";

function ResumeBuilding(){
    const { data, error } = useResumeListQuery();
    const fileRef = useRef();
    const [previewUrl, setPreviewUrl] = useState(null);
    const [file, setFile] = useState();
    const navigate = useNavigate();

    let _data = [];
    let _sample = [];

    if(error){
        if(error['data']){
            const { statusCode } = error['data'].error;
            globalQueryError(statusCode, error['data']);
        }else{
            toastService.error(error['error']);
        }
    }

    if(data){
        if(data['status'] === 'success'){
            console.log({ data })
            _data = data['data'];
            _sample = data['sample'];
        }
    };

    const downloadResume = async(_uri) => window.open(_uri);

    const handleFileUpload = (_event) => {
        const file = _event.target.files[0];
        if(file['type'] !== 'application/pdf'){
            toastService.error('Invalid file type, Upload PDF files');
            return;
        }
        setPreviewUrl(URL.createObjectURL(file));
        setFile(file);
    };

    const submitResume = async() => {
        try{    
            let formData = new FormData();
            formData.append('resume', file, file.name);
            let response = await uploadResume(formData);
            if(response && response['data'].status === 'success'){
                toastService.info('Resume uploaded');
                toastService.success('We will be scheduling your call with our expert shortly');
                navigate('/placement');
            }else{
                toastService.error();
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    }

    const renderPDF = () => _data.map((e, i)=>{
        let docs = [
            { uri: e.uri },
        ]
        return (<div className="placement__resume__template__container--pdf" key={i} >
            <DocViewer 
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                documents={docs} 
                pluginRenderers={DocViewerRenderers}
                config={{ 
                    header: { disableHeader: true, disableFileName: false, retainURLParams: false, },
                }}    
            />
            <img
                onClick={()=>downloadResume(e.uri)}  
                className="placement__resume__template__container--pdf--download" 
                src="/assets/placement/resume-download.png" alt="resume download" />
        </div>)
    });

    return(
        <>
            <section className="placement__resume" >
                <h1>Resume Writing Guidelines</h1>
                <div className="placement__resume__sample" >
                    <div className="placement__resume__sample--left" >
                        <h2>The resume is a first impression, and it is important to make a good one.</h2>
                        <ul>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" /> 
                                Use a professional font and font size.
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Use consistent formatting throughout your resume.
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Use white space to make your resume easy to read.
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Use bullet points to list your skills and experience.
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Use strong action verbs to describe your skills and experience.
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Avoid using personal pronouns (I, me, my).
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Use strong action verbs to describe your skills and experience.
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Quantify your accomplishments whenever possible.
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Recheck your resume carefully before submitting it.
                            </li>
                            <li>
                                <img src="/assets/placement/white-dot.png" alt="white-dot" />
                                Do not edit Example Resume use sample resumes to edit
                            </li>
                        </ul>
                    </div>
                    <div className="placement__resume__sample--right" >
                        <div className="placement__resume__sample--right__pdf" >
                            {
                                _sample.length > 0 &&
                                (<DocViewer 
                                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                    zoom={'fitWidth'}
                                    documents={[
                                        {
                                            uri: _sample[0].uri,
                                        }
                                    ]} 
                                    pluginRenderers={DocViewerRenderers}
                                    config={{ 
                                        header: { disableHeader: true, disableFileName: false, retainURLParams: false, },
                                    }}    
                                />)
                            }
                        </div>
                        <button onClick={()=>downloadResume(_sample[0].uri)} >
                            Download Example Resume <img src="/assets/placement/download.png" alt="download" />
                        </button>
                    </div>
                </div>
                <div className="placement__resume__template" >
                    <h2>Sample Resume Templets</h2>
                    <div className="placement__resume__template__container" >
                        {renderPDF()}
                    </div>
                </div>
                <input type="file" 
                    ref={fileRef} 
                    className="placement__resume__template--file"
                    accept="application/pdf" multiple="false"
                    onChange={handleFileUpload}        
                />
                <button className="placement__resume__upload" onClick={()=>fileRef.current.click()}  >
                    Upload your Resume for Validation 
                    <img src="/assets/placement/upload.png" alt="upload" />
                </button>
            </section>
            {
                previewUrl &&
                <div className="placement__resume__modal" >
                    <div className="placement__resume__modal__container" >
                        <div className="placement__resume__modal__container__pdf" >
                             <DocViewer 
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                documents={[{ uri: previewUrl }]} 
                                pluginRenderers={DocViewerRenderers}
                                config={{ 
                                    header: { disableHeader: true, disableFileName: false, retainURLParams: false, },
                                }}    
                            />
                        </div>
                        <div className="placement__resume__modal__container__btn" >
                            <button onClick={()=>setPreviewUrl(null)} >Cancel</button>
                            <button onClick={()=>submitResume()} >Submit</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default ResumeBuilding;