import React ,{ useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';

import { toggleNavbar, usePaymentQuery, useRazorpayOrderMutation, useRazorpayVerifyMutation } from '../../store';
import toastService from "../../toast";
import { globalQueryError } from "../../utils/__errorHandling";
import CONSTANTS from "../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


function PaymentDetails(){
    const dispatch = useDispatch();
    const sidebar = useSelector(state => state.navigation.sidebar);

    const { data, error, refetch } = usePaymentQuery();
    let _data = { 
            totalFee: 0, 
            totalPaid: 0, 
            amountDue: 0,
            installment: 0,
            oneTimePayment: 0,
            student: { name: '', course: '', email: '', phone: '' },
            accounts: [] 
        };
    
    const [razorpayOrder] = useRazorpayOrderMutation();
    const [razorpayVerify] = useRazorpayVerifyMutation();
    const navigate = useNavigate();

    const formatNumber = (num = 0) => {
        let x = num.toString();
        let lastThree = x.substring(x.length-3);
        let otherNumbers = x.substring(0,x.length-3);
        if(otherNumbers !== '')
            lastThree = ',' + lastThree;
        let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
        return res; 
    }

    useEffect(()=>{
        dispatch(toggleNavbar(true))
        // refetch();
    }, [data]);

    const totalPercent = () => (Math.round((_data.totalPaid / _data.totalFee) * 100));

    if(error){
        if(error['data']){
            const { statusCode } = error['data'].error;
            globalQueryError(statusCode, error['data']);
        }else{
            toastService.error(error['error']);
        }
    }

    if(data){
        if(data['status'] === 'success'){
            _data = { ...data};
        }
    };

    const loadScript = (src) =>{
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const displayRazorpay = async (fee = 0)=>{
        const rp = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!rp) {
            // toast err
            toastService.error()
            return;
        }

        // creating a new order
        const result = await razorpayOrder(fee);
        if (!result) {
            // toast err
            toastService.error();
            return;
        }

        // Getting the order details back
        const { amount, id: order_id, currency } = result.data;

        const options = {
            key: "rzp_live_3zTUV5jP5Z41tX", // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: _data.student['name'],
            description: `${_data.student['course'] } Fee `,
            order_id: order_id,
            handler: async (response)=>{
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                };

                let verify = await razorpayVerify(data);
                if(verify.data['status'] === 'success'){
                    // toast success
                    toastService.success('Payment Successfull');
                    refetch();
                }else{
                    // toast err
                    toastService.error('Payment, verification failed');
                    refetch();
                }
            },
            prefill: {
                name: _data.student['name'],
                email: _data.student['email'],
                contact:_data.student['phone'],
            },
            notes: {
                address: "",
            },
            theme: {
                color: "#1C47FC",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const renderInstallment = () => (
        <div className="payment__cards__card">
            <div>
                <h3>Pay As You Learn</h3>
                <h4> &#8377; {formatNumber(_data['installment'])} &#47;&#8208;</h4>
                <p>All transaction are secured </p>
                <hr />
                {/*
                <div className="payment__cards__card_ibm">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/ibm.png" alt="" />
                    <span>IBM Certification</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/nascom.png" alt="" />
                    <span>NASSCOM Certification</span>
                </div>
                <div className="payment__cards__card_iim">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/iimjobs.png" alt="" />
                    <span>IIMJOBS-Hirist job portal Premium Membership</span>
                </div>
                */}
            </div>        
            <div className="pay">
                <button className="btn" onClick={()=>displayRazorpay(_data['installment'])} >Pay Now</button>
            </div>
        </div>
    );

    const renderLoan = () => {
        let loanFee = (_data['totalFee'] - _data['totalPaid']);
        if (loanFee < 20000) return null;
        return(
        <div className="payment__cards__card">
            <div>
                <h3>6 or 9 Months No Cost EMI</h3>
                <h4> &#8377; {
                        formatNumber(Math.round((_data['totalFee'] - _data['totalPaid'])/ 9))
                    } 
                &#47;&#8208;</h4>
                <p>0% Interest, Easy EMI </p>
                <hr />
                {/*
                <div className="payment__cards__card_iim">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/amazon.png" alt="" />
                    <span>Amazon Prime Subscription</span>
                </div>
                <div className="payment__cards__card_spotify">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/spotify.png" alt="" />
                    <span>Spotify Premium</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/youtube.png" alt="" />
                    <span>Youtube Premium</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/watch.png" alt="" />
                    <span>5 Free Webinars</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/learn.png" alt="" />
                    <span>Free Cohort based course worth &#8377;4,999 </span>
                </div>
                <div className="payment__cards__card_ibm">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/ibm.png" alt="" />
                    <span>IBM Certification</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/nascom.png" alt="" />
                    <span>NASSCOM Certification</span>
                </div>
                <div className="payment__cards__card_iim">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/iimjobs.png" alt="" />
                    <span>IIMJOBS-Hirist job portal Premium Membership</span>
                </div>
                */}
            </div>    
            <div className="pay">
                <button onClick={()=>navigate('/payment/kyc')} className="btn btn-card">Apply Now</button>
            </div>

        </div>)
    };

    const renderOneTime = () => (
        <div className="payment__cards__card">
            <div>
                <h3>One Time Payment</h3>
                <h4> &#8377; {formatNumber(_data['oneTimePayment'])} &#47;&#8208;</h4>
                <p>Save 
                    <span className="payment__cards__card-save" > &#8377; &nbsp;{
                        formatNumber(_data['totalFee'] - (_data['oneTimePayment'] + _data['totalPaid'])
                        )} </span> 
                    on one time payment
                </p>
                <hr />
                {/*
                <div className="payment__cards__card_iim">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/amazon.png" alt="" />
                    <span>Amazon Prime Subscription</span>
                </div>
                <div className="payment__cards__card_spotify">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/spotify.png" alt="" />
                    <span>Spotify Premium</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/youtube.png" alt="" />
                    <span>Youtube Premium</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/watch.png" alt="" />
                    <span>5 Free Webinars</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/learn.png" alt="" />
                    <span>Free Cohort based course worth &#8377; 4999</span>
                </div>
                <div className="payment__cards__card_ibm">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/ibm.png" alt="" />
                    <span>IBM Certification</span>
                </div>
                <div className="payment__cards__card_ns">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/nascom.png" alt="" />
                    <span>NASSCOM Certification</span>
                </div>
                <div className="payment__cards__card_iim">
                    <img src="/assets/icons/check.png" alt="" />
                    <img src="/assets/payment_details/iimjobs.png" alt="" />
                    <span>IIMJOBS-Hirist job portal Premium Membership</span>
                </div>
                    */}
            </div>
            <div className="pay">
                <button className="btn btn-card" onClick={()=>displayRazorpay(_data['oneTimePayment'])} >Pay Now</button>
            </div>
        </div>
    );

    const renderPayHistory = ()=>{
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return _data.accounts.map((e)=>{
            return (
                <div className="payment__history__container_body" key={e._id}>
                    <div>{new Date(e.createdOn).toLocaleDateString('en-US', options)}</div>
                    <div> &#8377; {e.amount} &#47;&#8208;</div>
                    <div>
                        <a href={`${CONSTANTS.BaseURL}/student/invoice/${e._id}`} 
                            target="__blank"
                            download="invoice" >
                            Download 
                            <img src="/assets/icons/download.png" alt="" />
                        </a>
                    </div>

                </div>
            );
        });
    };

    return (
        <section className="payment" style={{ width: (sidebar) ? '85%' : '100%' }} >
            <div className="payment__amt">
                <div className="payment__amt__head">
                    <div className="btn-lg">
                        <h3>Total Investment</h3>
                        <p>&#8377; <span>{formatNumber(_data['totalFee'])}</span></p>
                    </div>
                    <div >
                        <h3>Amount Paid</h3>
                        <p>&#8377; <span>{formatNumber(_data['totalPaid'])}</span></p>
                    </div>
                    <div >
                        <h3>Amount Due</h3>
                        <p>&#8377; <span>{formatNumber(_data['amountDue'])}</span></p>
                    </div>
                </div>
                <div className="payment__amt__chart">
                    <div style={{ width: 100, height: 100 }}>
                        <CircularProgressbar value={totalPercent()}
                            styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color
                                  stroke: '#d6d6d6',
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'butt',
                                  // Customize transition animation
                                  transition: 'stroke-dashoffset 0.5s ease 0s',
                                  // Rotate the path
                                  transform: 'rotate(0turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color
                                  stroke: `rgba(35, 77, 163, ${ 90 / 100})`,
                                  strokeWidth: 8,
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: 'round',
                                  // Rotate the trail
                                  transform: 'rotate(0.25turn)',
                                  transformOrigin: 'center center',
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: '#f88',
                                  // Text size
                                  fontSize: '16px',
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: '#234DA3',
                                },
                              }}
                        />
                    </div>
                    <div className="payment__amt__chart-labels">
                        <span><img src="/assets/icons/white-bullet.png" alt="dot" /> &nbsp; Amount Paid</span>
                        <span><img src="/assets/icons/blue-bullet.png" alt="dot" /> &nbsp; Amount Due</span>
                    </div>  
                </div>
            </div>

            <div className="payment__cards">
                { (_data['amountDue'] > 0) && renderInstallment()}
                { (_data['amountDue'] > 0) && renderLoan()}
                { (
                    (_data['amountDue'] && _data['oneTimePayment'] !== _data['installment']) 
                    > 0) && renderOneTime()}
            </div>

            <div className="payment__history">
                <h3>Payment History</h3>

                <div className="payment__history__container">
                    <div className="payment__history__container_head">
                        <div>Date</div>
                        <div>Amount</div>
                        <div>Reciept</div>
                    </div>
                    {renderPayHistory()}
                </div>
            </div>
        </section>
    );            
    
}

export default PaymentDetails;