import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toggleSidebar } from '../../store';

import CONSTANTS from '../../constants';

const assets = CONSTANTS.assets;

function Loading(){
    const dispatch = useDispatch();
    const loading = useSelector(state => state.loading)
    const query = useSelector(state => Object.values(state.api.queries).some(query => query.status === 'pending'))
    const mutation = useSelector(state => Object.values(state.api.mutations).some(mutation => mutation.status === 'pending'))

    const [viewState, setViewState] = useState(false);
    
    useEffect(()=>{
        if(loading || query || mutation){
            setViewState(true);
        };
        if(window.innerWidth < 770){
            let url = window.location.href;
            let dont = ['/login', '/signup', `/${CONSTANTS.magicLinkSlug}`];
            if (!dont.some(substring => url.includes(substring))) {
                dispatch(toggleSidebar(true));
            }
        }
        setTimeout(()=>{
            setViewState(false);
        }, 800)
    }, [loading, query, mutation]);    

    if(viewState && (!window.location.href.includes('/course') && !window.location.href.includes('/video'))){   
        return(
        <section className='loading' >
            <img src={assets + '/logos/logo.png'} alt="logo" />
            <div className='loading__bar' >
                <span></span>
            </div>
        </section>
    )}else{
        return null;
    }
};

export default Loading;