import React, { useCallback, useEffect, useRef, useState } from 'react';
import Plyr from "plyr-react";
import "plyr-react/plyr.css";

import { useDispatch, useSelector } from 'react-redux';
import { useMarkAttendanceMutation, setTimestamp, popupfreeCourseFeedback } from '../../store';
// import CONSTANTS from '../../constants';
// import PlyrComponent from './VideoJS';
import { videoWatchTime, videoWatchTimeGet } from '../../utils/__axios';
import { useParams } from 'react-router-dom';
import { getCookie, setCookie } from '../../storage';

function useHookWithRefCallback(_videoDetails, _time = 0, _videoDuration = false, _courseId) {
    const ref = useRef(null);
    const dispatch = useDispatch();
    const setRef = useCallback(node => {
      if (ref.current) {
        // Make sure to cleanup any events/references added to the last instance
      };

      if (node) {
        // Check if a node is actually passed. Otherwise node would be null.
        // You can now do what you need to, addEventListeners, measure, etc.
        let plyr = node.plyr;
        if(plyr.source !== null){
            
          plyr.on('ready', (event)=>{
            event.detail.plyr.currentTime = _time;
          });

          plyr.on('timeupdate', (event)=>{
            const currentTime = event.detail.plyr.currentTime;
            dispatch(setTimestamp(currentTime));
          });

          // plyr.on('loadeddata', (event)=>{
          //   console.log({ 'loadeddata': event });

          //   // if(event.detail.plyr.currentTime === 0 || event.detail.plyr.currentTime < 20){
          //   //   if(_time > 20){
          //   //     event.detail.plyr.currentTime = _time;
          //   //   }
          //   // };
          // })
          let courseId =  getCookie('courseId');
          
          plyr.on('progress', (event) => {

            if(event.detail.plyr.currentTime > 0){
              videoWatchTime(_videoDetails['_id'], {
                inital: false,
                seeked: event.detail.plyr.currentTime
              });
            };
            
            if(
              _videoDetails['videoDuration'] && 
              courseId === '648c27d4893d34e65002124f' 
            ){
              if(event.detail.plyr.currentTime > _videoDetails['videoDuration']){
                let seenCert = getCookie('648c27d4893d34e65002124f__cert');
                if(seenCert !== 'true'){
                  dispatch(popupfreeCourseFeedback(true));
                }
                setCookie('648c27d4893d34e65002124f__cert', true);
              }
            }

            // if(event.detail.plyr.ready){
            //   if(event.detail.plyr.currentTime === 0 || event.detail.plyr.currentTime < 20){
            //     if(_time > 20){
            //       event.detail.plyr.currentTime = _time;
            //     }
            //   };
            // }
          });

          // plyr.on('canplay', (event)=>{
          //   console.log({ 'canplay': event });
          //   event.detail.plyr.currentTime = _time;
          // });

        }
      }
      // Save a reference to the node
      ref.current = node
    }, [])
    return [setRef]
}

function Player(){
    const [playTime, setPlayTime] = useState(null);
    const videoDetails= useSelector(state =>  state.videoDetails);
    const [plyrRef] = useHookWithRefCallback(videoDetails, playTime || 0);
    const notes = useSelector(state => state.notes );
    const [markAttendance] = useMarkAttendanceMutation();
    const [marker, setMarker] = useState([]);


    useEffect(()=>{
      let _modMarker = [];
      for(let i=0; i<notes.length; i++){
        let note = notes[i];
        _modMarker.push({
          time: note.timestamp,
          label: note.content
        })
      };
      setMarker(_modMarker);
    }, [notes]);

    const loadData = async() => {
      try{ 
        let response = await videoWatchTimeGet(videoDetails['_id']);
        if(response.data.status === 'success'){
          setPlayTime(response.data.time)
        }
      }catch(err){
        console.log({ err });
      }
    };

    useEffect(()=>{
      videoWatchTime(videoDetails['_id'], {
        inital: true,
      });
      // loadData();
    }, [videoDetails]);

    const renderPlayer = () => {
        return (
        <div style={{ display: (videoDetails.status !== 'PROCESSED') ? 'none' : 'block' }}>
            <Plyr ref={plyrRef} 
            title={ `${videoDetails['title']}` || 'Business Toys' }
            source={
              {
                type: "video",
                sources: [{ src: videoDetails['src']}]
              }
            } 
            options={{
              debug: false,
              hideControls: false,
              autoplay: false,
              settings: ['speed'],
              markers: {
                enabled: true,
                points: marker
              },
              controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'pip', 'airplay', 'fullscreen']
            }}
          />
          <div id='player' ></div>
        </div>
        )
      };

    const renderLive = () => {
      return (
        <div className='video__player__live'  >
            {videoDetails.title}
          <br />
          <br />
          {
            (videoDetails.meetingUrl !== 'Recording not available') ?
            <a href={videoDetails.meetingUrl}
              onClick={()=>markAttendance(videoDetails._id)} target="__blank" className="btn" >JOIN NOW
            </a>
            : null
          }
        </div>
      )
    }

    return (
        <div id='video-player' >
          {renderPlayer()}
          {/* <PlyrComponent /> */}
          {(videoDetails.status === 'SCHEDULED') && renderLive()}
        </div>
    );
};

export default Player;