import { createSlice } from '@reduxjs/toolkit';

const courseSlice = createSlice({
    name: 'course',
    initialState: {
        id: '',
        name: '',
        createdOn: null,
        issueCertificate: null,
        deliveryType: ''
    },
    reducers: {
        setCourseData(state, action){
            return { ...action.payload }
        }
    }
});

export const { setCourseData } = courseSlice.actions
export { courseSlice };