import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from 'react-hot-toast';

import CONSTANTS from '../constants';
import ProtectedRoutes from './ProtectedRoutes';

import Login from './Login';
import Signup from './Signup';
import OnBoard from './Onboard';
import Home from './Home';
import Navbar from './Navigation';
import SideBar from './Navigation/_sidebar';
import Courses from './Courses';
import PaymentDetails from './Payment';
import LoanPayments from './Loan';
// import JobPortal from './JobPortal';
import Video from './Video';
import Loading from './Loading';
import CollegeSignup from './Signup/College';
import CollegeOnBoard from './Onboard/college';
import Popup from './Popup';
import PDFViewer from './PDFViewer';
import FeedbackPopup from './Popup/_feedBackPopup';
import Privacy from './Policies/privacy';
import Terms from './Policies/terms';
import MagicLink from './Login/magicLink';
import Guide from './Guide';
import UserGuidePopup from './Popup/_userGuidePopup';
import Quiz from './Quiz';
import QuizPlay from './Quiz/play';
import QuizEnd from './Quiz/end';
import FreeCoursePopup from './Popup/_freeCoursePopup';
import FreeCourseFeedbackPopup from './Popup/_freeCourseFeedback';
import Placement from './Placement';
import ResumeBuilding from './Placement/ResumeBuilding';
import InterviewPreparation from './Placement/InterviewPreparation';
import InterviewPreparationList from './Placement/InterviewPreparation/list';
import MockInterview from './Placement/MockInterview';
import JobPortal from './Placement/JobPortal';
import InterviewPreparationDetail from './Placement/InterviewPreparation/detail';
import VidStackPlayer from './VideoPlayer/vidStack';
import Course from './Course';
import CollegeExam from './Signup/CollegeExam';

function Component(){
    return(
        <BrowserRouter>
            <Loading />
            <Popup />
            <FeedbackPopup />
            <UserGuidePopup />
            <FreeCoursePopup />
            <FreeCourseFeedbackPopup />
            <GoogleOAuthProvider 
                clientId={CONSTANTS.GoogleClientId}
            >
                <div className='container'>
                    <Navbar />
                    <SideBar /> 
                    {/* <Guide /> */}
                    <div className='content' >
                        <Routes>
                            <Route path='/login' element={
                                <Login />
                            } ></Route>
                            <Route path='/player' element={
                                <VidStackPlayer />
                            } ></Route>
                            <Route path='/college/:id' element={
                                <CollegeSignup />
                            } ></Route>
                            <Route path='/exam/:id' element={
                                <CollegeExam />
                            } ></Route>
                            <Route path='/signup/:id' element={
                                <CollegeSignup />
                            } ></Route>
                            <Route path='/onboard' element={
                                <OnBoard />
                            } ></Route>
                            <Route path='/onboard/ds' element={
                                <CollegeOnBoard />
                            } ></Route>
                            <Route path='/privacy-policy' element={
                                    <Privacy />
                            } ></Route>
                            <Route path='/terms-and-conditions' element={
                                    <Terms />
                            } ></Route>
                            <Route path={`/${CONSTANTS.magicLinkSlug}/:id`} element={
                                    <MagicLink />
                            } ></Route>
                            
                            {/* PROTECTED ROUTES */}
                            <Route path='/' element={
                                <ProtectedRoutes>
                                    <Home />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/courses' element={
                                <ProtectedRoutes>
                                    <Courses />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/payment' element={
                                <ProtectedRoutes>
                                    <PaymentDetails />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/payment-details' element={
                                <ProtectedRoutes>
                                    <PaymentDetails />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/payment/kyc' element={
                                <ProtectedRoutes>
                                    <LoanPayments />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/job-portal' element={
                                <JobPortal />
                            } ></Route>
                            <Route path='/course/:id' element={
                                <ProtectedRoutes>
                                    <Video />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/pdf-open/:id' element={
                                <ProtectedRoutes>
                                    <PDFViewer />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path={'/quiz/topic/:slug'} element={
                                <ProtectedRoutes>
                                    <Quiz />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path={'/quiz/play/:slug'} element={
                                <ProtectedRoutes>
                                    <QuizPlay />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path={'/quiz/end/:slug'} element={
                                <ProtectedRoutes>
                                    <QuizEnd />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/placement' element={
                                <ProtectedRoutes>
                                    <Placement />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/placement/resume' element={
                                <ProtectedRoutes>
                                    <ResumeBuilding />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/placement/interview-preparation' element={
                                <ProtectedRoutes>
                                    <InterviewPreparation />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/placement/interview-preparation/:slug' element={
                                <ProtectedRoutes>
                                    <InterviewPreparationList />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/placement/interview-preparation-detail/:slug' element={
                                <ProtectedRoutes>
                                    <InterviewPreparationDetail />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/placement/mock-interview' element={
                                <ProtectedRoutes>
                                    <MockInterview />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/placement/job-portal' element={
                                <ProtectedRoutes>
                                    <JobPortal />
                                </ProtectedRoutes>
                            } ></Route>
                            <Route path='/video/:id' element={
                                <ProtectedRoutes>
                                    <Course />
                                </ProtectedRoutes>
                            } ></Route>
                            {/* 404 ROUTE */}
                            <Route path="*" element={<Login />} ></Route>
                        </Routes>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={9000}
                    bodyClassName="toastBody"
                    newestOnTop={true}
                    hideProgressBar={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme= "colored"
                    />
            </GoogleOAuthProvider>
            {/* <Toaster 
                position="top-right"
                toastOptions={{
                    style: {
                        fontFamily: 'Plus Jakarta Sans',
                    }
                }}
            /> */}
        </BrowserRouter>
    )
}

export default Component;