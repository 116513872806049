import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import fileDownload from "js-file-download";

import { useInterviewPrepDetailMutation } from "../../../store";
import toastService from "../../../toast";
import { downloadInterviewAssets, downloadInterviewSolution, uploadPlacementAssignment } from "../../../utils/__axios";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

function InterviewPreparationDetail(){
    const [interviewPrepDetail] = useInterviewPrepDetailMutation();
    const { slug } = useParams();
    const [data, setData] = useState({ objective: [], files: [] });
    const [solution, setSolution] = useState(null);
    const [file, setFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const fileRef = useRef();

    const loadData = async(_slug) => {
        try{
            let response = await interviewPrepDetail(_slug);
            if(response && response.data && response.data.status === 'success'){
                setData(response.data.data);
                setSolution(response.data.solution);
            }else{
                toastService.error();
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    };

    useEffect(()=>{
        loadData(slug);
    },[]);

    const downloadAssets = async(_key) => {
        try{
            toastService.success('Downloading file');
            let response = await downloadInterviewAssets(_key);
            fileDownload(response.data, _key);
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    };

    const downloadSolution = async(_key, _name) => {
        try{
            toastService.success('Downloading file');
            let response = await downloadInterviewSolution(_key);
            fileDownload(response.data, _name);
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    }

    const handleFileUpload = (_event) => {
        const file = _event.target.files[0];
        setPreviewUrl(URL.createObjectURL(file));
        setFile(file);
    };

    const submitSolution = async() => {
        try{
            let formData = new FormData();
            formData.append('solution', file, file.name);
            let response = await uploadPlacementAssignment(data._id, formData);
            if(response && response.data.status === 'success'){
                toastService.success('Upload successfully');
                setSolution(response.data.data);
                setPreviewUrl(null);
            }else{
                toastService.error();
            }
        }catch(err){
            toastService.error();
        }
    }

    return(
        <>
            <section className="placement__interview__detail" >
                <h4>Objective</h4>
                <div className="placement__interview__detail__container" >
                    { data && data['objective'].map((e,i)=>(
                        <p key={i} >{e}</p>
                    )) }

                    <div className="placement__interview__detail__container__btn" >
                        <h3>Download Assets</h3>
                        {   
                            data['files'].map((e,i)=>(
                                <h6 onClick={()=>downloadAssets(e.awsKey)} key={i} >
                                    {e.awsKey}
                                    <img src="/assets/placement/download.png" alt="download" />    
                                </h6>
                            ))
                        }
                    </div>
                </div>
                <div className="placement__interview__detail__submit" >
                    <input ref={fileRef} type="file" onChange={handleFileUpload}  />
                    <button onClick={()=>fileRef.current.click()} >
                        Upload your solution 
                        <img src="/assets/placement/upload.png" alt="upload" />
                    </button>
                    {
                        solution && 
                        (<div className="placement__interview__detail__submit__solution" >
                            <h4>Submitted Files</h4>
                            {
                                solution.answers.map((e,i)=>(
                                    <p key={i} onClick={()=>downloadSolution(e.awsKey, e.name)} >{i+1}. {e.name}</p>
                                ))
                            }
                        </div>)
                    }
                </div>
            </section>
            {
                previewUrl &&
                <div className="placement__interview__modal" >
                    <div className="placement__interview__modal__container" >
                        <div className="placement__interview__modal__container__pdf" >
                             <DocViewer 
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                documents={[{ uri: previewUrl }]} 
                                pluginRenderers={DocViewerRenderers}
                                config={{ 
                                    header: { disableHeader: true, disableFileName: false, retainURLParams: false, },
                                }}    
                            />
                        </div>
                        <div className="placement__interview__modal__container__btn" >
                            <button onClick={()=>setPreviewUrl(null)} >Cancel</button>
                            <button onClick={()=>submitSolution()} >Submit</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default InterviewPreparationDetail;