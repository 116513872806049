import React, { useEffect, useState } from "react";
import ReactStars from 'react-stars';
import { useSelector } from 'react-redux';

import { 
    useGetFeedbackMutation,
    useSubmitFeedbackMutation
} from '../../store';
import toastService from "../../toast";

function Feedback(){
    const [star, setStar] = useState(0);
    const [comments, setComment] = useState('');
    const [ fetchFeedback ] = useGetFeedbackMutation();
    const [ submitFeedback ] = useSubmitFeedbackMutation();
    
    const { _id } = useSelector(state => state.videoDetails );

    const loadData = async() => {
        try{
            let response = await fetchFeedback(_id);
            let data = response.data;
            if(response.data.status === 'success'){
                if(data.feedback){
                    let feedback = data.feedback;
                    setStar(feedback.rating);
                    setComment(feedback.comments);
                }else{
                    setStar(0);
                    setComment('');
                };
            };
        }catch(err){
            // console.log({ err });
        }
    }

    useEffect(()=>{
        loadData();
    }, [_id]);

    const submitForm = async() => {
        try{
            let body = {
                rating: star,
                comments
            };
            if(star <= 0){
                toastService.error('Select valid rating!');
                return;
            }
            if(`${comments}`.length <= 3 ){
                toastService.error('Enter valid comment');
                return;
            }
            let response = await submitFeedback({
                _id,
                body
            });
            if(response.data.status === 'created'){
                toastService.success('Feedback submitted');
                loadData();
            }
        }catch(err){
            // console.log({ err });
        }
    }

    return(
        <div className='video__player__features--feedback' >
            <h4>How would you rate this session?</h4>
            <div className="video__player__features--feedback__container">
                <ReactStars
                    count={5}
                    value={star}
                    onChange={(_e)=>{setStar(_e)}}
                    size={37}
                    half={false}
                    color2={'#145EEE'} />
                <textarea value={comments} placeholder="Comments....." onChange={(e)=>setComment(e.target.value)} ></textarea>
                <button onClick={submitForm} className="btn" >Submit Feedback</button>
            </div>
        </div>
    )
};

export default Feedback;