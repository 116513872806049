import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CONSTANTS from '../../constants';
import { toggleSidebar, useLogOutMutation } from '../../store';
import Notification from '../Notifications';
// import toastService from '../../toast';
import { topbarData } from '../../utils/__axios';
import toastService from '../../toast';
import { getCookie, removeCookie } from '../../storage';

const assets = CONSTANTS.assets;

function Navbar(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {sidebar, topbar, navbar} = useSelector(state => state.navigation)
    const user = useSelector(state => state.user)
    
    // const sidebarClick = () => dispatch(toggleSidebar());
    const [certT, setCertT] = useState(false);
    const [logOut] = useLogOutMutation();

    const loadData = async() => {
        try{
            let response = await topbarData();
            if(response.data.status === 'success'){
                (response.data.show === -1) ? setCertT(false) : setCertT(true);
            }
        }catch(err){
            // console.log({ err });
        }
    }

    useEffect(()=>{
        let isAuth = getCookie(CONSTANTS.authCookie);
        // if(isAuth) loadData();
    }, [topbar, window.location.href]);

    let renderTopbar = () => {
        if(certT){
            return(
                <Link className='nav__top' target="__blank" to='/course/64195294728a7a711ac56173' >
                    2 Day Masterclass on Data Science Churn rate by Anurag Meher is live now! <span>Click Here</span>
                </Link>
            )
        }else{
            return null
        }
    }

    const logoutClick = async() => {
        try{
            let response = await logOut();
            removeCookie(CONSTANTS.authCookie);
            if(response['data'].status === 'success'){
                toastService.success('Logged Out');
                removeCookie(CONSTANTS.authCookie);
                navigate('/login');
            }else{
                toastService.error();
            }
        }catch(err){
            toastService.error();
        }
    };

    return(
        <React.Fragment>
            <nav style={{ display: (navbar) ? 'flex' : 'none' }} >
                <Link to='/' className='nav__logo' >
                    <img  src={assets + '/logos/logo-v2.png'} alt='logo' />
                </Link>
                { topbar && 
                    (<div className='nav__home' >
                        <a href='https://moatschool.live/master-the-art-of-cracking-product-manager-interviews' id='upcoming-events' target="__blank" className='nav__home__card' >
                            <img src={assets + '/icons/calender.png'} alt="calendar" />
                            <p>Upcoming Events</p>
                        </a>
                        <a href='https://businesstoys.in/refer-and-earn/' id='refer-and-earn' target="__blank" className='nav__home__card' >
                            <img src={assets + '/icons/speaker.png'} alt="calendar" />
                            <p>Refer & Earn</p>
                        </a>
                        <Notification />
                        <div className='nav__home__user' >
                            <img src={ user['profilePic'] || assets + '/icons/user.png'} alt="user" />
                            <p>{/*Username*/}</p>
                            <div className='nav__home__user--options' >
                                <button onClick={logoutClick} >Logout</button>
                            </div>
                        </div>
                    </div>)
                }
            </nav>
        </React.Fragment>
    );
};

export default Navbar;