import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileDownload from 'js-file-download';

import { popupfreeCourseFeedback, setVideoDetials } from '../../store';
import CONSTANTS from '../../constants';
import { downloadCertificate } from '../../utils/__axios';
import toastService from '../../toast';
import { getCookie } from '../../storage';

const assets = CONSTANTS.assets;

function Content(props){
    const dispatch = useDispatch();
    const { title } = useSelector(state => state.videoDetails);
    const [openItems, setOpenItems] = useState({});

    const toggleItem = (key) => {
      setOpenItems((prevState) => ({
        ...prevState,
        [key]: !prevState[key],
      }));
    };
    
    const onTopicClick = (_title, _id, _status, _meetingUrl, _attachments, _videoDuration) => {
        window.scrollTo(0,0);
        dispatch(setVideoDetials({
            status: _status,
            _id: _id,
            title: _title,
            meetingUrl: _meetingUrl,
            attachments: _attachments,
            videoDuration: _videoDuration
        }));
    };

    useEffect(()=>{
        Object.entries(props['data']).forEach(([key, value], i) => {
            if(i === 0) toggleItem(key);

            if(!props._id && i === 0){
                let _data = value[0];
                dispatch(setVideoDetials({
                    status: _data.videoStatus,
                    _id: _data._id,
                    title: _data.title.split('##')[0],
                    meetingUrl: _data.meetingUrl,
                    attachments: _data.attachments,
                    videoDuration: _data.videoDuration
                }));

            }else{
                for(let i=0; i < value.length; i++){
                    let _data = value[i];
                    if(props._id === _data._id){
                        dispatch(setVideoDetials({
                            status: _data.videoStatus,
                            _id: _data._id,
                            title: _data.title.split('##')[0],
                            meetingUrl: _data.meetingUrl,
                            attachments: _data.attachments,
                            videoDuration: _data.videoDuration
                        }));
                    }
                    
                }
            }
        });
    }, [props]);

    const renderContentObj = () => (
        Object.entries(props['data']).map(([key, value], i)=>{
            if( i === 0 && title === '' ){
                let _e = value[0];
                if(_e.videoStatus === 'SCHEDULED'){
                    onTopicClick(
                        _e.title.split('##')[0], 
                        _e._id, 
                        _e.videoStatus,
                        _e.meetingUrl,
                        _e.attachments,
                        _e.videoDuration
                    );
                }
            };
            return(
                <div key={key} className='video__stats__content--card'>
                    <h4 onClick={() => toggleItem(key)} >
                        {key}
                        <img src={assets + '/icons/arrow-down.png'} alt="arrow-down" />
                    </h4>
                    {openItems[key] && (
                    <span>
                        {value.map((e)=>(
                            <div key={e._id} onClick={()=>onTopicClick(
                                    e.title.split('##')[0],
                                    e._id, 
                                    e.videoStatus,
                                    e.meetingUrl,
                                    e.attachments
                                )} 
                                className='video__stats__content--card--content' >
                                {   (e.videoStatus === 'PROCESSED')
                                        ? <img src={assets + '/icons/check-box-check.png'} alt="check" />
                                        : <img src={assets + '/icons/check-box.png'} alt="check" /> 
                                }
                                <div>
                                    <h5>
                                        {e.title.split('##')[0]}
                                    </h5>
                                    <div className='video__stats__content--card--content--play' >
                                        <img src={assets + '/icons/play.png'} alt="play" />
                                        {
                                            new Date(e.scheduledOn).toLocaleString('en-US', {
                                                year: 'numeric', 
                                                month: 'short', 
                                                day: 'numeric', 
                                                hour: 'numeric',
                                                minute: 'numeric'
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}                    
                    </span>
                    )}
                </div>
            )
        }
        )
    );

    let certificateDownload = async(_url) => {
        try{    
            let response = await downloadCertificate(_url);
            FileDownload(response.data, 'certificate.png');
        }catch(err){
            toastService.error();
        }
    }

    const renderCertificate = (_certificate = { _id: '', 
        course: { issueCertificate: false, name: "", createdOn: null } }) => {
        let date = new Date(_certificate.course.createdOn);
        let linkedIn = {
            name: _certificate.course.name,
            organizationId: '13684488',
            issueYear: date.getFullYear(),
            issueMonth: date.getMonth()
        }

        if(_certificate.course._id === '648c27d4893d34e65002124f'){
            if(getCookie('648c27d4893d34e65002124f__feedback') === 'true'){
                return (
                    <div className='video__stats__content--card' >
                        <button onClick={()=>dispatch(popupfreeCourseFeedback())} >Show Certificate</button>
                    </div>
                )
            }
            // dispatch(popupfreeCourseFeedback())
        }

        if(_certificate._id === '' || !_certificate.course.issueCertificate ){
            return null;
        };

        return(
            <div className='video__stats__content--card'>
                <h4>
                    Certificate
                    <img src={assets + '/icons/arrow-down.png'} alt="arrow-down" />
                </h4>
                <span>
                    <div className='video__stats__content--card--content' >
                        <img src={assets + '/icons/check-box-check.png'} alt="check" />
                        <div>
                            <h5>
                            {_certificate.course.name}
                            </h5>
                            <div className='video__stats__content--card--content--certificate' >
                                <img 
                                    src={`${CONSTANTS.BaseURL}/certificate/${_certificate.course._id}/${_certificate._id}`} 
                                    className="video__stats__content--card--content--certificate--main"    
                                />
                                <div>
                                    <a 
                                        target="__blank"
                                        href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${linkedIn.name}&organizationId=${linkedIn.organizationId}&issueYear=${linkedIn.issueYear}&issueMonth=${linkedIn.issueMonth}&certUrl=${CONSTANTS.BaseURL}/certificate/${_certificate.course._id}/${_certificate._id}&certId=${_certificate._id}`}  
                                    >Add to <img src={assets + '/icons/linkedin.png' } alt="LinkedIn" />
                                    </a>
                                    <a target="__blank" 
                                        onClick={()=>certificateDownload(`${CONSTANTS.BaseURL}/certificate/${_certificate.course._id}/${_certificate._id}`)}
                                        download={'certificate'} ><img src={assets + '/icons/download.png'} alt="download" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        )
    }

    return(
        <div className='video__stats__content' id='course-content' >
            <h3>Course Content</h3>
            <div className='video__stats__content--scroll' >
                {renderContentObj()}
                {renderCertificate(props.certificate)}
            </div>
        </div>
    )
}

export default Content;