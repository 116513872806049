import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Player from './Player';
import Content from './Content';
// import Notes from './Notes';
// import Resources from './Resources';
// import Feedback from './Feedback';
import toastService from '../../toast';
import { 
    useRecordingsMutation, 
    popupFeedback, 
    setSteps, 
    toggleNavbar, 
    popupFreeProject, 
    setCourseData } from '../../store';
import { globalError, globalQueryError } from '../../utils/__errorHandling';
import Nav from './Nav';
import { getCookie, setCookie } from '../../storage';
import CONSTANTS from '../../constants';

const useQuery = () => new URLSearchParams(useLocation().search);

function Video(){
    let dispatch = useDispatch();
    // const sidebar = useSelector(state => state.navigation.sidebar);
    const { id } = useParams();
    const query = useQuery();

    const [ fetchPlaylist ] = useRecordingsMutation();
    const [_data, setData] = useState({ paylist: {}, _id: '', course: {} });

    const [fullwidth, setFullwidth] = useState(false); 
    const [width] = useState(window.innerWidth);
    const [plyrTime, setplyrTime] = useState(0);
    // const [navigation, setNavigation] = useState('resources');

    const loadData = async() => {
        try{
            let response = await fetchPlaylist(id);
            if(response['error']){
                globalError(response['error']);
                return;
            };
            if(response['data']){
                await setData({ ...response['data'] });
                let { feedbackForce, freeJourney, course } = response['data'];
                await setCookie('courseId', id);
                if(!freeJourney) await dispatch(popupFeedback(feedbackForce));
                if(freeJourney) await dispatch(popupFreeProject(freeJourney));
                await dispatch(setCourseData({ ...course, studentId: response['data']._id}));
                return;
            };
        }catch(err){
            toastService.error();
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        if(width < 770){
            setFullwidth(true);
        }else{
            setFullwidth(false);
        };
        loadData();
        dispatch(toggleNavbar(true))
    }, [width]);

    return(
        <section className='video'  >
            <div className={ !fullwidth ? 'video__player' : 'video__player video__player--full'} >
                <Player plyrTime={plyrTime} setplyrTime={setplyrTime} />
                <Nav data={_data} loadData={loadData} setplyrTime={setplyrTime}  />
            </div>
            <div className='video__stats' style={{ display: fullwidth ? 'none' : 'block' }} >
                <Content 
                    data={_data['paylist']} 
                    _id={query.get('_id') || null} 
                    course={_data['course']} certificate={ { _id: _data['_id'], course: _data['course'] } }  
                />
            </div>
        </section>
    )
};

export default Video;

    // if(error){
    //     if(error['data']){
    //         const { statusCode } = error['data'].error;
    //         globalQueryError(statusCode, error['data']);
    //     }else{
    //         toastService.error(error['error']);
    //     }
    // }

    // if(data){
    //     if(data['status'] === 'success'){
    //         _data = { ...data };
    //     }
    // };


    // if(error){
    //     if(error['data']){
    //         const { statusCode } = error['data'].error;
    //         globalQueryError(statusCode, error['data']);
    //     }else{
    //         toastService.error(error['error']);
    //     }
    // }

    // if(data){
    //     if(data['status'] === 'success'){
    //         _data = { ...data };
    //     }
    // };