import axios from "axios";

import CONSTANTS from "../constants";

const API = axios.create({
    baseURL: CONSTANTS.BaseURL,
    withCredentials: true
});

const downloadAttachment = (_key) => {
    return API.get(`/recording/attachment/download/${_key}`, {
        responseType: 'blob'
    })
};

const downloadCertificate = (_url) => {
    return API.get(_url, {
        responseType: 'blob'
    })
};

const topbarData = () => {
    return API.get('/topBar');
};

const kycFileUpload = (data) => {
    return API.post('/kyc-file', data);
};

const downloadAssignment = (_key) => {
    return API.get(`/aseessment/download/${_key}`, {
        responseType: 'blob'
    });
};

const downloadSubmission = (_key, _name) => {
    return API.get(`/aseessment/solution/download/${_key}`, {
        responseType: 'blob'
    })
};

const uploadAssignment = (_id, _file) => {
    return API.post(`/assessment/submit/${_id}`, _file);
};

const answerCheckQuiz = ({_id, _data}) => {
    return API.post(`/quiz/answer/${_id}`, _data);
};

const updateGuide = (_data) => {
    return API.post('/guide', _data);
};

const updateAnalytics = (_data) => {
    return API.post('/analytics/update', _data);
};

const videoWatchTime = (_id,_data) => {
    return API.post(`/video/watchtime/${_id}`, _data);
};

const videoWatchTimeGet = (_id) => {
    return API.get(`/video/watchtime/${_id}`);
};

const downloadResume = (_key) => {
    return API.get(`/resume/download/${_key}`, {
        responseType: 'blob'
    });
};

const uploadResume = (_file) => {
    return API.post(`/resume/upload`, _file);
};

const downloadInterviewAssets = (_key) => {
    return API.get(`/interview-assessment-download/${_key}`, {
        responseType: 'blob'
    });
};

const uploadPlacementAssignment = (_id, _file) => {
    return API.post(`/interview-assessment/upload/${_id}`, _file);
};

const downloadInterviewSolution = (_id, _file) => {
    return API.get(`/interview-assessment/download/${_id}`, _file);
};

const courseVideoWatchtime = (_id, _key, _watchtime) => {
    return API.get(`/watchtime/hls/${_id}/${_key}?watchtime=${_watchtime}`)
}

export { 
    downloadAttachment, 
    downloadCertificate, 
    topbarData,
    kycFileUpload,
    downloadAssignment,
    downloadSubmission,
    uploadAssignment,
    answerCheckQuiz,
    updateGuide,
    updateAnalytics,
    videoWatchTime,
    videoWatchTimeGet,
    downloadResume,
    uploadResume,
    downloadInterviewAssets,
    uploadPlacementAssignment,
    downloadInterviewSolution,
    courseVideoWatchtime
};