
import toastService from '../toast';
import { removeCookie } from '../storage';
import CONSTANTS from '../constants';

const globalError = (_error) => {
    let { status, data } = _error;
    if(status === 401){
        toastService.error(data['message']);
        removeCookie(CONSTANTS.authCookie);
        //SET PATH BEFORE REDIRECT
        return window.location.href = '/login';
    }else if(status === 403){
        toastService.error(data['message']);
        return window.location.href = '/payment';
    }else{
        toastService.error(data['message']);
    }
};

const globalQueryError = (_status, _error) => {
    if(_status === 401){
        toastService.error(_error['message']);
        removeCookie(CONSTANTS.authCookie);
        //SET PATH BEFORE REDIRECT
        return window.location.href = '/login';
    }else{
        toastService.error(_error['message']);
    }
}

export { globalError, globalQueryError };