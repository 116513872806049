import { createSlice } from '@reduxjs/toolkit';

import { setRedirect } from './redirectSlice';

const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        sidebar: false,
        topbar: false,
        activebar: '',
        navbar: true
    },
    reducers: {
        toggleNavigation(state, action){
            if(action.payload !== undefined) {
                let width = window.screen.width;
                state.sidebar = (action.payload) ? true : false;
                state.topbar = (action.payload) ? true : false;
                state.navbar = true;
                if(width < 770){
                    state.sidebar = false;
                }
                return state;
            }else{
                state.sidebar = !state.sidebar;
                state.topbar = !state.topbar;
                return state;
            }
        },
        toggleSidebar(state, action){
            if(action.payload !== undefined) {
                state.sidebar = (action.payload) ? true : false;
                return state;
            }
            state.sidebar = !state.sidebar;
            return state;
        },
        setActivebar(state, action){
            state.activebar = action.payload;
            return state;
        },
        toggleNavbar(state, action){
            if(action.payload !== undefined) {
                let width = window.screen.width;
                state.navbar = (action.payload) ? true : false;
                state.sidebar = (action.payload) ? true : false;
                if(width < 770){
                    state.sidebar = false;
                }
                return state;
            }else{
                state.navbar = !state.navbar;
                state.sidebar = !state.sidebar;
                return state;
            }
        }
    },
    extraReducers(builder){
        builder.addCase(setRedirect, (state)=>{
            state.sidebar = false;
            state.topbar = false;
            state.navbar = true;
        });
    }
});

export const navigationReducer = navigationSlice.reducer;
export const { toggleSidebar, toggleNavigation, setActivebar, toggleNavbar } = navigationSlice.actions;
export { navigationSlice };