import React from "react";

import { 
    setGuideModal,
    toggleRun
} from '../../store/';
import { useDispatch, useSelector } from 'react-redux';
import { setCookie } from "../../storage";
import CONSTANTS from "../../constants";

function UserGuidePopup(){
    const dispatch = useDispatch();
    const { modal } = useSelector(state=>state.guide);

    return(
        // <section className="guide__modal" style={{ display: (modal) ? 'block' : 'none' }} >
        //     <div className="guide__modal__container" >
        //         <h2>Welcome to a simple & intuitive learning experience</h2>
        //         <div className="guide__modal__container__btns" >
        //             <button onClick={()=>{
        //                 setCookie(CONSTANTS.guideKey, true);
        //                 dispatch(setGuideModal(false));
        //                 dispatch(toggleRun(false));
        //             }} >Skip for now</button>
        //             <button onClick={()=>dispatch(toggleRun(true))} >Start the tour</button>
        //         </div>
        //     </div>
        // </section>
        null
    )   
};

export default UserGuidePopup;