import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Player from './Player';
import Content from './Content';
import Nav from './Nav';
import toastService from '../../toast';
import { 
    useCoursePlaylistMutation, 
    toggleNavbar, 
} from '../../store';
import { globalError } from '../../utils/__errorHandling';

const useQuery = () => new URLSearchParams(useLocation().search);

function Course(){
    // const courseVideo = useSelector(state => state.courseVideo)
    let dispatch = useDispatch();
    const { id } = useParams();
    const query = useQuery();

    const [ fetchPlaylist ] = useCoursePlaylistMutation();
    const [_data, setData] = useState({ playlist: {}, _id: '', course: {}, completion: 0 });

    const [fullwidth, setFullwidth] = useState(false); 
    const [width] = useState(window.innerWidth);
    const [plyrTime, setplyrTime] = useState(0);
    const [playNext, setPlayNext] = useState(false);
    // const [navigation, setNavigation] = useState('resources');


    const loadData = async() => {
        try{
            let response = await fetchPlaylist(id);
            if(response['error']){
                globalError(response['error']);
                return;
            };
            if(response['data']){
               setData(response['data'])
            };
        }catch(err){
            toastService.error();
        }
    }

    useEffect(()=>{
        window.scrollTo(0,0);
        if(width < 770){
            setFullwidth(true);
        }else{
            setFullwidth(false);
        };
        loadData();
        dispatch(toggleNavbar(true))
    }, [width]);

    return(
        <section className='courseVideo'  >
            <div className={ !fullwidth ? 'courseVideo__player' : 'courseVideo__player courseVideo__player--full'} >
                <Player plyrTime={plyrTime} courseId={id} setplyrTime={setplyrTime} setPlayNext={setPlayNext} />
                <Nav loadData={loadData} course={
                    {
                        data: _data['playlist'],
                        _id: query.get('_id') || null,
                        course: _data['course'],
                        completion: _data['completion'],
                        playNext: playNext,
                        setPlayNext: setPlayNext
                    }
                } />
            </div>
            <div className='courseVideo__stats' style={{ display: fullwidth ? 'none' : 'block' }} >
                <Content 
                    data={_data['playlist']} 
                    _id={query.get('_id') || null} 
                    course={_data['course']}
                    completion={_data['completion']}
                    playNext={playNext}
                    setPlayNext={setPlayNext}
                />
            </div>
        </section>
    )
};

export default Course;

    // if(error){
    //     if(error['data']){
    //         const { statusCode } = error['data'].error;
    //         globalQueryError(statusCode, error['data']);
    //     }else{
    //         toastService.error(error['error']);
    //     }
    // }

    // if(data){
    //     if(data['status'] === 'success'){
    //         _data = { ...data };
    //     }
    // };


    // if(error){
    //     if(error['data']){
    //         const { statusCode } = error['data'].error;
    //         globalQueryError(statusCode, error['data']);
    //     }else{
    //         toastService.error(error['error']);
    //     }
    // }

    // if(data){
    //     if(data['status'] === 'success'){
    //         _data = { ...data };
    //     }
    // };