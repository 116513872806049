import { createSlice } from '@reduxjs/toolkit';

const notesSlice = createSlice({
    name: 'notes',
    initialState: [],
    reducers: {
        setNotes(state, action){
            return action.payload;
        }
    }
});


export const { setNotes } = notesSlice.actions;
export { notesSlice }