import { createSlice } from '@reduxjs/toolkit';

import { toggleNavigation } from './navigationSlice';

const loadingSlice = createSlice({
    name: 'loading',
    initialState: false,
    reducers: {
        toggleLoading(state, action){
            return action.payload ? true : false;
        }
    },
    extraReducers(builder){
        builder.addCase(toggleNavigation, (state)=>{
            state = false;
            return state;
        })
    }
});

export const loadingReducer = loadingSlice.reducer;
export const { toggleLoading } = loadingSlice.actions;