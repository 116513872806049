import React, { useEffect, useState } from 'react';
import ReactStars from 'react-stars';

import { useDispatch, useSelector } from 'react-redux';
import toastService from '../../toast';
import { popupFreeProject } from '../../store';
import { updateAnalytics } from '../../utils/__axios';

const _skills = [
    { type: 'collabration', question: 'Rate your collaboration skills' },
    { type: 'communication', question: 'Rate your communication skills' },
    { type: 'thinking', question: 'Rate your thinking skills' },
    { type: 'problemSolving', question: 'Rate your problem solving skills' }
];

function FreeCoursePopup(){
    const dispatch = useDispatch();
    const popup = useSelector( state => state.popup);
    
    const [index, setIndex] = useState(0);
    const [_skillsIndex, setSkillsIndex] = useState(0);
    const [currentCTC, setCurrentCTC] = useState('');
    const [educationBackground, setEducationBackground] = useState('');
    const [intent, setIntent] = useState([]);
    const [preferedRole, setPreferedRole] = useState('');
    const [skills, setSkills] = useState({
        collabration: null,
        communication: null,
        thinking: null,
        problemSolving: null
    });

    const loadData = async() => {
        try{
            
        }catch(err){    
            console.log({ err });
            toastService.error();
        }
    }

    useEffect(()=>{

    }, [popup.freeCourse]);

    useEffect(()=>{
        window.scrollTo(0,0);
    }, [index])

    const formSubmit = async(_index, _data) => {
        try{
            let response = await updateAnalytics(_data);
            if(response.data && response.data['status'] === 'success'){
                if(_index) setIndex(_index);
                else{
                    dispatch(popupFreeProject(false));
                }
            }else{
                toastService.error();
            }
        }catch(error){
            toastService.error(error.message);
        }
    }

    const renderOne = () => {
        return(
            <div className='popup__freecourse__container' >
                <div className='popup__freecourse__container__img' >
                    <img src='/assets/free-course/illustration-1.png' />
                </div>
                <div className='popup__freecourse__container__border' >&nbsp;</div>
                <div className='popup__freecourse__container__txt' >
                    <p className='popup__freecourse__container__txt--start' >
                        Getting Started
                    </p>
                    <h1>Begin your journey with <img src='/assets/free-course/business-toys.png' alt='business toys' /></h1>
                    <h2>Get a sneak peek of BT's unique learning experience&nbsp;<br /> 
                        <span>
                        free of cost.
                        </span>
                    </h2>
                    <h3>Our certification programs include</h3>
                    <div className='popup__freecourse__container__txt--points' >
                        <p><img src='/assets/free-course/points/1.png' alt='1' />Interactive Live Sessions</p>
                        <p><img src='/assets/free-course/points/2.png' alt='1' />Small Batch Size</p>
                        <p><img src='/assets/free-course/points/3.png' alt='1' />Endless Placement Assistance</p>
                        <p><img src='/assets/free-course/points/4.png' alt='1' />Real time Hands on Projects</p>
                        <p><img src='/assets/free-course/points/5.png' alt='1' />Resume & interview preparation</p>
                    </div>
                    <button onClick={()=>setIndex(1)} >Start your Free course</button>
                </div>
            </div>
        )
    };

    const twoSubmit = (e) => {
            e.preventDefault();

            if(educationBackground.length < 2){
                toastService.error('Enter valid Highest qualification');
                return;
            }
            
            if(currentCTC.length < 1){
                toastService.error('Enter valid CTC');
                return;
            }

            if(intent.length === 0){
                toastService.error('Select valid reasons for joining this program');
                return;
            }

            if(preferedRole.length < 2){
                toastService.error('Enter valid preffered role');
                return;
            }

            formSubmit(2, {
                currentCTC,
                educationBackground,
                intent,
                preferedRole
            })
    }

    const renderTwo = () => {
        const _intents = [
            'To become more employable and stay relevant in future but not looking for transition immediately',
            'To make switch to new role in same or another company',
            'To get promotion/salary hike',
            'To attain further higher education after BT program',
            'To get certificate',
            'To start freelancing/ coaching'
        ];

        const renderIntent = () => _intents.map((e, i)=>(
            <p 
                key={i}
                onClick={
                    ()=>setIntent((state)=>{
                        console.log({ state })
                        const intentIndex = state.indexOf(e);
                        if (intentIndex > -1) {
                            // Element exists in the array, so remove it
                            state.splice(intentIndex, 1);
                        } else {
                            // Element doesn't exist in the array, so push it
                            state.push(e);
                        };
                        return state;
                    })
                } 
            >
                {(intent.indexOf(e) === -1) ? 
                    <img src='/assets/free-course/check-empty.png' />
                    :
                    <img src='/assets/free-course/check-fill.png' />
                }
                {e}
            </p>
        ));

        return(
            <div className='popup__freecourse__container popup__freecourse__container--form' >
                <form onSubmit={twoSubmit} >
                    <div className='popup__freecourse__container__input' >
                        <label>1. What is your Highest qualification ?</label>
                        <input type='text' value={educationBackground} 
                            onChange={(e)=>setEducationBackground(e.target.value)}
                            name='Qualification' placeholder='Ex  B Tech' />
                    </div>
                    <div className='popup__freecourse__container__input' >
                        <label>2. What is your current/ Last CTC ?</label>
                        <input type='text' value={currentCTC} onChange={(e)=>setCurrentCTC(e.target.value)} 
                            name='CTC' placeholder='Ex  6LPA' />
                    </div>
                    <div className='popup__freecourse__container__input__options' >
                        <label>3. What's your Primary reason for joining this program ?</label>
                        <div>
                            {renderIntent()}
                        </div>
                    </div>
                    <div className='popup__freecourse__container__input' >
                        <label>4. What is your preferred role ?</label>
                        <input type='text' value={preferedRole} onChange={(e)=>setPreferedRole(e.target.value)} 
                            name='Prefered Role' placeholder='Ex  Data Scientist' />
                    </div>
                    <button className='popup__freecourse__container__input--btn' >Next <img src='/assets/free-course/arrow.png' alt='arrow' /></button>
                </form>
            </div>
        )
    };

    const threeSubmit = () => {
        if(skills[_skills[_skillsIndex].type] === null){
            toastService.error('Select valid rating');
            return;
        }

        if(_skills.length - 1 === _skillsIndex){
            formSubmit(3, {skills: skills});
            return;
        };
        
        setSkillsIndex((state) => {
            toastService.info(`Lets know about ${_skills[state + 1].type}`)
            return state+1;
        });
    }

    const renderThree = () => {
        return(
            <section className='popup__freecourse__container popup__freecourse__container--drive' >
                <p className='popup__freecourse__container__txt--start' > Professional Deep Dive</p>
                <h1>Rate your Skills (This will help us to customize your learning path)</h1>
                <div className='popup__freecourse__container--drive__question' >
                    <h3>
                        {_skills[_skillsIndex].question}
                    </h3>
                    <div className='popup__freecourse__container--drive__question--container' >
                        <div  
                            className={`${
                                (skills[_skills[_skillsIndex].type] === 0)
                                ? 'popup__freecourse__container--drive__question--container__active'
                                : ''
                            }`}
                            onClick={()=>{
                                    setSkills((state)=>{
                                        return {...state, [_skills[_skillsIndex].type]: 0}
                                    });
                                }} 
                        >
                            <img src='/assets/free-course/options/1.gif' alt='gif' />
                            <p>Poor</p>
                        </div>
                        <div
                            className={`${
                                (skills[_skills[_skillsIndex].type] === 1)
                                ? 'popup__freecourse__container--drive__question--container__active'
                                : ''
                            }`}  
                        
                            onClick={()=>{
                                    setSkills((state)=>{
                                        return {...state, [_skills[_skillsIndex].type]: 1}
                                    });
                                }}   >
                            <img src='/assets/free-course/options/2.gif' alt='gif' />
                            <p>Basic</p>
                        </div>
                        <div
                            className={`${
                                (skills[_skills[_skillsIndex].type] === 2)
                                ? 'popup__freecourse__container--drive__question--container__active'
                                : ''
                            }`} 
                            onClick={()=>{
                                    setSkills((state)=>{
                                        return {...state, [_skills[_skillsIndex].type]: 2}
                                    });
                                }}  >
                            <img src='/assets/free-course/options/3.gif' alt='gif' />
                            <p>Intermediate</p>
                        </div>
                        <div
                            className={`${
                                (skills[_skills[_skillsIndex].type] === 3)
                                ? 'popup__freecourse__container--drive__question--container__active'
                                : ''
                            }`} 
                        
                            onClick={()=>{
                                    setSkills((state)=>{
                                        return {...state, [_skills[_skillsIndex].type]: 3}
                                    });
                                }}  >
                            <img src='/assets/free-course/options/4.gif' alt='gif' />
                            <p>Advanced</p>
                        </div>
                    </div>
                    <button onClick={threeSubmit} >Next <img src='/assets/free-course/arrow.png' alt='arrow' /></button>
            </div>
            </section>
        )
    };

    const fourSubmit = () => {
        formSubmit(null, {
            freeJourneyComplete: true
        })
    };

    const renderFour = () => {
        return(
            <div className='popup__freecourse__container popup__freecourse__container--platform' >
                <p className='popup__freecourse__container__txt--start' > Learning Experience & Platform</p>
                <div className='popup__freecourse__container--platform__content' >
                    <h4>Introduction to Business Toys</h4>
                    <p>
                        Business Toys is a leading edtech in online Data science and Analytics certifications, 
                        building the careers of tomorrow by offering cutting-edge industry-relevant programs in 
                        association with Microsoft, IBM, NASSCOM, iimjobs. Business Toys offers programs in 
                        Data Science, Machine Learning and AI, Engineering, Product Management ,Technology, 
                        Business Management and more.
                    </p>
                </div>
                <div className='popup__freecourse__container--platform__content' >
                    <h4>Digital-First Learning</h4>
                    <p>
                        We have worked with leading academicians, pedagogy experts and education technology 
                        professionals to create the most engaging learning environment for you. 
                        This platform is designed in a way that you can access the learning experience any 
                        time at your convenience.
                    </p>
                </div>
                <div className='popup__freecourse__container--platform__content' >
                    <p>
                        In this course on Amazon India- Employee Performance Prediction you will be learning Data Analysis framework and 
                        how to do predictive analytics using Excel from the basics.
                    </p>
                    <p>Resources available post-completion of this course</p>
                    <p>Post this course, you can choose to avail the following services by Business Toys  if you would like:</p>
                    <p>1. Speak to one of our career counselors regarding your needs and our programs offerings </p>
                    <p>2.Try out a few of our free courses to enhance your skills. Our free courses offer certifications as well. </p>
                    <p>
                        3. If there is one good thing that the COVID-19 situation has shown us more vividly,
                        it's to rely on data more. To get the most out of the generated data, organizations need to spend more on innovations, 
                        problem-solving approaches, 
                        and employees' upskilling. Hence you can't escape data science upskilling.
                    </p>
                    <p>
                        Business Toys  has an unparalleled collection of data science courses with varying prices 
                        and duration. Check it out today!
                    </p>
                    <p>
                        Business Analytics Certification Program 
                    </p>
                    <p>
                        Post Graduate Programme in Business Analytics | <img src='/assets/free-course/iim.png' alt='iim' />
                    </p>
                    <p>
                        Data Scientist Certification Programme | <img className='popup__freecourse__container--platform__content--nasscom' src='/assets/free-course/nasscom.png' alt='nasscom' /> | <img src='/assets/free-course/microsoft.png' alt='microsoft' /> | <img src='/assets/free-course/iim.png' alt='iim' />
                    </p>
                    <p>
                        Certification in Data Science and Artificial Intelligence | <img className='popup__freecourse__container--platform__content--nasscom' src='/assets/free-course/nasscom.png' alt='nasscom' /> | <img src='/assets/free-course/microsoft.png' alt='microsoft' /> | <img src='/assets/free-course/iim.png' alt='iim' />
                    </p>
                    <p>
                        Full Stack Data Science Professional Programme | <img src='/assets/free-course/microsoft.png' alt='microsoft' /> | <img src='/assets/free-course/iim.png' alt='iim' />
                    </p>
                    <div className='popup__freecourse__container--platform__content--sticky' >
                        <button onClick={fourSubmit} >Start My Free course</button>
                    </div>
                </div>
            </div>
        )
    };

    return(
        <section className='popup__freecourse' style={{ display: (popup.freeCourse) ? 'block' : 'none' }}  >
            {(index === 0) && renderOne()}
            {(index === 1) && renderTwo()}
            {(index === 2) && renderThree()}
            {(index === 3) && renderFour()}
        </section>
    )
};

export default FreeCoursePopup;