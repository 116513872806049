import React, { useEffect, useState } from "react";
import toastService from "../../../toast";
import { useInterviewPrepTopicsCasesMutation } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";

function InterviewPreparationList(){
    const navigate = useNavigate();
    const [ topicDetails ] = useInterviewPrepTopicsCasesMutation();
    const { slug } = useParams();
    const [list, setList] = useState([]);

    const loadData = async(_slug) => {
        try{
            let response = await topicDetails(_slug);
            if(response && response.data && response.data.status === 'success'){
                setList(response.data.data);
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    }

    useEffect(()=>{
        loadData(slug);
    },[])

    return(
        <section className="placement__interview__list" >
            <h1>{list[0] && list[0].topic}</h1>
            <div className="placement__interview__list__container" >
                {
                    list.map((e)=>(
                        <div key={e._id} className="placement__interview__list__container__card" >
                            <div className="placement__interview__list__container__card__title" >
                                <img src={e.logoUrl} alt={e.topic} />
                                <h3>{e.title}</h3>
                            </div>
                            <div className="placement__interview__list__container__card__stats"  >
                                <h5 className="placement__interview__list__container__card__stats--basic" >{e.difficulty}</h5>
                                <p> <img src="/assets/placement/attend.png" alt="attend" /> Attended : {e.attend}</p>
                                <p> <img src="/assets/placement/success.png" alt="success" /> Success : {e.success}% </p>
                                <p> <img src="/assets/placement/duration.png" alt="duration" /> Duration : {e.duration} hours </p>
                                <button 
                                    onClick={()=>navigate(`/placement/interview-preparation-detail/${e.slug}`)} >
                                        Take Assessment
                                </button>
                            </div>
                        </div>
                    ))
                }
            </div>
        </section>
    )
};

export default InterviewPreparationList;