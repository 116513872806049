import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useInterviewPrepTopicsMutation } from "../../../store";
import toastService from "../../../toast";

function InterviewPreparation(){
    const navigate = useNavigate();
    const [interviewTopics] = useInterviewPrepTopicsMutation();
    const [list, setList] = useState([]);

    const loadData = async() => {
        try{
            let response = await interviewTopics();
            if(response && response.data && response.data.status === 'success'){
                setList(response.data.data);
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    };

    useEffect(()=>{
        loadData();
    },[])
    
    return(
        <section className="placement__interview" >
            <h1>Prepare by Topics/Tools</h1>
            <div className="placement__interview__container" >
                { list.map((e, i)=>(
                    <Link key={i} to={`/placement/interview-preparation/${e.topic}`} className="placement__interview__container__card" >
                        <img src={e.logoUrl} alt={e.topic}  />
                        <h3>{e.topic}</h3>
                        {/* <div className="placement__interview__container__card__status" >
                            <span style={{ width: `${e.complete}%` }} ></span>
                        </div>
                        <p>{e.complete}% Completed</p> */}
                        <button>Start Now</button>
                    </Link>
                )) }
            </div>
        </section>
    )
};

export default InterviewPreparation;

/*
const list = [
        {
            title: 'Statistics using Excel',
            img: '/assets/placement/interview-prepration/excel.png',
            slug: 'excel',
            complete: 45
        },
        {
            title: 'SQL',
            img: '/assets/placement/interview-prepration/sql.png',
            slug: 'sql',
            complete: 0
        },
        {
            title: 'Tableau',
            img: '/assets/placement/interview-prepration/tableau.png',
            slug: 'tableau',
            complete: 0
        },
        {
            title: 'Python',
            img: '/assets/placement/interview-prepration/python.png',
            slug: 'python',
            complete: 0
        },
        {
            title: 'Machine Learning',
            img: '/assets/placement/interview-prepration/machine-learning.png',
            slug: 'python',
            complete: 0
        },
        {
            title: 'Deep Learning',
            img: '/assets/placement/interview-prepration/deep-learning.png',
            slug: 'python',
            complete: 0
        },
    ]

*/