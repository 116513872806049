import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { usePlacementProcessAccessMutation } from "../../store";
import toastService from "../../toast";
import { useSelector } from "react-redux";

function Placement(){
    const [ placementProcessAccess ] = usePlacementProcessAccessMutation();
    const sidebar = useSelector(state => state.navigation.sidebar);

    const navigate = useNavigate();
    const [accessList, setAccessList] = useState({
        resume: false,
        interviewPrep: false, 
        mockInterview: false, 
        jobPortal: false
    });
    
    const loadData = async() => {
        try{
            let response = await placementProcessAccess();
            if(response && response.data && response.data['status'] === 'success'){
                let data = response.data.data;
                let enable = response.data.enable;
                if(enable){
                    let blockShow = true;
                    for(let key in data){
                        if(data[key]) blockShow = false;
                    }
                    if(blockShow) toastService.error("Access the placement process upon reaching 80% completion of your course.")
                }else{
                    toastService.error("Your course doesn't support Placement Process, Upgrade your course");
                }
                setAccessList(data);
            }else{
                toastService.error();
            }
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    };

    useEffect(()=>{
        loadData();
    },[]);

    const onCardClick = ({ _url = '#', _access = false, _prev = null }) => {
        if(_access){
            navigate(_url);
        }else{
            if(_prev) toastService.error(`Complete ${_prev} to unlock this section`);
            else toastService.error("Access the placement process upon reaching 80% completion of your course.");
        }
    }

    return(
        <section className="placement__route"  >
            <h1>Placement Program</h1>
            <div className="placement__route__container" >
                <div onClick={()=>onCardClick({ 
                    _url: '/placement/resume',  
                    _access: accessList['resume'], 
                    _prev: null })} 
                    className="placement__route__container__card" >
                { (!accessList['resume']) && <img className="placement__route__container__card--lock" src="/assets/placement/lock.png" />}
                    <img className="placement__route__container__card--main" src="/assets/placement/resume-building.png" alt="resume building" />
                    <h3>Resume Building</h3>
                    <div className="placement__route__container__card--tooltip" >
                        <p>
                            Craft an impressive and professional resume that showcases your 
                            skills and accomplishments.
                        </p>
                    </div>
                </div>
                <div 
                    onClick={()=>onCardClick({ 
                        _url: '/placement/interview-preparation',  
                        _access: accessList['interviewPrep'], 
                        _prev: 'Resume Building' })} 
                    className="placement__route__container__card" >
                    { (!accessList['interviewPrep']) && <img className="placement__route__container__card--lock" src="/assets/placement/lock.png" />}
                    <img className="placement__route__container__card--main" src="/assets/placement/interview-preparation.png" alt="Interview Preparation" />
                    <h3>Interview Preparation</h3>
                    <div className="placement__route__container__card--tooltip" >
                        <p>
                            Prepare yourself for data science job interviews 
                            with our Interview Preparation section.
                        </p>
                    </div>
                </div>
                <div
                    onClick={()=>onCardClick({ 
                        _url: '/placement/mock-interview',  
                        _access: accessList['mockInterview'], 
                        _prev: 'Interview Preparation' })} 
                    className="placement__route__container__card" >
                    { (!accessList['mockInterview']) && <img className="placement__route__container__card--lock" src="/assets/placement/lock.png" />}
                    <img className="placement__route__container__card--main" src="/assets/placement/mock-interviews.png" alt="Mock Interviews" />
                    <h3>Mock Interviews</h3>
                    <div className="placement__route__container__card--tooltip" >
                        <p>
                            Put your skills to the test through our Mock Interviews section. 
                            Simulate real interview scenarios with industry professionals 
                            who provide insightful evaluations and feedback.
                        </p>
                    </div>
                </div>
                <div 
                    onClick={()=>onCardClick({ 
                        _url: '/placement/job-portal',  
                        _access: accessList['mockInterview'], 
                        _prev: 'Mock Interview' })} 
                    className="placement__route__container__card" >
                    { (!accessList['jobPortal']) && <img className="placement__route__container__card--lock" src="/assets/placement/lock.png" />}
                    <img className="placement__route__container__card--main" src="/assets/placement/job-portal.png" alt="Job Portal" />
                    <h3>Job Portal</h3>
                    <div className="placement__route__container__card--tooltip placement__route__container__card--tooltip--bottom" >
                        <p>
                            Explore our Job Portals section, where you can access customized job recommendations
                            tailored to your skills and preferences. Discover a range of job opportunities 
                            in the data science field, browse through relevant listings, and take the 
                            next step towards your desired career.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Placement;