import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CONSTANTS from "../../constants";
import { toggleNavigation, useOnBoardMutation, useCheckOnBoardMutation } from "../../store";
import toastService from '../../toast';
import { globalError } from "../../utils/__errorHandling";

let assets = CONSTANTS.assets;

function OnBoard(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ onBoard ] = useOnBoardMutation();
    const [checkOnBoard] = useCheckOnBoardMutation();

    const [questions, changeQuestion] = useState(
        [
            {
                question: 'What is your Education Background?',
                options: [ 'BE/B Tech', 'B Com/M Com', 'MBA', 'BCA', 'BBA', 'M Tech/ME', 'Others' ],
                selected: ''
            },
            {
                question: 'What is your dream job profile?',
                options: [ 'Data Analyst', 'Data Scientist', 'Full Stack Data Scientist', 'Business Analyst', 'Others' ],
                selected: ''
            },
            {
                question: 'What is source of income of your Guardian?',
                options: [ 'Salaried', 'Business', 'Others' ],
                selected: ''
            },
            {
                question: 'What is your expected salary CTC?',
                options: [ '0 to 3L', '3L to 6L', '6L to 8L', 'More than 8L' ],
                selected: ''
            },
            {
                question: 'What are you looking forward to ?',
                options: [ 'Secure a new job/Internship', 'Learn new skills', 'Gain Industry Experience', 'Growth in the current job' ],
                selected: ''
            }
        ]
    );

    const [ index, changeIndex ] = useState(0);

    const checkRecord = async() => {
        try{
            let response = await checkOnBoard();
            const { status, exists } = response.data;
            if(status === 'success'){
                if(exists){
                    navigate('/');
                }else{
                    return;
                }
            }
        }catch(err){
            console.log({ err })
            toastService.error();
        }
    }

    useEffect(()=>{
        dispatch(toggleNavigation(false));
        checkRecord();
    }, [dispatch]);

    const optionClick = (e) => {
        let question = questions;
        questions[index].selected = e;
        changeQuestion([...question]);
    };

    const nextClick = async() => {
        try{
            window.scrollTo(0,0);
            if(index === questions.length - 1){
                let response = await onBoard({
                    educationBackground: questions[0].selected,
                    dreamJobProfile: questions[1].selected,
                    guardianIncomeSource: questions[2].selected,
                    expectedSalary: questions[3].selected,
                    lookingForwardTo: questions[4].selected
                });

                if(response['error']){
                    globalError(response['error']);
                    return;
                };

                if(response['data']){
                    if(response['data'].status === 'success'){
                        await dispatch(toggleNavigation(true));
                        navigate('/');
                    }else{
                        toastService.error();
                    }
                    return;
                };
                return;
            };
            if(questions[index].selected === ''){
                toastService.error('Select valid answer!');
                return;
            }
            changeIndex(index + 1);
        }catch(err){
            toastService.error();
        }
    };

    const renderQuestion = () => {
        let data = questions[index];
        return (
            <React.Fragment>
                <h3>{data.question}</h3>
                <div className="onboard__container__txt__options" >
                    { 
                        data.options.map((e)=>(
                            <>
                                <div 
                                    key={e}
                                    onClick={()=>optionClick(e)}
                                    className={ (e === data.selected) ? "onboard--active": null} >
                                    {e}
                                </div>
                            </>
                        )) 
                    }
                    {
                        index === 4 && (
                        <span className="onboard__container__txt__options--check" >
                            I agree to the <a href="https://businesstoys.in/privacy-policy/" target="__blank" >Terms and Conditions</a>&nbsp;
                            and have read and understood the &nbsp;
                            <a href="https://businesstoys.in/privacy-policy/#:~:text=on%20this%20page.-,Refund%20%26%20Cancellation%20Policy,-Thank%20you%20for" target="__blank" >Refund Policy</a>.&nbsp;
                            I give my explicit consent to the terms outlined and acknowledge my rights and 
                            obligations under the refund policy. I understand that these terms govern my use of &nbsp;
                            <a href="https://businesstoys.in/" target="__blank" >businesstoys</a> and any transactions made on this platform.
                        </span>
                        )
                    }
                </div>
            </React.Fragment>
        )
    };

    return (
        <section className="onboard" >
            <div className="onboard__container" >
                <div className="onboard__container__txt" >
                    { (index === 0) && <h1>Welcome to Business Toys</h1>}
                    {renderQuestion()}
                    <button className="btn" onClick={nextClick} >
                        { (index === (questions.length - 1)) ? 'Submit' : 'Next' }
                    </button>
                </div>
                <div className="onboard__container__img" >
                    <img src={assets + '/onboard/' + (index + 1) +'.png' } alt="onboard-img" />
                </div>
            </div>
            <div className="onboard__sub" >
                <div className={ (index === 0) ? 
                        "onboard__sub--img onboard__sub--active": 
                        "onboard__sub--img"} >
                    <img src={assets + '/icons/note.png'} alt="note" />
                </div>
                <div className="onboard__sub--line" >
                    <img src={assets + '/icons/line.png'} alt="line" />
                </div>
                <div className={ (index === 1) ? 
                    "onboard__sub--img onboard__sub--active":
                    "onboard__sub--img"} >
                    <img src={assets + '/icons/breifcase.png'} alt="breifcase" />
                </div>
                <div className="onboard__sub--line" >
                    <img src={assets + '/icons/line.png'} alt="line" />
                </div>
                <div className={ (index === 2) ? 
                    "onboard__sub--img onboard__sub--active":
                    "onboard__sub--img"} >
                    <img src={assets + '/icons/wallet.png'} alt="wallet" />
                </div>
                <div className="onboard__sub--line" >
                    <img src={assets + '/icons/line.png'} alt="line" />
                </div>
                <div className={ (index === 3) ? 
                    "onboard__sub--img onboard__sub--active":
                    "onboard__sub--img"} >
                    <img src={assets + '/icons/money.png'} alt="wallet" />
                </div>
                <div className="onboard__sub--line" >
                    <img src={assets + '/icons/line.png'} alt="line" />
                </div>
                <div className={ ( index === 4) ? 
                    "onboard__sub--img onboard__sub--active":
                    "onboard__sub--img"} >
                    <img src={assets + '/icons/forward.png'} alt="wallet" />
                </div>
            </div>
        </section>
    )
};

export default OnBoard;