/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import CONSTANTS from "../../constants";

const assets = CONSTANTS.assets;

export default function CourseQuiz({ data, setPlayNext }) {
    const [status, setStatus] = useState('start');
    const [questionIndex, setQuestionIndex] = useState(0);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
    const [isAnswerChecked, setIsAnswerChecked] = useState(false);
    const [correctCount, setCorrectCount] = useState(0);
    
    const handleStartClick = () => setStatus('quiz');

    const handleOptionClick = useCallback((index) => {
        if (!isAnswerChecked) {
            setSelectedOptionIndex(index);
        }
    }, [isAnswerChecked]);

    const handleCheckAnswer = useCallback(() => {
        setIsAnswerChecked(true);
    }, [selectedOptionIndex]);

    useEffect(() => {
        setStatus('start')
        setQuestionIndex(0)
        setSelectedOptionIndex(null)
        setIsAnswerChecked(false)
        setCorrectCount(0)
    }, [data?.quiz])

    useEffect(() => {
        if (isAnswerChecked) {
            const correctAnswer = data?.quiz[questionIndex]?.answer;
            const selectedAnswer = data?.quiz[questionIndex]?.options[selectedOptionIndex];

            if (correctAnswer === selectedAnswer) {
                setCorrectCount((count) => count + 1);
            }
        }
    }, [isAnswerChecked, data?.quiz, questionIndex, selectedOptionIndex]);

    const handleNextClick = useCallback(() => {
        if (questionIndex < data?.quiz?.length - 1) {
            setQuestionIndex((index) => index + 1);
        } else {
            setStatus('end');
            setQuestionIndex(0);
        }
        setIsAnswerChecked(false);
        setSelectedOptionIndex(null);
    }, [data?.quiz?.length, questionIndex]);

    const getOptionClassName = (index) => {
        let className = "course__quiz__question__options";
        if (isAnswerChecked) {
            const correctAnswer = data?.quiz[questionIndex]?.answer;
            if (index === selectedOptionIndex) {
                className += correctAnswer === data?.quiz[questionIndex]?.options[index] 
                    ? " course__quiz__question__options__correct"
                    : " course__quiz__question__options__wrong";
            }
        } else if (index === selectedOptionIndex) {
            className += " course__quiz__question__options__select";
        }
        return className;
    };

    const renderStart = () => (
        <div className="course__quiz__start">
            <img src={assets + '/video/quiz-logo.png'} alt="logo" />
            <h1>"Ready to put your skills to the test?" <br /> Welcome to our quiz!</h1>
            <p>This quiz will have {data?.quiz?.length} questions <br /> Complete this quiz to unlock the next lesson</p>
            <button onClick={handleStartClick}>Start Quiz</button>
        </div>
    );

    const renderStartAssessment = () => (
        <div className="course__quiz__assessment">
            <div className="course__quiz__assessment__start" >
                <h1>Assessment</h1>
                <h4>
                    {data?.subtitle}
                </h4>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.07996 3.05225C5.24599 3.05225 2.13794 6.16029 2.13794 9.99426C2.13794 13.8282 5.24599 16.9363 9.07996 16.9363C12.9139 16.9363 16.022 13.8282 16.022 9.99426C16.022 6.16029 12.9139 3.05225 9.07996 3.05225ZM1.01221 9.99426C1.01221 5.53857 4.62426 1.92651 9.07996 1.92651C13.5356 1.92651 17.1477 5.53857 17.1477 9.99426C17.1477 14.45 13.5356 18.062 9.07996 18.062C4.62426 18.062 1.01221 14.45 1.01221 9.99426Z" fill="#5E6779"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.07996 8.68066C9.39082 8.68066 9.64282 8.93267 9.64282 9.24353V13.7465C9.64282 14.0573 9.39082 14.3093 9.07996 14.3093C8.76909 14.3093 8.51709 14.0573 8.51709 13.7465V9.24353C8.51709 8.93267 8.76909 8.68066 9.07996 8.68066Z" fill="#5E6779"/>
                    <path d="M9.08008 6.2417C9.49456 6.2417 9.83057 6.5777 9.83057 6.99219C9.83057 7.40667 9.49456 7.74268 9.08008 7.74268C8.66559 7.74268 8.32959 7.40667 8.32959 6.99219C8.32959 6.5777 8.66559 6.2417 9.08008 6.2417Z" fill="#5E6779"/>
                </svg>
                <p>
                    <span>
                        Download data from <b>Resources</b> section below
                    </span>
                    NB: You can only answer the quiz questions after you've completed the following question
                </p>
                <button onClick={handleStartClick} className="course__quiz__assessment__start__btn" >Start Quiz</button>
            </div>
            <div className="course__quiz__assessment__questions" >
                {
                    data?.assessmentQuestions?.map((question, index) => (
                        <div key={index} >
                            <p>Q{index + 1}. {question}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )

    const renderQuestions = () => (
        <div className="course__quiz__question">
            <h4>QUESTION {questionIndex + 1} OF {data?.quiz?.length}</h4>
            <h5>{data?.quiz[questionIndex]?.question}</h5>
            {data?.quiz[questionIndex]?.options.map((option, index) => (
                <div 
                    className={getOptionClassName(index)}
                    key={index}
                    onClick={() => handleOptionClick(index)}
                >
                    {option}
                </div>
            ))}
            {!isAnswerChecked && <button onClick={handleCheckAnswer}>Check Answer</button>}
            {isAnswerChecked && <button onClick={handleNextClick}>Next</button>}
        </div>
    );

    const renderEnd = () => (
        <div className="course__quiz__end">
            <h1>{correctCount === data?.quiz?.length ? 'Perfect Score!' : correctCount > 0 ? 'Great Job!' : 'Try Again'}</h1>
            <p>You scored {correctCount} out of {data?.quiz?.length}</p>
            <div className="course__quiz__end__btn" >
                <button onClick={() => {
                    setStatus('start');
                    setCorrectCount(0);
                }}>Try Again</button>
                <button onClick={() => setPlayNext(true) } >Next</button>
            </div>
        </div>
    );
    
    console.log({ data })

    return (
        <section className={`course__quiz ${data?.uiType === 'ASSESSMENT' && 'course__quiz--assessment'}`}>
            {(status === 'start' && data?.uiType !== 'ASSESSMENT') && renderStart()}
            {(status === 'start' && data?.uiType === 'ASSESSMENT') && renderStartAssessment()}
            {status === 'quiz' && renderQuestions()}
            {status === 'end' && renderEnd()}
        </section>
    );
}
