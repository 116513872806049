import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { toggleNavbar } from '../../store';
import { CircularProgressbar } from "react-circular-progressbar";

import { useQuizResultCheckMutation } from '../../store';
import toastService from "../../toast";
import { analyticsEvent } from "../../firebase";

function QuizEnd(){
    let params = useParams();
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const [fetchQuizResult] = useQuizResultCheckMutation();
    const [percentage, setPercentage] = useState(0);
    const [data, setData] = useState({ correct: 0, content: [], quiz: { tag: '' } });

    const loadData = async() => {
        try{
            let response = await fetchQuizResult(params['slug']);
            if(response.data && response.data.status === 'success'){
                if(response.data.data){
                    await setData(response.data.data);
                    let _data = response.data.data;
                    let percentage = Math.round((_data.correct / _data.content.length) * 100)
                    await setPercentage((percentage > 100) ? 100 : percentage);
                }else{
                    toastService.error('No Quiz Found');
                    navigate('/')

                }
            };
        }catch(err){
            console.log({ err });
            toastService.error();
        }
    };

    useEffect(()=>{
        window.scrollTo(0,0);
        loadData();
        analyticsEvent({
            _event: 'quiz_result_view',
            _type: 'view',
            _custom: { quizName: params['slug'] }
        });
    }, [])

    useEffect(()=>{
        window.scrollTo(0,0);
        dispatch(toggleNavbar(false));
    }, [dispatch]);

    const renderGIPHY = () => {
        let url;
        if(percentage >= 50 && percentage < 75 ){
            url = 'https://giphy.com/embed/9J92ARAauOQfdDoKlC';
        }
        if(percentage >= 75){
            url = 'https://giphy.com/embed/kHsExpA4CL8OnrzO6P';
        }
        if(percentage < 50){
            url = 'https://giphy.com/embed/Khi4ihXtrDpfnlZIXh';
        }
        return (
            <iframe src={url} 
                style={{
                    width: '100%',
                    objectFit: 'contain',
                    height: '100%',
                    position: 'absolute'
                }} 
                frameBorder={0}
                className="giphy-embed">
            </iframe>
        )
    }

    return(
        <section className="quiz" >
            <div className="quiz__end" >
                <div className="quiz__end__txt" >
                    <h1>"Great job on our {`${params['slug'].split('-').join(' ')}`.toLocaleUpperCase()} quiz!, Check out your results below."</h1>
                    <div style={{ width: 200, height: 200 }} className="quiz__end__txt--circle" >
                        <CircularProgressbar value={percentage}
                            text={`${percentage}%`}
                            styles={{
                                root: {},
                                path: {
                                stroke: '#2A70FE',
                                strokeLinecap: 'butt',
                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                transform: 'rotate(0turn)',
                                transformOrigin: 'center center',
                                },
                                trail: {
                                stroke: `rgba(35, 77, 163, ${ 90 / 100})`,
                                strokeWidth: 8,
                                strokeLinecap: 'round',
                                transform: 'rotate(0.25turn)',
                                transformOrigin: 'center center',
                                },
                            text: {
                                    fill: '#fff',
                                    fontSize: '16px',
                                },
                            }}
                        />
                    </div>
                    <p>
                        You scored {(data.correct > data.content.length) ? data.content.length : data.correct} 
                        &nbsp;out of {data.content.length}</p>
                    <div className="quiz__end__txt__btn" >
                        <Link to={`/quiz/topic/${data.quiz.tag}`} >Check Out More</Link>
                        <Link to="/" >Home</Link>
                    </div>
                </div>
                <div className="quiz__end__media" onClick={(e)=>e.preventDefault()} >
                    {renderGIPHY()}
                </div>
            </div>
        </section>
    )
};

export default QuizEnd;