/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCourseVideo } from '../../store';
import CONSTANTS from '../../constants';
import toastService from '../../toast';
import { useCourseProgressMutation } from '../../store/apis/api';
import { useParams } from 'react-router-dom';

const assets = CONSTANTS.assets;

function Content(props){
    const dispatch = useDispatch();
    const [fetchCourseProgress] = useCourseProgressMutation();
    const [openItems, setOpenItems] = useState({});
    const courseVideo = useSelector(state => state.courseVideo)
    const timeStamp = useSelector(state => state.timestamp )
    const params = useParams()
    const [progress, setProgress] = useState(0)

    const playNextVideo = async() => {
        window.scrollTo(0,0);
        const values = Object.values(props.data)
        const mainArr = values.flat(1)
        const currentVideoIndex = mainArr.findIndex(video => video._id === courseVideo._id)
        if (currentVideoIndex !== -1 && currentVideoIndex < mainArr.length - 1) {
            const nextVideo = mainArr[currentVideoIndex + 1]
            await dispatch(
                setCourseVideo({
                    _id: nextVideo._id,
                    title: nextVideo.title,
                    module: nextVideo.module,
                    type: nextVideo.type,
                    quiz: nextVideo.container || [],
                    banner: nextVideo.banner
                })
            )
        }
        props.setPlayNext(false)
    };

    useEffect(()=>{
        if (props.playNext) {
            playNextVideo()
        }
        
        const values = Object.values(props.data)
        const mainArr = values.flat(1)
        const currentVideoIndex =  mainArr.findIndex(video => video._id === courseVideo._id)

        if (!courseVideo?._id || currentVideoIndex === -1) {
            const [intro] = Object.entries(props['data'])
            console.log({ data: props.data, intro })
            if (intro) {
                onTopicClick({
                    _title: intro[1][0].title,
                    _id: intro[1][0]._id,
                    _module: intro[0],
                    banner: intro[1][0].banner,
                    _type: 'VIDEO'
                })
            }
        }
    }, [props]);

    const getCourseProgress = async () => {
        try {
            const response = await fetchCourseProgress(params.id)
            if (response && response.data) {
                setProgress(response.data.completion)
            } else {
                toastService.error();
            }
        } catch (error) {
            toastService.error()
        }
    }

    useEffect(() => {
        getCourseProgress()
        setOpenItems(state => ({
            ...state, [courseVideo.module]: true
        }))
    }, [courseVideo])

    const toggleItem = (key) => {
        setOpenItems((prevState) => ({
          ...prevState,
          [key]: !prevState[key],
        }));
    };
  
    const onTopicClick = ({
        _title,
        _id,
        _module,
        _type = 'VIDEO',
        _quiz = [],
        banner = null,
        uiType = '',
        subtitle = '',
        assessmentQuestions = []
    }) => {
        window.scrollTo(0,0);
        dispatch(setCourseVideo({
            _id: _id,
            title: _title,
            module: _module,
            type: _type,
            quiz: _quiz,
            banner,
            uiType,
            subtitle,
            assessmentQuestions
        }));
    };

    function formatTime(seconds) {
        seconds = Math.floor(seconds)
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = secs.toString().padStart(2, '0');
        return hours > 0 
        ? `${formattedHours}:${formattedMinutes}:${formattedSeconds} hrs`
        : `${formattedMinutes}:${formattedSeconds} min`;
    }

    const renderContentObj = () => (
        Object.entries(props['data']).map(([key, value])=>{
            return(
                <div key={key} 
                    className={`courseVideo__stats__content--card 
                    ${courseVideo.module === key && 'courseVideo__stats__content--card--active'}`} >
                    <h4 onClick={() => toggleItem(key)} >
                        {key}
                        <img src={assets + '/icons/arrow-down.png'} alt="arrow-down" />
                    </h4>
                    {openItems[key] && (
                    <span>
                        {value.map((e)=>(
                            (e.type === 'VIDEO')
                            ? <div key={e._id} onClick={() => onTopicClick({ 
                                _id: e._id,
                                _title: e.title,
                                _module: key,
                                banner: e.banner || null
                            }) } 
                                className={`courseVideo__stats__content--card--content
                                    ${courseVideo._id === e._id && 'courseVideo__stats__content--card--content--active' }`} >
                                <div>
                                    <h5>
                                        {courseVideo._id === e._id 
                                            ? <img src={assets + '/video/blue-dot.png'} alt='blue-dot' />
                                            : <img src={assets + '/video/sliver-dot.png'} alt='silver-dot' />}
                                        {e.title}
                                    </h5>
                                    <h6>
                                        {
                                            courseVideo._id === e._id 
                                                ? formatTime(e.duration - timeStamp) + ' left'
                                                : formatTime(e.duration)
                                        }
                                    </h6>
                                </div>
                            </div>
                            : <div key={e._id}
                                onClick={() => onTopicClick({
                                    _id: e._id,
                                    _title: e.title,
                                    _module: key,
                                    _type: 'QUIZ',
                                    _quiz: e.container,
                                    uiType: e.uiType,
                                    subtitle: e.subtitle,
                                    assessmentQuestions: e.assessmentQuestions
                                }) }
                                    className={`courseVideo__stats__content--card--content
                                        courseVideo__stats__content--card--content--quiz 
                                        ${courseVideo._id === e._id && 'courseVideo__stats__content--card--content--quiz--active'}
                                        ${e.uiType === 'ASSESSMENT' && 'courseVideo__stats__content--card--content--quiz--assessment'}
                                        `} >
                                <div className='courseVideo__stats__content--card--content--quiz--container' >
                                    {
                                        e.uiType === 'ASSESSMENT' 
                                        ? <img src={assets + '/video/assessment.png'} alt='assessment' />
                                        : <img src={assets + '/courses/course-quiz.png'} alt='quiz' />
                                    }
                                    
                                    <div>
                                        <h5>
                                            {e?.uiType === 'ASSESSMENT' ? '': 'Quiz -'}  &nbsp;
                                            { e.title }
                                        </h5>
                                        {
                                            e?.uiType === 'ASSESSMENT' && <p>Solve for the following questions</p>
                                        }
                                        {/* <p>Complete this quiz to unlock Next Lesson</p> */}
                                    </div>
                                </div>
                            </div>
                        ))}                    
                    </span>
                    )}
                </div>
            )
        }
        )
    );

    return(
        <>
            <div className='courseVideo__stats__progress' >
                <div className='courseVideo__stats__progress__fill' style={{ width: `${progress}%` }} ></div>
                <h4>
                    Course Progress 
                </h4>
                <img src='/assets/video/course-progress.png' alt='course progress' />
            </div>
            <div className='courseVideo__stats__content' id='course-content' >
                <h3>Course Content</h3>
                <div className='courseVideo__stats__content--scroll' >
                    {renderContentObj()}
                </div>
            </div>
        </>
    )
}

export default Content;