import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-stars";
import { 
    FacebookShareButton, 
    TwitterShareButton, 
    LinkedinShareButton, 
    WhatsappShareButton
} from 'react-share';

import {
    popupfreeCourseFeedback
} from '../../store';
import CONSTANTS from "../../constants";
import fileDownload from "js-file-download";
import { downloadCertificate, updateAnalytics } from "../../utils/__axios";
import toastService from "../../toast";
import { getCookie, setCookie } from "../../storage";

function FreeCourseFeedbackPopup(){
    const popup = useSelector(state => state.popup.freeCourseFeedback)
    const course = useSelector(state => state.course)
    const video = useSelector(state => state.videoDetails)
    const dispatch = useDispatch();
    const [intent, setIntent] = useState([]);
    const [wrong, setWrong] = useState([]);
    const [star, setStar] = useState(0);
    const [index, setIndex] = useState(0);
    const [comment, setComment] = useState('')

    const submit = async(e) => {
        try{
            e.preventDefault();

            if(intent.length === 0){
                toastService.error('Select valid Intent');
                return;
            }

            if(star === 0){
                toastService.error('Select valid rating');
                return;
            }

            if(wrong.length === 0 && comment.length<3){
                toastService.error('Select what went wrong');
                return;
            }
            
            let response = await updateAnalytics({
                intent: intent,
                feedback: {
                    rating: star,
                    comment: comment,
                    wrong: wrong,
                    courseId: course._id,
                    zoomRecodingId: video._id
                }
            });

            if(response.data && response.data['status'] === 'success'){
                await setCookie('648c27d4893d34e65002124f__feedback', true);
                setIndex(1);
            }else{
                toastService.error();
            }

        }catch(error){
            console.log({ error });
            toastService.error(error.message);
        }
    };

    useEffect(()=>{
        let feedback = getCookie('648c27d4893d34e65002124f__feedback');
        if(feedback === 'true') setIndex(1);
        // dispatch(popupfreeCourseFeedback(true));
    }, [popup]);

    const _intents = [
        "I'd like to upskill myself as an individual by doing courses on trending topics",
        "I am interested to take up a paid course and want to try out Business Toys 's free offerings to make a more informed decision",
        "I am interested in building knowledge in this subject domain"
    ];
    const renderIntent = () => {
        const onIntentClick = (e) => {
            setIntent((state) => {
                const intentIndex = state.indexOf(e);
                if (intentIndex > -1) {
                  // Element exists in the array, so remove it
                  return [...state.slice(0, intentIndex), ...state.slice(intentIndex + 1)];
                }
                // Element doesn't exist in the array, so push it
                return [...state, e];
            });
        };

        return _intents.map((e, i)=>(
            <p 
                key={i}
                onClick={()=>onIntentClick(e)}
            >
                {(intent.indexOf(e) === -1) ? 
                    <img src='/assets/free-course/check-empty.png' />
                    :
                    <img src='/assets/free-course/check-fill.png' />
                }
                {e}
            </p>
        ));
    }
    
    const _wrongs = [
        "Content covered was generic",
        "Difficult to understand content",
        "Less examples",
        "Irrelevant content",
        "Quiz/Assessments quality",
        "Technical issues",
    ];
    const renderWrong = () =>{
        const onWrongclick  = (e) => {
            setWrong((state) => {
                const newState = [...state];
                const wrongIndex = newState.indexOf(e);
              
                if (wrongIndex > -1) {
                  // Element exists in the array, so remove it
                  newState.splice(wrongIndex, 1);
                } else {
                  // Element doesn't exist in the array, so push it
                  newState.push(e);
                }
              
                return newState;
              });
        };

        return _wrongs.map((e, i)=>(
            <p 
                key={i + 10}
                onClick={()=>onWrongclick(e)} 
            >
                {(wrong.indexOf(e) === -1) ? 
                    <img src='/assets/free-course/check-empty.png' />
                    :
                    <img src='/assets/free-course/check-fill.png' />
                }
                {e}
            </p>
        ));
    };
    
    const renderContent = () => {
        return(
            <div className='popup__freecourse__container popup__freecourse__container--form' >
                <form onSubmit={submit} >
                    <p className='popup__freecourse__container__txt--start' style={{ marginBottom: '20px' }} >
                        Submit Feedback To Download Certificate
                    </p>
                    <div className='popup__freecourse__container__input__options' >
                        <label>What was your intent for signing up for this course?</label>
                        <div>
                            {renderIntent()}
                        </div>
                    </div>
                    <div className='popup__freecourse__container__input' >
                        <label>How would you rate this session?</label>
                        <ReactStars
                            count={5}
                            value={star}
                            onChange={(_e)=>{setStar(_e)}}
                            size={25}
                            half={false}
                            color2={'#145EEE'}
                        />
                    </div>
                    <div className='popup__freecourse__container__input__options' >
                        <label>Hey, what went wrong?</label>
                        <div className="popup__freecourse__container__input__options--two">
                            {renderWrong()}
                        </div>
                    </div>
                    <div className='popup__freecourse__container__input' >
                        <textarea 
                            value={comment}
                            onChange={(e)=>setComment(e.target.value)}
                            placeholder="Leave a comment..." ></textarea>
                    </div>
                    <button className='popup__freecourse__container__input--btn' >Unlock Certificate <img src='/assets/free-course/arrow.png' alt='arrow' /></button>
                </form>
            </div>
        )
    };

    let certificateDownload = async(_url) => {
        try{    
            let response = await downloadCertificate(_url);
            fileDownload(response.data, 'certificate.png');
        }catch(err){
            toastService.error();
        }
    }

    const renderCertificate = () => {
        let date = new Date(course.createdOn);
        let linkedIn = {
            name: course.name,
            organizationId: '13684488',
            issueYear: date.getFullYear(),
            issueMonth: date.getMonth()
        }
        const certificateUrl = `${CONSTANTS.BaseURL}/certificate/${course._id}/${course.studentId}`;
        return (
            <div className='popup__freecourse__container'>
                <div className="popup__freecourse__container__certificate" >
                    <img
                        onClick={()=>dispatch(popupfreeCourseFeedback(false))} 
                        className="popup__freecourse__container__certificate--close" 
                        src="/assets/free-course/close.png" alt="close" />
                    <p className='popup__freecourse__container__txt--start' style={{ marginBottom: '20px' }} >
                        Certificate
                    </p>
                    <div className="popup__freecourse__container__certificate--share" >
                        <p>Share : 
                            <LinkedinShareButton url={certificateUrl} >
                                <img src="/assets/free-course/linkedin.png" alt="linkedin" />
                            </LinkedinShareButton>
                            <FacebookShareButton url={certificateUrl}  >
                                <img src="/assets/free-course/facebook.png" alt="facebook" />
                            </FacebookShareButton>
                            <TwitterShareButton url={certificateUrl}  >
                                <img src="/assets/free-course/twitter.png" alt="twitter" />
                            </TwitterShareButton>
                            <WhatsappShareButton url={certificateUrl}  >
                                <img src="/assets/free-course/whatsapp.png" alt="whatsapp" />
                            </WhatsappShareButton>
                        </p>
                    </div>
                    <div className="popup__freecourse__container__certificate__main" >
                        <img src={certificateUrl} alt="certificate" />
                    </div>
                    <div className="popup__freecourse__container__certificate--add">
                        <a 
                            target="__blank" 
                            href={`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${linkedIn.name}&organizationId=${linkedIn.organizationId}&issueYear=${linkedIn.issueYear}&issueMonth=${linkedIn.issueMonth}&certUrl=${certificateUrl}&certId=${course.studentId}`}  
                        >
                            <p>Add to LinkedIn <img src="/assets/free-course/linkedin.png" alt="linkedin" /></p>
                        </a>
                        <a onClick={()=>certificateDownload(certificateUrl)} >
                            <p> Download <img src="/assets/free-course/download.png" alt="download" /> </p>
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    return(
        //popup.freeCourseFeedback
        <section className='popup__freecourse' style={{ display: (popup) ? 'block' : 'none' }}  >
            { index==0 && renderContent() }
            { index==1 && renderCertificate() }
        </section>
    )
};

export default FreeCourseFeedbackPopup;