import { createSlice } from '@reduxjs/toolkit';

const redirectSlice = createSlice({
    name: 'redirect',
    initialState: '/',
    reducers: {
        setRedirect(state, action){
            return action.payload;
        },
        resetRedirect(){
            return '/';
        }
    }
});

export const { setRedirect, resetRedirect } = redirectSlice.actions;
export { redirectSlice };