import React, { useState } from "react";
import FileDownload from 'js-file-download';
import { Link } from 'react-router-dom';

import CONSTANTS from "../../constants";
import { useSelector } from 'react-redux';
import { useStudyMaterialQuery, useDownloadResourceMutation } from '../../store';
import { globalQueryError } from "../../utils/__errorHandling";
import toastService from "../../toast";
import { downloadAttachment } from '../../utils/__axios';

const assets = CONSTANTS.assets;

function Resources(){

    const [active, setActive] = useState('uploaded_doc');
    const {attachments} = useSelector((state => state.videoDetails));
    const { data, error } = useStudyMaterialQuery();

    let _data = {};
    
    if(error){
        if(error['data']){
            const { statusCode } = error['data'].error;
            globalQueryError(statusCode, error['data']);
        }else{
            toastService.error(error['error']);
        }
    }

    if(data){
        if(data['status'] === 'success'){
            _data = { ...data['data']};
        }else{
            toastService.error();
        }
    };

    const downloadClick = async(_key) => {
        try{
            window.scrollTo(0,0);
            toastService.info('Downloading...');
            let response = await downloadAttachment(_key);
            FileDownload(response.data, _key.split('----')[1]);
        }catch(err){
            toastService.error();
        }
    }

    return(
        <div className="video__player__features--resource" >
            <div className="video__player__features--resource__btns" >
                {
                    Object.values(_data).length > 0 &&
                    <button 
                        onClick={()=>setActive('study_material')} 
                        className={(active === 'study_material') ? 
                            'video__player__features--resource--active' : ''
                        }   
                    >   
                    Study Material
                    </button>
                }
                <button 
                    onClick={()=>setActive('uploaded_doc')} 
                    className={(active === 'uploaded_doc') ? 
                        'video__player__features--resource--active' : ''
                    }   
                >   
                Uploaded Documents
                </button>
            </div>
            <div className="video__player__features--resource__container" >
                {
                    (active === 'study_material') && 
                    <span>
                        {
                            Object.values(_data).map((e)=>(
                                <Link to={`/pdf-open/${e.fileKey}`} key={e._id} 
                                    className="video__player__features--resource__container__study" >
                                    <h5>{e.title}</h5>
                                    <img src={`${CONSTANTS.BaseURL}/cover-study-material/${e.imageKey}`} 
                                        alt="study-material" />
                                    <p>- {e.source}</p>
                                </Link>
                            ))
                        }
                    </span>
                }
                {
                    (active === 'uploaded_doc') && 
                    <span>
                        {
                            attachments?.map((e)=>(
                                <div key={e._id} onClick={()=>downloadClick(e.fileKey || e.fileName)} className="video__player__features--resource__container__upload" >
                                <img src={assets + '/video/folder-placeholder.png'} alt="folder" />
                                <h6>{e.fileName.split('----')[1] || e.fileName }</h6>
                                {/*
                                    <p>
                                        <img src={assets + '/icons/attach.png'} alt="attach" />
                                    </p>
                                */}
                                <p>
                                    {
                                        new Date(e.uploadedOn).toLocaleString('en-US', {
                                            year: 'numeric', 
                                            month: 'short', 
                                            day: 'numeric', 
                                            hour: 'numeric',
                                            minute: 'numeric'
                                        })
                                    }
                                </p>
                                </div>
                            ))
                        }
                        { (attachments?.length === 0) && <p>No attachments</p> }
                    </span>
                }
            </div>
        </div>
    )
};

export default Resources;