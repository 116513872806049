import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from "react-tooltip";
import HoverVideoPlayer from 'react-hover-video-player';

import CONSTANTS from '../../constants';
import { 
    useHomeDataMutation,  
    useNotificationTokenMutation, 
    setNotificationToken,
    useCustomClickEventMutation,
    togglePopup,
    setHomeData,
    setGuideModal,
    setSteps,
    toggleNavigation
} from '../../store';
import toastService from '../../toast';
import { globalError } from '../../utils/__errorHandling';
import { analyticsEvent, requestForToken } from '../../firebase';
import { getCookie } from '../../storage';

let assets = CONSTANTS.assets;

function Home(){
    let dispatch = useDispatch();
    let [_data, set_Data] = useState({ 
        attendance: 0,  
        academic: 0, 
        courses: [], 
        recording: [], 
        quiz: [], 
        guide: false,
        email: ''
    });
    const [banner, setBanner] = useState(assets + '/home/banner-web.png');

    const [ liveUpdate ] = useState([
        // {
        //     _id: '01',
        //     time: '45 minutes ago',
        //     title: 'Celebration Time! 🎉',
        //     content: 'Congratulations Pavan on getting an offer from TCS'
        // },
        // {
        //     _id: '02',
        //     time: '10 minutes ago',
        //     title: 'Celebration Time! 🎉',
        //     content: 'Congratulations Ajay on getting an offer from Infosys'
        // }
    ]);

    const [fetchHomeData]  = useHomeDataMutation();
    const [customClickEvent] = useCustomClickEventMutation();
    const [notificationToken] = useNotificationTokenMutation();
    const [width] = useState(window.innerWidth);

    const updateNotificationToken = async() => {
        try{
            let token = await requestForToken();
            let response = await notificationToken(token);
            if(response.data.status === 'success'){
                dispatch(setNotificationToken(token));
            }
        }catch(err){
            // toastService.error('Enable notifications, To recieve updates');
            dispatch(setNotificationToken(null));
        }
    }

    const loadData = async() => {
        try{
            let response = await fetchHomeData();
            if(response['error']){
                globalError(response['error']);
                return;
            };
            if(response['data'].status === 'success'){
                await set_Data(response['data'].data);
                dispatch(setHomeData(response['data'].data));
                if(!response['data'].phone) dispatch(togglePopup(true));

                //CLARITY CUSTOM IDENTIFIER
                if(window.location.href.includes('learn.businesstoys.in')){
                    window.clarity("identify", response['data'].data.email);
                }
            }
        }catch(error){
            toastService.error();
        }
    };

    useEffect(()=>{
        window.scrollTo(0,0);
        dispatch(toggleNavigation(true));
        loadData();
        // setTimeout(()=>{
        //     updateNotificationToken();
        // }, 5000);        
        if(width < 770){
            // dispatch(toggleSidebar());
            setBanner(assets + '/home/banner-mobile.png');
        };

        analyticsEvent({ _event: 'home_page', _type: 'view' });
    }, [width]);

    useEffect(()=>{
        // let guideKey = getCookie(CONSTANTS.guideKey);
        if(_data.guide){
            dispatch(setGuideModal(true));
            dispatch(setSteps([
                {
                    content: <section>
                        <h3>Course overview</h3>
                        <p>Switch between courses & get course information with progress</p>
                    </section>,
                    placement: 'right',
                    target: '#courses',
                },
                {
                    content: <section>
                        <h3>Job Portal</h3>
                        <p>Internal Job portal exclusively for our students, Get latest job postings based on your course</p>
                    </section>,
                    placement: 'right',
                    target: '#job-portal',
                },
                {
                    content: <section>
                        <h3>Payment Details</h3>
                        <p>Access to your payment details, for fee payment and invoice download</p>
                    </section>,
                    placement: 'right',
                    target: '#payment',
                },
                {
                    content: <section>
                        <h3>Blogs</h3>
                        <p>Stay updated with the Industry trends, Blogs written by mentors & industry experts</p>
                    </section>,
                    placement: 'right',
                    target: '#blogs',
                },
                {
                    content: <section>
                        <h3>Upcoming Events</h3>
                        <p>New webinars by top industry experts, Learn from the top 1%</p>
                    </section>,
                    placement: 'bottom',
                    target: '#upcoming-events',
                },
                {
                    content: <section>
                        <h3>Refer & Earn</h3>
                        <p>Refer someone you know and get Amazon gift card up to ₹2,500!</p>
                    </section>,
                    placement: 'bottom',
                    target: '#refer-and-earn',
                },
                {
                    content: <section>
                        <h3>Notifications</h3>
                        <p>Enable browser notifications, to recive  all notifications in your device real time</p>
                    </section>,
                    placement: 'bottom',
                    target: '#notifications',
                },
                {
                    content: <section>
                        <h3>Events Overview</h3>
                        <p>Latest info of upcoming events, webinars, courses, and other activites</p>
                    </section>,
                    placement: 'bottom',
                    target: '#banner',
                },
                {
                    content: <section>
                        <h3>Your latest recording</h3>
                        <p>The latest topic which happend in your course, is available here for easy access</p>
                    </section>,
                    placement: 'bottom',
                    target: '#your-recordings',
                },
                {
                    content: <section>
                        <h3>Related Courses</h3>
                        <p>The most relevant course for you, based on your previous courses, learning speed etc.</p>
                    </section>,
                    placement: 'bottom',
                    target: '#related-courses',
                },
                {
                    content: <section>
                        <h3>Attendance</h3>
                        <p>Your total attendance percentage for your longterm course,</p>
                    </section>,
                    placement: 'left',
                    target: '#attendance',
                },
            ]));
            }
    }, [_data.guide])

    const randomNumber = () => {
        return Math.floor(Math.random() * (2000 - 1000 + 1) + 1000)
    };


    const truncateText = (_txt, _maxLength) => {
        let truncatedText = _txt;
        if(truncatedText.length > _maxLength){
            truncatedText = truncatedText.substring(0, _maxLength) + "...";
        };
        return truncatedText;
    };

    let renderQuiz = () => _data.quiz.map((e)=>(
            <Link key={e._id} to={`/quiz/topic/${e.tag}`} className='home__main__quiz__container__card' >
                <img src={e.homeIcon} alt={e.name} />
            </Link>
    ));

    let renderRelatedCourse = () => _data.courses.map((e)=>(
        <a href={e.landingPage} onClick={()=>customClickEvent(e.name)} 
            target="__blank" key={e._id} className='home__main__course__container__card' >
            <img className='home__main__course__container__card--main' src={e.banner} alt={e.name}  />
            <div className='home__main__course__container__card--txt' >
                <h4>{truncateText(e.name, 36)}</h4>
                <div className='home__main__course__container__card--trainer' >
                    <p><img src={e.trainers[0].profilePic} alt={e.trainers[0].name} />{e.trainers[0].name}</p>
                    <span>{e.rating} <img src={assets + '/icons/star.png'} alt="star" /> </span>
                </div>
                <p> <img src={assets + '/icons/enroll.png'} alt="enroll" /> {randomNumber()}+ enrolled</p>
                <div className='home__main__course__container__card--session' >
                    <img src={assets + '/icons/green-dot.png'} alt="green" />
                    {e.type} Live
                </div>
            </div>
        </a>
    ));

    let renderUpdates = () => liveUpdate.map((e)=>(
        <div key={e._id} className='home__update__container__card' >
            <h4>{e.time}</h4>
            <h3>{e.title}</h3>
            <p>{e.content}</p>
        </div>
    ));

    let renderPrevious = () => _data.recording.map((e, i)=>{
        return(
        <Link  data-tooltip-id={`my-tooltip-${i}`} key={i} 
            to={`/course/${e.course}?_id=${e._id}`} className='home__main__cont__container__card' >
            <div className='home__main__cont__container__card__img' >
                <img src={assets + '/icons/play-silver.png'} alt="white" />
                <img src={assets + '/home/continue.png'} alt="continue" />

            </div>
            <div className='home__main__cont__container__card__txt' >
                <h4>{truncateText(e.label, 30)}</h4>
                <h5>{truncateText(e.title, 38)}</h5>
                <h6>&nbsp;</h6>
                <div  className='home__main__cont__container__card--status' >
                    <span  style={{ width: (e.seeked > 0) ? `${((e.seeked / e.duration ) * 100)}%` : 0 }} ></span>
                </div>
            </div>
            <ReactTooltip
                id={`my-tooltip-${i}`}
                place="top"
                content={`${e.seeked} minutes watched`}
            />
        </Link>
        )
        }
    );

    return(
        <section className='home' >
            <div className='home__main' >
                <div className='home__main__banner' id='banner' >
                    <a href="#" target="__blank" >
                        <img src={banner} alt="banner" />
                    </a>
                </div>
                {
                    _data.quiz.length > 0 &&
                    <div className='home__main__quiz' >
                        <h3>Quiz&nbsp;🚀</h3>
                        <div className='home__main__quiz__container' >
                            {renderQuiz()}
                        </div>
                    </div>
                }
                {
                    _data.recording.length > 0 && 
                    <div className='home__main__cont' id='your-recordings' >
                        <h3>Your Recordings 📺</h3>
                        <div className='home__main__cont__container' >
                            {renderPrevious()}
                        </div>
                    </div>
                }
                <div className="home__main__course" id='related-courses' >
                    <h3>Related Courses</h3>
                    <div className='home__main__course__container' >
                        {renderRelatedCourse()}
                    </div>
                </div>
            </div>
            <div className='home__right' >
                <div className='home__status' >
                    <div className='home__status__card' id='attendance' >
                        <img src={assets + '/home/attendance.png'} alt="attendance" />
                        <div className='home__status__card__txt'  >
                            <h4>Attendance <span>{_data.attendance}%</span></h4>
                            <div className='home__status__card__txt__status' >
                                <span style={{ width: `${_data.attendance}%` }} ></span>
                            </div>
                        </div>
                    </div>
                    <div className='home__status__card' >
                        <img src={assets + '/home/academic.png'} alt="attendance" />
                        <div className='home__status__card__txt' >
                            <h4>Academic Progress <span>{_data.academic}%</span></h4>
                            <div className='home__status__card__txt__status' >
                                <span style={{ width: `${_data.academic}%` }} ></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='home__update' >
                    <h4>Live Updates</h4>
                    <div className='home__update__container' >
                        {renderUpdates()}                        
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Home;

    // if(error){
    //     if(error['data']){
    //         const { statusCode } = error['data'].error;
    //         globalQueryError(statusCode, error['data']);
    //     }else{
    //         toastService.error(error['error']);
    //     }
    // }

    // if(data){
    //     if(data['status'] === 'success'){
    //         _data = { ...data['data']};
    //     }
    // }