import { createSlice } from '@reduxjs/toolkit';

const timeStampSlice = createSlice({
    name: 'timestamp',
    initialState: 0,
    reducers: {
        setTimestamp(state, action){
            return action.payload;
        }
    }
});

export const { setTimestamp } = timeStampSlice.actions;
export { timeStampSlice };