import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
    disablePopup,
    setGuideModal,
    toggleNavigation,
    toggleRun,
    useCourseSignUpDetailMutation, 
    useExamSignUpMutation
} from "../../store";
import toastService from "../../toast";
import { globalError } from "../../utils/__errorHandling";
import { setCookie } from "../../storage";
import CONSTANTS from "../../constants";

export default function CollegeExam () {
    let params = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [courseDetails] = useCourseSignUpDetailMutation();
    const [examSignup] = useExamSignUpMutation()

    const [course, setCourse] = useState({ name: null, _id: null })
    const [formData, setFormData] = useState({ name: '', usn: '', email: '' })

    const handleChange = (_event) => setFormData(stata => ({ ...stata, [_event.target.name]: _event.target.value }))

    const successNavigateHandel = async(_data) => {
        await setCookie(CONSTANTS.authCookie, true, {
            path: '/',
            expires: CONSTANTS.cookieExpire
        })
        navigate('/onboard/ds');
    }

    const submit = async (_event) => {
        try {
            _event.preventDefault()

            let response = await examSignup({
                _id: course._id, 
                data: formData
            });

            if(response['error']){
                globalError(response['error']);
                return;
            };
            if(response['data']){
                successNavigateHandel();
                return;
            }
        } catch (error) {
            console.log({ error })
        }
    }

    const fetchDetails = async(_slug) => {
        try{
            let response = await courseDetails(_slug);
            let data = response.data;
            if(!data.data){
                toastService.error('Invalid URL');
                return;
            }
            setCourse(data.data);
        }catch(err){
            toastService.error(err.message);

        }
    }

    useEffect(()=>{
        toastService.info('Using multiple accounts will block your account')

        dispatch(toggleNavigation(false));
        dispatch(disablePopup());
        dispatch(setGuideModal(false));
        dispatch(toggleRun(false));
        const { id } = params;
        fetchDetails(id);
    }, [dispatch]);

    return (
        <section className='login' >
            <div className='login__container' >
                <h1>{course.name}</h1>
                <form onSubmit={submit}>
                    {/* <h1>Login with Email Link</h1> */}
                    <div className='login__container__input'>
                        <label>Name</label>
                        <input type='text' required name="name" value={formData.name} placeholder='Enter your Name' onChange={handleChange} />
                    </div>
                    <div className='login__container__input'>
                        <label>Registered Email</label>
                        <input type='email' required name="email" value={formData.email} placeholder='Enter email address' onChange={handleChange} />
                    </div>
                    <div className='login__container__input'>
                        <label>USN/Registration Number</label>
                        <input type='text' required name="usn" value={formData.usn} placeholder='Enter your USN/Registration Number' onChange={handleChange} />
                    </div>
                    <button >Login</button>
                </form>
            </div>
        </section>
    )
}