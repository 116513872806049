const dateFormatter = (_date) => {
    const date = new Date(_date);
    const formattedDate = new Intl.DateTimeFormat("en-US", { 
        year: "numeric", 
        month: "long", 
        day: "numeric" 
    }).format(date);
    return formattedDate;
};

const daysLeft = (_date) => { 
    const sameTimeDate1 = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate(), 0, 0, 0, 0);
    const msInDay = 1000 * 60 * 60 * 24;  
    const daysLeft = Math.ceil((sameTimeDate1 - new Date()) / msInDay);
    return daysLeft
};

export {
    dateFormatter,
    daysLeft
}