import { createSlice } from '@reduxjs/toolkit';

import CONSTANTS from '../../constants';

const VideoPlayerSlice = createSlice({
    name: 'videoDetails',
    initialState: {
        src: '/assets/video/placeholder.mp4',
        title: '',
        status: 'LOADING',
        meetingUrl: '',
        attachments: [],
        activeTab: 'resources',
        _id: '',
        videoDuration: 0
    },
    reducers: {
        setVideoDetials(state, action){
            state.src = `${CONSTANTS.BaseURL}/recording/play/${action.payload._id}`;
            state.title = action.payload.title || '';    
            state.status = action.payload.status || 'LOADING'; 
            state.meetingUrl = action.payload.meetingUrl;
            state.attachments = action.payload.attachments;
            state._id = action.payload._id;
            state.videoDuration = action.payload.videoDuration || null;
        },
        setStatus(state, action){
            state.status = action.payload;
        },
        changeTab(state, action){
            state.activeTab = action.payload;
        }
    }
});

export const VideoPlayerReducer = VideoPlayerSlice.reducer;
export const { setVideoDetials, setStatus, changeTab } = VideoPlayerSlice.actions;
export { VideoPlayerSlice };