const CONSTANTS = {
    assets: '/assets',
    BaseURL: 'https://app.businesstoys.in/api/student',
    // BaseURL: 'http://localhost:9000/api/student',
    // GoogleClientId: '690687473243-ahq8562unfl6hnkojppcfeemrbogrhl0.apps.googleusercontent.com',
    // GoogleClientId: '690687473243-k2c9t4nh1mq54g6qv5mo3jqjgbl917iu.apps.googleusercontent.com',
    GoogleClientId: '309667625614-i6g0mdprkptfup7m3hjqnvl2rsmlobip.apps.googleusercontent.com',
    authCookie: 'F7y2CN35x5$E',
    cookieExpire: new Date(Date.now() + 10 * 24 * 60 * 60 * 1000),
    magicLinkSlug: 'dscmjxlpfswxacerpedp',
    guideKey: 'pPUyfgOjbTqU',
    VAPID_KEY: 'BDEPoL_phdszmBlW_OS7tJi3UR73n_UIIZtTomMUR21xmF90HXfzVfbtwZ0AWnE_9bZVG1GQqVMRxJ8ypzRnAAM',
    // HLS: CONSTANTS.BaseURL + '/recording/hls/12345a67890b/output.m3u8'
};

export default CONSTANTS;