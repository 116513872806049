import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toggleNavigation, disablePopup } from '../../store';

function Privacy(){
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(toggleNavigation(false));
        dispatch(disablePopup());
    }, [dispatch]);

    return(
        <section className='policy' >
            <h1>Privacy Policy</h1>
            <div className="policy__container" >
                <div className="policy__container__card" >
                    <h2>Effective Date: February 21, 2022</h2>
                </div>
                <div className="policy__container__card" >
                    <h3>Introduction</h3>
                    <p>
                        This Privacy Policy explains how the learn.businesstoys.in Web App 
                        (“we,” “our,” “us,” or “learn.businesstoys.in”) collects, uses, and discloses your personal 
                        information when you use our web app. 
                        We respect your privacy and are committed to protecting your personal information.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Information We Collect</h3>
                    <p>
                        We collect personal information that you provide to us, such as your name, email address, and 
                        password when you register for an account with us. We also collect information about your 
                        use of the learn.businesstoys.in, 
                        such as the courses you enroll in, your course progress, and your assessment scores.
                    </p>
                    <p>
                        We automatically collect certain information about your device, including your IP address, 
                        browser type, referring/exit pages, and operating system. We may also collect information about 
                        your activity on the learn.businesstoys.in, 
                        such as the pages you view, the links you click, and the dates and times of your visits.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Use of Your Information</h3>
                    <p>
                        <b>
                            We use your personal information to
                        </b>
                    </p>
                    <ul>
                        <li>
                            Provide you with the learn.businesstoys.in services and support.
                        </li>
                        <li>
                            Personalize and improve your experience on the learn.businesstoys.in.
                        </li>
                        <li>
                            Communicate with you about your account, courses, and related matters.
                        </li>
                        <li>
                            Analyze and improve the learn.businesstoys.in and develop new features.
                        </li>
                        <li>
                            Enforce our terms and conditions and prevent fraud and other illegal activities.
                        </li>
                    </ul>
                </div>
                <div className="policy__container__card" >
                    <h3>Disclosure of Your Information</h3>
                    <p>
                        <b>
                            We may disclose your personal information to
                        </b>
                    </p>
                    <p>
                        Our service providers who assist us in providing the learn.businesstoys.in services and support.
                        Our affiliates, partners, or other third parties in connection with business transactions such as mergers, acquisitions, or divestitures.
                        Law enforcement, government agencies, or other third parties as required by law or in response to a legal request.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Security</h3>
                    <p>
                        We take reasonable measures to protect your personal information from 
                        unauthorized access, use, or disclosure. However, no transmission of data over 
                        the internet or electronic storage is completely secure.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Retention</h3>
                    <p>
                        We will retain your personal information for as long as necessary to fulfill the 
                        purposes for which it was collected, 
                        unless a longer retention period is required by law.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Your Rights</h3>
                    <p>
                        <b>
                            You have certain rights regarding your personal information, including the right to
                        </b>
                    </p>
                    <ul>
                        <li>
                            Access, correct, or delete your personal information.
                        </li>
                        <li>
                            Object to the processing of your personal information.
                        </li>
                        <li>
                            Restrict the processing of your personal information.
                        </li>
                        <li>
                            Receive a copy of your personal information.
                        </li>
                        <li>
                            Withdraw your consent to the processing of your personal information.
                        </li>
                    </ul>
                </div>
                <div className="policy__container__card" >
                    <h3>Contact Us</h3>
                    <p>
                        If you have any questions or concerns about this Privacy Policy or our practices, 
                        please contact us at <a href='mailto:contact@businesstoys.in' >contact@businesstoys.in</a>.
                    </p>
                </div>
                <div className="policy__container__card" >
                    <h3>Changes to this Privacy Policy</h3>
                    <p>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by 
                        posting the new Privacy Policy on the learn.businesstoys.in<br /> 
                        We encourage you to review this Privacy Policy periodically for any updates or changes.
                    </p>
                </div>
                
            </div>
        </section>
    )
};

export default Privacy;