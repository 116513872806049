import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';

// import API from '../../api';

const googleLogin = async(_data) => {
    try{
        console.log(_data);
        // let response = await API.googleLogin({token: `${_data['code']}`});
        // console.log(response);
    }catch(err){
        // console.log(err)
    }
}

function Signup(){
    const login = useGoogleLogin({
        onSuccess: codeResponse => googleLogin(codeResponse),
    });
    return(
        <section>
        </section>
    )
};

export default Signup;